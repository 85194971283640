import {
    Box,
    createTheme,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ThemeProvider,
    Toolbar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import AppBar from "./AppBar.component";
import MenuIcon from "@mui/icons-material/Menu";
import AppsIcon from "@mui/icons-material/Apps";
import UserProfileAppBar from "./UserProfileAppBar.component";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NavigationListItems from "./NavigationListItems.component";
import Main, { drawerWidth, childMenuWidth } from "./MainContent.container";
import MainContainer from "./MainContainer.container";
import { useApiAuthorization } from "../components/authorization/ApiAuthorizationProvider.component";
import useMediaQuery from "@mui/material/useMediaQuery";

export const theme = createTheme({
    // MUI Palette/Theme Info: https://mui.com/material-ui/customization/palette/
    // HIMSS Branding Color Guide: https://brand.himss.org/Styleguide/visualidentity/#page/919E2309-BA1B-4A57-A6608F0981B8B289
    palette: {
        primary: {
            main: "#1e22aa",
        },
        secondary: {
            main: "#55c1e9",
        },
        error: {
            main: "#ff5a5a",
        },
        warning: {
            main: "#ffc72c",
        },
        info: {
            main: "#d064ce",
        },
        success: {
            main: "#3cd5af",
        },
    },
    typography:{
        fontFamily: ['soleto', 'Verdana', 'sans-serif'].join(','),
        h1: {
            fontFamily: ["Prometo", "Arial", 'sans-serif'].join(',')
        },
        h2:{
            fontFamily: ["Prometo", "Arial", 'sans-serif'].join(',')
        },
        h3:{
            fontFamily: ["Prometo", "Arial", 'sans-serif'].join(',')
        },
        h4:{
            fontFamily: ["Prometo", "Arial", 'sans-serif'].join(',')
        },
        h5:{
            fontFamily: ["Prometo", "Arial", 'sans-serif'].join(',')
        },
        h6:{
            fontFamily: ["Prometo", "Arial", 'sans-serif'].join(',')
        },
    }
});

type BaseLayoutType = {
    children?: React.ReactNode;
    childMenu?: React.ReactNode;
    hideHeader?: boolean;
    hideNavigation?: boolean;
    childMenuOpen?: boolean;
};

function BaseLayout(props: BaseLayoutType) {
    const [menuOpen, setMenuOpen] = React.useState(true);
    const [childMenuOpen, setChildMenuOpen] = React.useState(true);
    const { appUser } = useApiAuthorization();
    const muiTheme = useTheme();
    const smallScreenSize = useMediaQuery(muiTheme.breakpoints.down("md"));
    const printMode = useMediaQuery("print");

    const toggleDrawer = () => {
        if (smallScreenSize && childMenuOpen) {
            setChildMenuOpen(false);
        }
        setMenuOpen(!menuOpen);
    };

    const toggleChildMenuDrawer = () => {
        if (smallScreenSize && menuOpen) {
            setMenuOpen(false);
        }
        setChildMenuOpen(!childMenuOpen);
    };

    React.useEffect(() => {
        if (smallScreenSize && !printMode) {
            setMenuOpen(false);
            setChildMenuOpen(false);
        }
    }, [smallScreenSize, printMode]);

    React.useEffect(() => {
        if (!props.childMenu) {
            setChildMenuOpen(false);
        }
    }, [props.childMenu]);

    React.useEffect(() => {
        if (props.childMenuOpen) {
            setChildMenuOpen(props.childMenuOpen);
        }
    }, [props.childMenuOpen]);

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    "@media print": {
                        height: "auto !important",
                    },
                }}
            >
                <CssBaseline />
                <MainContainer
                    open={menuOpen}
                    childmenuopen={childMenuOpen}
                    sx={{
                        "@media print": {
                            margin: 0,
                            padding: 0,
                        },
                    }}
                >
                    {props.hideHeader &&
                        (!props.hideNavigation || props.childMenu) && (
                            <AppBar
                                position="fixed"
                                open={menuOpen}
                                childmenuopen={childMenuOpen}
                                sx={{
                                    background: "none",
                                    border: "none",
                                    boxShadow: "none",
                                }}
                            >
                                <Toolbar
                                    sx={{
                                        pr: "24px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        {!props.hideNavigation && !menuOpen && (
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                aria-label="open drawer"
                                                onClick={toggleDrawer}
                                                sx={{}}
                                            >
                                                <AppsIcon
                                                    sx={{
                                                        color: theme.palette
                                                            .primary.main,
                                                    }}
                                                />
                                            </IconButton>
                                        )}
                                    </div>
                                    <div>
                                        {props.childMenu && !childMenuOpen && (
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                aria-label="open drawer"
                                                onClick={toggleChildMenuDrawer}
                                                sx={{}}
                                            >
                                                <MenuIcon
                                                    sx={{
                                                        color: theme.palette
                                                            .primary.main,
                                                    }}
                                                />
                                            </IconButton>
                                        )}
                                    </div>
                                </Toolbar>
                            </AppBar>
                        )}

                    {!props.hideHeader && (
                        <AppBar
                            position="fixed"
                            open={menuOpen}
                            childmenuopen={childMenuOpen}
                            sx={{
                                boxShadow: "none",
                            }}
                        >
                            <Toolbar
                                sx={{
                                    pr: "24px",
                                    "@media print": {
                                        display: "none",
                                        transition: "none !important",
                                    },
                                }}
                            >
                                {!menuOpen && (
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={toggleDrawer}
                                        sx={{
                                            marginRight: "36px",
                                        }}
                                    >
                                        <AppsIcon />
                                    </IconButton>
                                )}
                                <img
                                    alt="app logo"
                                    src="https://ha-static-content.s3.amazonaws.com/images/HIMSSlogo_Hwhitelogotype_RGB.svg"
                                    className="App-logo"
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "right",
                                        flexGrow: "1",
                                    }}
                                >
                                    <UserProfileAppBar />
                                </Box>
                                {!childMenuOpen && props.childMenu && (
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={toggleChildMenuDrawer}
                                        sx={{
                                            marginRight: "0px",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                            </Toolbar>
                        </AppBar>
                    )}

                    {!props.hideNavigation && (
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                [theme.breakpoints.down("md")]: {
                                    width: "100% !important",
                                },
                                position: "fixed",
                                flexShrink: 0,
                                zIndex: 1200,
                                "& .MuiDrawer-paper": {
                                    width: drawerWidth,
                                    [theme.breakpoints.down("md")]: {
                                        width: "100% !important",
                                    },
                                    boxSizing: "border-box",
                                    borderRight: 'none'
                                },
                                '& .MuiList-root': {
                                        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                                        height: '100%'
                                    },
                                "@media print": {
                                    display: "none",
                                    transition: "none !important",
                                },
                            }}
                            variant="persistent"
                            open={menuOpen}
                            anchor="left"
                        >
                            <Toolbar
                                sx={{
                                    display: "flex",
                                    background: theme.palette.primary.main,
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    px: [1],
                                }}
                            >
                                <IconButton onClick={toggleDrawer}>
                                    <ChevronLeftIcon
                                        sx={{
                                            color: "#fff",
                                        }}
                                    />
                                </IconButton>
                            </Toolbar>
                            <Divider />
                            <List component="nav">
                                <NavigationListItems appUser={appUser} />
                            </List>
                        </Drawer>
                    )}

                    <Main open={menuOpen} childmenuopen={childMenuOpen}>
                        {props.children}
                    </Main>

                    {props.childMenu && (
                        <Drawer
                            sx={{
                                width: childMenuWidth,
                                [theme.breakpoints.down("md")]: {
                                    width: "100% !important",
                                },
                                position: "fixed",
                                flexShrink: 0,
                                zIndex: 1200,
                                "& .MuiDrawer-paper": {
                                    width: childMenuWidth,
                                    [theme.breakpoints.down("md")]: {
                                        width: "100% !important",
                                    },
                                    boxSizing: "border-box",
                                    borderLeft: 'none'
                                },
                                '& .MuiList-root': {
                                    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                                    height: '100%'
                                },
                                "@media print": {
                                    display: "none",
                                },
                            }}
                            variant="persistent"
                            open={childMenuOpen}
                            anchor="right"
                        >
                            <Toolbar
                                sx={{
                                    display: "flex",
                                    background: theme.palette.primary.main,
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    px: [1],
                                }}
                            >
                                <IconButton onClick={toggleChildMenuDrawer}>
                                    <ChevronRightIcon
                                        sx={{
                                            color: "#fff",
                                        }}
                                    />
                                </IconButton>
                            </Toolbar>

                            <Divider />

                            {props.childMenu}
                        </Drawer>
                    )}
                </MainContainer>
            </Box>
        </ThemeProvider>
    );
}

export default BaseLayout;
