import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import React from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import Loading from "../../components/loading/Loading.component";
import { MassDeleteAssessmentDetail } from "./Assessment.service";

interface AssessmentMassDeleteDialogProps{
    open: boolean,
    onClose: () => void,
    selectedIDs: GridRowSelectionModel
};

const AssessmentMassDeleteDialog = (props: AssessmentMassDeleteDialogProps) => {
    const {appUser} = useApiAuthorization();
    const [saving, setSaving] = React.useState<boolean>(false);
    
    
    const handleClose = React.useCallback(() => {
        props.onClose();
    }, [props]);
    
    const massDelete = React.useCallback(() => {
        
        setSaving(true);
        MassDeleteAssessmentDetail(props.selectedIDs as number[], appUser?.token).then((request) =>{
            
        }).finally(() => {
            setSaving(false);
            handleClose();
        })
    }, [props, appUser?.token, handleClose]);

    

    return (
        <>
            {saving && <Loading />}
            {!saving &&
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>Mass Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please confirm you wish to delete the selected assessments.</DialogContentText>                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={massDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
            }
        </>
    );
}

export default AssessmentMassDeleteDialog;