import { styled, Table } from "@mui/material";

export const StyledTable = styled(Table)(({ theme }) => ({
    border: "1px solid rgba(224, 224, 224, 1)",
    width: "100%",
    "& .bg-red": {
        backgroundColor: "#ff5a5a",
    },
    "& .bg-green": {
        backgroundColor: "#3cd5af",
    },
    "& .bg-blue": {
        backgroundColor: "#1e22aa",
        color: "white",
    },
    "& .bg-yellow": {
        backgroundColor: "#ffc72c",
    },
    "& .bg-pink": {
        backgroundColor: "#d064ce",
    },
    "& .bg-lightblue": {
        backgroundColor: "#55c1e9",
    },
    "& .MuiTableCell-root": {
        padding: "7px 7px"
    },
    "@media print": {
        "& .MuiTableCell-root": {
            fontSize: "10px"
        },
    },

        
}));