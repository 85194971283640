import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export interface DeleteDialogProps {
    open: boolean,
    onClose: () => void,
    onConfirm: () => void,
    content?: string,
    title?: string
}

const DeleteDialog = (props: DeleteDialogProps) => {
    const formatContent = (content: string) => {
        let formattedContent = content.split('\n').map(i => {
            return <div>{i}</div>
        });
        
        return formattedContent;
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{props.title ?? "Delete Confirmation"}</DialogTitle>
            <DialogContent>
                <DialogContentText component={"div"}>{props.content ? formatContent(props.content) : "Do you want to delete this record?"}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button onClick={props.onConfirm}>Delete</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;