import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type CircularProgressType = {
    label: string,
    color: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined
};

const CircularProgressWithLabel = ({label, color}:CircularProgressType) => {
    return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress color={color} />
        <Box
        sx={{
            top: 75,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
        >
        <Typography
            variant='caption'
            component='div'
            color='text.secondary'
            fontWeight='900'
        >
            {label}
        </Typography>
        </Box>
    </Box>
    )
}

export default CircularProgressWithLabel;
  