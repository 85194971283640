import { Divider, Grid, Typography } from "@mui/material";
import { AverageStagePercentAchievementModel, CreateDefaultAverageStagePercentageAchievementModel, LatestPublishedScoresDetailsProps } from "./LatestPublishedScoresDetails.models";
import { useEffect, useRef, useState } from "react";
import { BarChart } from "@mui/x-charts";
import { GetLatestPublishedScoresDetailsDataVisualization } from "./LatestPublishedScoresDetails.service";
import { useApiAuthorization } from "../../../../components/authorization/ApiAuthorizationProvider.component";
import { GlobalColors } from "../../../../constants/GlobalColors.const";

const AverageStagePercentAchievement = (props: LatestPublishedScoresDetailsProps) => {
    const [dataVizData, setDataVizData] = useState<AverageStagePercentAchievementModel>(CreateDefaultAverageStagePercentageAchievementModel()); 
    const { onLoad, queryOptions } = props;
    const { appUser } = useApiAuthorization();     
    const hanldeOnLoad = useRef(onLoad);

    useEffect(() => {
        hanldeOnLoad.current(true);
        GetLatestPublishedScoresDetailsDataVisualization(queryOptions, "1", appUser?.token)
            .then(response => response.json())
            .then(data => {
                setDataVizData(data);
            })
            .finally(() => hanldeOnLoad.current(false));

    }, [queryOptions, hanldeOnLoad, appUser?.token]);

    return(
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle1" textAlign="center">
                    Average Stage Percent Achievement
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="body1" textAlign={"center"} sx={{fontWeight: 'bold',fontSize: 30, color: '#02B2AF'}}>
                    {dataVizData?.facilitiesAssessed?.toLocaleString()}
                </Typography>
                <Typography variant="body1" textAlign={"center"}>
                    Facilities Assessed
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="body1" textAlign={"center"} sx={{fontWeight: 'bold',fontSize: 30, color: '#02B2AF'}}>
                    {dataVizData?.averageStageAchievement?.toLocaleString()}
                </Typography>
                <Typography variant="body1" textAlign={"center"}>
                    Average Stage
                </Typography>
            </Grid>
            <Divider/>
            <Grid item xs={12}>
                <BarChart
                    sx={{
                        '& .MuiBarLabel-root': {
                            fill: '#fff'
                        }
                    }}
                    slotProps={{
                        legend: {
                            direction: "column",
                            position: {
                                vertical:'middle',
                                horizontal: 'right',
                            },
                        }
                    }}
                    margin={{left: 70}}
                    layout="horizontal"
                    dataset={dataVizData.stageAchievements}
                    height={500}
                    yAxis={[{
                        scaleType: "band", 
                        dataKey: "stage", 
                        valueFormatter: (value) => `Stage ${value}`,

                    }]}
                    xAxis={[{
                        min: 0,
                        max: 100
                    }]}
                    series={[
                        {dataKey: "percentageAchieved", valueFormatter: (value) => `${(value ?? 0).toFixed(2)}%`},
                    ]}
                    barLabel={props.axisLable}
                    colors={GlobalColors}
                />
            </Grid>
        </Grid>
    )
};

export default AverageStagePercentAchievement;