import {styled} from '@mui/material/styles';
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import { drawerWidth, childMenuWidth } from './MainContent.container';

interface AppBarType extends AppBarProps {
    open?: boolean,
    childmenuopen?: boolean
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop:string) => !['open', 'childmenuopen'].includes(prop)
})<AppBarType>(({theme, open, childmenuopen}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: 'none',
      ...(!open && childmenuopen && {
        width: `calc(100% - ${childMenuWidth}px)`,
        marginLeft: `0px`,
        marginRight: `${childMenuWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
      ...(open && childmenuopen && {
        width: `calc(100% - ${drawerWidth + childMenuWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        marginRight: `${childMenuWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
      ...(open && !childmenuopen && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        marginRight: `0px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        '@media print': {
          transition: 'none !important'
        }
      }),
}));

export default AppBar;