import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { User } from "../../models/User.model";
import { GetCurrentUser } from "../../services/User.service";

interface ApiAuthorizationContextType {
    appUser?: User,
    isLoading: boolean,
    isAuthenticated: boolean
}

const ApiAuthorizationContext = React.createContext<ApiAuthorizationContextType>(null!);

export function ApiAuthorizationProvider({children}:{children: React.ReactNode}) {
    const [appUser, setAppUser] = React.useState<User>(null!);
    const [isApiLoading, setIsApiLoading] = React.useState<boolean>(false);
    const [token, setToken] = React.useState<string>(null!);
    const audience:string = process.env.REACT_APP_AUTH0_AUDIENCE ?? "";
    const {
        isLoading,
        isAuthenticated,
        getAccessTokenSilently,
        logout
      } = useAuth0<any>();
    
    React.useEffect(() => {
        (async () => {
            try {
                if (!isAuthenticated && !isLoading) {
                    //user is not logged in and not attempting to log in
                    setAppUser(null!);
                }
                
                if (isAuthenticated && !isLoading) {
                    //user is authenticated, get user token
                    const token = await getAccessTokenSilently({
                        authorizationParams: {
                            audience: audience,
                            // audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                            scope: 'read:current_user',
                        }
                    });
                    
                    setToken(token);
                }
            } catch (e:any) {
                if(e.error === "invalid_grant"){
                    logout({
                        logoutParams: {
                            returnTo: window.location.origin
                        }
                    })
                }
                console.error(e);
            }
        })()
    }, [audience, isAuthenticated, isLoading, getAccessTokenSilently, logout]);
    
    React.useEffect(() => {
        if(token !== null && token !== undefined){
            setIsApiLoading(true);
            GetCurrentUser(token)
                .then((response) => response.json())
                .then((data) => {
                    data.token = token;
                    setAppUser(data);
                    setIsApiLoading(false);
                });
        }
    }, [token]);

    let value = {appUser, isLoading: isLoading || isApiLoading, isAuthenticated};
    return <ApiAuthorizationContext.Provider value={value}>{children}</ApiAuthorizationContext.Provider>;
}

export function useApiAuthorization() {
    return React.useContext(ApiAuthorizationContext);
}