import { ApiBaseRoute } from "../api_helper";

export const GetRegionTypeAheadSearch = (token?: string, searchTerm?: string, includedRegionIDs?: number[]): Promise<Response> => {
    return fetch(`${ApiBaseRoute}regions/globalregiontypeaheadSearch`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            searchTerm: searchTerm ?? '',
            includedRegions: includedRegionIDs ?? []
        })
    });
}