import { ApiBaseRoute } from "../api_helper";

export const GetEmailTemplateTypeAheadMultiSearch = (token: string|undefined, searchTerm: string|undefined):Promise<Response> => {
    return fetch(`${ApiBaseRoute}EmailTemplates/TypeAheadSearch?searchTerm=${searchTerm ?? ''}&environment=${process.env.REACT_APP_HOST_ENV}`, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};