import { Divider, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CreateDefaultLatestPublishedScoresOverviewDataVisualization, LatestPublishedScoresOverviewProps, LatestPublishedScoresOverviewResponse } from "./LatestPublishedScoresOverview.models";
import { useApiAuthorization } from "../../../../components/authorization/ApiAuthorizationProvider.component";
import { GetLatestPublishedScoresDataViz } from "./LatestPublishedScoresOverview.service";
import CountryMap from "../Components/CountryMap.component";
import { BarChart } from "@mui/x-charts";
import moment from "moment";
import { GlobalColors, ModelColors } from "../../../../constants/GlobalColors.const";

const MaturityModelOverview = (props: LatestPublishedScoresOverviewProps) => {
    const [dataVizData, setDataVizData] = useState<LatestPublishedScoresOverviewResponse>(CreateDefaultLatestPublishedScoresOverviewDataVisualization());
    const {appUser} = useApiAuthorization();
    const { onLoad, queryOptions, updateQueryOptions } = props;
    const handleOnLoad = useRef(onLoad);
    
    useEffect(() => {
        if(appUser?.token){
            handleOnLoad.current(true);
            GetLatestPublishedScoresDataViz(queryOptions, 1, appUser?.token)
                .then(response => response.json())
                .then(data => {
                    setDataVizData(data); 
                }).finally(() => handleOnLoad.current(false));
        }
    },[queryOptions, appUser?.token]);
    
    return (
        <Grid container>
            <Grid item xs={12} sx={{pb: 5, pt: 5}}>
                <Typography variant="h6">
                    {`${queryOptions.modelIDs.length === 0 ? "All" : queryOptions.modelIDs.map(x => x.label).join(', ')} Maturity Model Adoption Report${queryOptions.validationDateRange[0] ? ` From ${moment(queryOptions.validationDateRange[0]).format("MM/DD/yyyy")}` : ''} ${queryOptions.validationDateRange[1] ? ` To ${moment(queryOptions.validationDateRange[1]).format("MM/DD/yyyy")}` : ''}`}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{pb: 5}}>
                <Typography variant="body1">
                    <b>Health System:</b> an organization that consists of one or more hospital, and/or more than one group of physicians.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                    <b>Facility:</b> hospitals, primary healthcare centers, clinics, burn patient units, feeding centers and other care units.
                </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Typography variant="body1" textAlign={"center"} sx={{fontWeight: 'bold',fontSize: 30, color: '#02B2AF'}}>
                    {dataVizData?.stage7Count.toLocaleString()}
                </Typography>
                <Typography variant="body1" textAlign={"center"}>
                    # of Stage 7 Validations
                </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Typography variant="body1" textAlign={"center"} sx={{fontWeight: 'bold',fontSize: 30, color: '#02B2AF'}}>
                    {dataVizData?.stage6Count.toLocaleString()}
                </Typography>
                <Typography variant="body1" textAlign={"center"}>
                    # of Stage 6 Validations
                </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Typography variant="body1" textAlign={"center"} sx={{fontWeight: 'bold',fontSize: 30, color: '#02B2AF'}}>
                    {dataVizData?.healthSystemCount.toLocaleString()}
                </Typography>
                <Typography variant="body1" textAlign={"center"}>
                    Unique Health System with Scored Facilities
                </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Typography variant="body1" textAlign={"center"} sx={{fontWeight: 'bold',fontSize: 30, color: '#02B2AF'}}>
                    {dataVizData?.facililtyCount.toLocaleString()}
                </Typography>
                <Typography variant="body1" textAlign={"center"}>
                    Unique Facilities Scored
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{pb: 5}}>
                <Divider/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <CountryMap
                    handleOnCountryChange={(newSelection) => {
                        let existingFilter = queryOptions.countryIDs.filter(countrySelection => newSelection.value === countrySelection.value);
                        let countriesToSet = existingFilter.length > 0 ? queryOptions.countryIDs.filter(country => country.value !== newSelection.value) : [...queryOptions.countryIDs, newSelection];
                        if(!!updateQueryOptions)
                            updateQueryOptions({...queryOptions, countryIDs: countriesToSet});
                    }}
                    mapData={dataVizData?.geographicRegionData}
                    title="Geographic Distribution"
                    countryToolTip=""
                />
                
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6' align="center">
                    Stage Distribution
                </Typography>
                <BarChart
                    sx={{
                        '& .MuiBarLabel-root': {
                            fill: '#fff'
                        }
                    }}
                    slotProps={{
                        legend: {
                            direction: "row",
                            position: {
                                vertical:'bottom',
                                horizontal: 'middle',
                            },
                        }
                    }}
                    margin={{
                        bottom: 100
                    }}
                    height={500}
                    xAxis={[{scaleType: "band", data: ["Stage 0","Stage 1","Stage 2","Stage 3","Stage 4","Stage 5","Stage 6","Stage 7",], }]}
                    series={
                        dataVizData?.modelStageDistributions?.map(x => {
                          return {
                            data: [x.stage0Count, x.stage1Count, x.stage2Count, x.stage3Count, x.stage4Count, x.stage5Count, x.stage6Count, x.stage7Count],
                            label: x.model,
                            stack: "total",
                            color: ModelColors[x.model] ?? "#1e22aa"
                          }  
                        })
                     }
                    barLabel={props.axisLable}
                    colors={GlobalColors}
                />
                
            </Grid>
        </Grid>
    );
}

export default MaturityModelOverview;