import { styled } from "@mui/material/styles";


export const drawerWidth: number = 240;
export const childMenuWidth: number = 300;

const Main = styled('main', { shouldForwardProp: (prop:string) => !['open', 'childmenuopen'].includes(prop) })<{
  open?: boolean;
  childmenuopen?: boolean;
}>(({ theme, open, childmenuopen }) => ({
  flexGrow: 1,
  height: '100%',
  "@media print": {
      height: "auto !important"    
  }

}));

export default Main;