import { GridColDef } from "@mui/x-data-grid-pro";
import { ApiBaseRoute } from "../../api_helper";
import moment from "moment";
import { AccountImportSearchResponse, AssessmentImportRowModel, ImportDetailSearchRequest, ImportSearchRequest } from "./Import.models";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export const GetImportStatusesTypeAhead = (searchTerm:string|undefined, token: string|undefined):Promise<Response> => {
    let uri = `${ApiBaseRoute}Imports/Importstatustypeahead?searchTerm=${searchTerm ?? ''}`;
    return fetch(uri, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
}

export const SearchImports = (request:ImportSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {...request};
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "Imports/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            ...requestCopy,
            userIDs: requestCopy.userIDs.map(x => x.value),
            statusIDs: requestCopy.statusIDs.map(x => x.value),
        })
    });
}

export const UploadAccounts = (fileUpload: File, token: string|undefined, projectName:string):Promise<Response> => {
    let payload = new FormData();
    payload.append('file', fileUpload);
    payload.append('projectName', projectName);
    return fetch(`${ApiBaseRoute}Imports/UploadAccountImport?projectName=${projectName}`, {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            "accept": "application/octet-stream"
        },
        body: payload
    });
};

export const DownloadAccountImportTemplate = (token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + `Imports/DownloadAccountImportTemplate`, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/octet-stream"
        }
    });
};

export const GetImportListColumns = ():GridColDef[] => {
    return [
        {field: "importID", headerName: "Batch ID", flex: 1, filterable: false},
        {field: "createdByName", headerName: "Created By", filterable: false, flex: 1},
        {field: "projectName", headerName: "Project Name", flex: 1, filterable: false},
        {field: "statusName", headerName: "Status", flex: 1, filterable: false},
        {
            field: "createdDate",
            headerName: "Date Created",
            filterable: false,
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        },{
            field: 'importType',
            headerName: "Type of Import",
            flex: 1, 
            filterable: false
        }
    ]
};

export const GetAssessmentImportListColumns = ():GridColDef[] => {
    return [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1
        },{
            field: 'importID',
            headerName: 'BatchID',
            flex: 1,
            filterable: false
        },{
            field: 'statusName',
            headerName: "Status",
            flex: 1,
            filterable: false
        },{
            field: 'isValid',
            headerName: 'Is Valid',
            flex: 1,
            filterable: false,
            renderCell: (params) => {
                return params.value === true ? <CheckIcon></CheckIcon> : params.value === false ? <CloseIcon></CloseIcon> : ''
            },
            align: "center"
        },{
            field: 'errors',
            headerName: 'Errors',
            flex: 1,
            filterable: false
        },{
            field: 'networkID',
            headerName: 'Network ID',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'parentID',
            headerName: 'Parent ID',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'facilityIDs',
            headerName: 'Facility IDs',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'recipients',
            headerName: 'Recipients',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'surveyTypeName',
            headerName: 'Assessment Type Name',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'assignedToEmail',
            headerName: 'Assigned To Email',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'dhtp',
            headerName: 'DHTP',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'language',
            headerName: 'Language',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'reasonForAssessment',
            headerName: 'Reason For Assessment',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'createEmptySurvey',
            headerName: 'Create Empty Assessment?',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'doNotSendCompletionEmail',
            headerName: "Do Not Send Completion Email",
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'skip',
            headerName: 'Skip Row?',
            type: 'boolean',
            editable: true,
            flex: 1,
            filterable: false,
        }
    ];
}

export const UploadAssessments = (fileUpload: File, token: string|undefined, projectName:string):Promise<Response> => {
    let payload = new FormData();
    payload.append('file', fileUpload);
    payload.append('projectName', projectName);
    return fetch(`${ApiBaseRoute}Imports/UploadAssessmentImport?projectName=${projectName}`, {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            "accept": "application/octet-stream"
        },
        body: payload
    });
};

export const DownloadAssessmentImportTemplate = (token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + `Imports/DownloadAssessmentImportTemplate`, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/octet-stream"
        }
    });
};

export const SearchAssessmentImports = (request:ImportDetailSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {...request};
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "Imports/AssessmentImportSearch", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            ...requestCopy,
            statusIDs: requestCopy.statusIDs.map(x => x.value),
        })
    });
};

export const DeleteImports = (importIDs: string[], token?: string): Promise<Response> => {
    return fetch(ApiBaseRoute + 'Imports/DeleteImports', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(importIDs)
    });
};

export const UpdateAssessmentImportRow = (assessmentImport: AssessmentImportRowModel, token?: string): Promise<Response> => {
    return fetch(ApiBaseRoute + 'Imports/FileAssessmentImport', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify([assessmentImport])
    });
};

export const ValidateAssessmentImport = (importID: string, token?: string):Promise<Response> => {
    let uri = `${ApiBaseRoute}Imports/ValidateAssessmentImport?importID=${importID}`;
    return fetch(uri, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const ProcessAssessmentImport = (importID: string, token?: string):Promise<Response> => {
    let uri = `${ApiBaseRoute}Imports/ProcessAssessmentImport?importID=${importID}`;
    return fetch(uri, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const GetAccountImportListColumns = ():GridColDef[] => {
    return [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1
        },{
            field: 'importID',
            headerName: 'BatchID',
            flex: 1,
            filterable: false
        },{
            field: 'statusName',
            headerName: "Status",
            flex: 1,
            filterable: false
        },{
            field: 'isValid',
            headerName: 'Is Valid',
            flex: 1,
            filterable: false,
            renderCell: (params) => {
                return params.value === true ? <CheckIcon></CheckIcon> : params.value === false ? <CloseIcon></CloseIcon> : ''
            },
            align: "center"
        },{
            field: 'errors',
            headerName: 'Errors',
            flex: 1,
            filterable: false
        },{
            field: 'accountName',
            headerName: 'Account Name',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'facilityTypeName',
            headerName: 'Facility Type Name',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'assignedToEmail',
            headerName: 'AssignedToEmail',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'parentUniqueID',
            headerName: 'Parent Unique ID',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'networkUniqueIDs',
            headerName: 'Network Unique IDs',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'primaryServiceID',
            headerName: 'Primary Service ID',
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'typeOfHealthCareSettingID',
            headerName: 'Type Of Health Care Setting ID',
            flex: 1, 
            filterable: false,
            editable: true
        },{
            field: 'addressLine1',
            headerName: "Address Line 1",
            flex: 1,
            filterable: false,
            editable: true
        },{
            field: 'addressLine2',
            headerName: 'Address Line2 ',
            editable: true,
            flex: 1,
            filterable: false,
        },{
            field: 'city',
            headerName: 'City',
            editable: true,
            flex: 1,
            filterable: false
        },{
            field: 'postalCode',
            headerName: 'Postal Code',
            editable: true,
            flex: 1,
            filterable: false
        },{
            field: 'country',
            headerName: 'Country',
            editable: true,
            flex: 1,
            filterable: false
        },{
            field: 'geographicRegion',
            headerName: 'Geographic Region',
            editable: true,
            flex: 1,
            filterable: false
        },{
            field: 'phone',
            headerName: 'Phone',
            editable: true,
            flex: 1,
            filterable: false
        },{
            field: 'numberOfBeds',
            headerName: 'Number Of Beds',
            editable: true,
            flex: 1,
            filterable: false
        },{
            field: 'doNotContact',
            headerName: 'Do Not Contact',
            editable: true,
            flex: 1,
            filterable: false
        },{
            field: 'testOrganization',
            headerName: 'Test Organization',
            editable: true,
            flex: 1,
            filterable: false
        },{
            field: 'university',
            headerName: 'University',
            editable: true,
            flex: 1,
            filterable: false
        },{
            field: 'website',
            headerName: 'Website',
            editable: true,
            flex: 1,
            filterable: false
        },{
            field: 'emailConvention',
            headerName: 'Email Convention',
            editable: true,
            flex: 1,
            filterable: false
        },{
            field: 'skip',
            headerName: 'Skip Row?',
            type: 'boolean',
            editable: true,
            flex: 1,
            filterable: false,
        }
    ];
};

export const SearchAccountImports = (request:ImportDetailSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {...request};
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "Imports/AccountImportSearch", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            ...requestCopy,
            statusIDs: requestCopy.statusIDs.map(x => x.value),
        })
    });
};

export const UpdateAccountImportRow = (accountImportRow: AccountImportSearchResponse, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + 'Imports/FileAccountImport', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify([accountImportRow])
    });
};

export const ValidateAccountImport = (importID: string, token?: string):Promise<Response> => {
    let uri = `${ApiBaseRoute}Imports/ValidateAccountImport?importID=${importID}`;
    return fetch(uri, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const ProcessAccountImport = (importID: string, token?:string):Promise<Response> => {
    let uri = `${ApiBaseRoute}Imports/ProcessAccountImport?importID=${importID}`;
    return fetch(uri, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
}