import { ApiBaseRoute } from "../api_helper";

export function GetGeographicRegionTypeAheadSearch(token: string, searchTerm: string|undefined, countryIDs?: number[]): Promise<Response> {
    return fetch(ApiBaseRoute+`Regions/typeaheadSearch`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({searchTerm: searchTerm, countryIDs: countryIDs ?? []})
    });
};