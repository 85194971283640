import NumericDropDownModel from "../../models/NumericDropDown.model";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";
import { SearchResponseBase } from "../../models/SearchResponseBase.model";
import { User } from "../../models/User.model";
import { Location } from "react-router-dom";

export interface ImportSearchRequest extends SearchRequestBase
{
    userIDs: NumericDropDownModel[],
    statusIDs: NumericDropDownModel[],
    projectName?: string
};

export interface ImportDetailSearchRequest extends SearchRequestBase{
    importID: string,
    statusIDs: NumericDropDownModel[],
    isValid?: boolean
};

export interface AssessmentImportRowModel {
    id: number,
    importID: string,
    statusID: number,
    isValid: boolean,
    skip?: boolean,
    errors: string,
    networkID: string,
    parentID: string,
    facilityIDs: string,
    recipients: string,
    surveyTypeName: string,
    assignedToEmail: string,
    dhtp: string,
    language: string,
    reasonForAssessment: string,
    createEmptySurvey: string,
    doNotSendCompletionEmail: string
};

export interface ImportSearchResponse extends SearchResponseBase
{
    id: number
    importID: string,
    createdBy: number,
    createdByName: string
    createdDate: Date,
    projectName: string,
    statusID: number,
    statusName: string,
    importTypeID: number,
    importType: string,
};

export function CreateDefaultImportSearchRequest(appUser: User|undefined, location: Location|undefined):ImportSearchRequest{
    if(location && location.state?.componentState){
        return location.state.componentState;
    }
    return {
        userIDs: !!appUser ? [{label: appUser?.email, value: appUser.userID}] : [],
        statusIDs: [],
        projectName: undefined,
        page: 0,
        pageSize: 100,
        sortColumn: "CreatedDate",
        sortDirection: "DESC",
    }
};

export function CreateDefaultAssessmentImportSearchRequest(importID: string):ImportDetailSearchRequest{
    return {
        statusIDs: [],
        importID: importID,
        page: 0,
        pageSize: 100,
        sortColumn: "CreatedDate",
        sortDirection: "DESC",
    }
};

export interface AccountImportSearchResponse {
    id: number,
    importID: string,
    statusID: number,
    statusName: string,
    isValid: boolean|null,
    skip: boolean|null,
    errors: string,
    accountName: string,
    facilityTypeName: string,
    assignedToEmail: string,
    parentUniqueID: string,
    networkUniqueIDs: string,
    primaryServiceID: string,
    typeOfHealthCareSettingID: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    postalCode: string,
    country: string,
    geographicRegion: string,
    phone: string,
    numberOfBeds: string,
    doNotContact: string,
    testOrganization: string,
    university: string,
    website: string,
    emailConvention: string,
        
}