import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, FormControlLabel, Grid, List, ListItem, ListItemButton, Switch, TextField, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import TypeAhead from "../../components/formControls/TypeAheadBase.component";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import PrimaryServiceDropDown from "../../components/formControls/PrimaryServicesDropDown.component";
import TypeOfHealthCareSettingDropDown from "../../components/formControls/TypeOfHealthCareSettingsDropDown.component";
import Loading from "../../components/loading/Loading.component";
import GoBack from "../../components/rightMenuControls/GoBack.component";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { CreateDefaultAssessmentSearchRequest } from "../Assessments/Assessment.models";
import { AccountDetailModel, CreateDefaultAccountDetail, ExternalIDMapping, ModelIsValid } from "./Account.models";
import { DeleteAccount, GetAccountDetail, GetReportURL, SaveAccountDetail } from "./Account.service";
import AccountMergeDialog from "./AccountMergeDialog.component";
import { GetFacilityTypeTypeAheadSearch } from "../../services/FacilityType.service";
import { GetUserTypeAheadSearch, userHasPermission } from "../../services/User.service";
import { GetFacilityTypeAheadSearch } from "../../services/FacilityTypeAhead.service";
import { GetCountryTypeAheadSearch } from "../../services/Country.service";
import { GetGeographicRegionTypeAheadSearch } from "../../services/GeographicRegion.service";
import { useIsDirtyPrompt } from "../../components/formComponents/useIsDirtyPrompt.component";
import AccountCreateAssessmentDialog from "./AccountCreateAssessmentDialog.component";
import { CreateDefaultDocumentSearchRequest } from "../Documents/Document.models";
import AccountDetailsExternalMapping from "./AccountDetails.ExternalMapping.component";

const AccountDetails = () => {
    const {appUser} = useApiAuthorization();
    const {accountID} = useParams();
    const [saving, setSaving] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showValidationText, setShowValidationText] = React.useState<boolean>(false);
    const [showMergeDialog, setShowMergeDialog] = React.useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
    const [createChildDialogOpen, setCreateChildDialogOpen] = React.useState<boolean>(false);
    const [childFacilityType, setChildFacilityType] = React.useState<NumericDropDownModel|null>(null);
    const [childImportAddress, setChildImportAddress] = React.useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [accountDetail, setAccountDetail] = React.useState<AccountDetailModel>(CreateDefaultAccountDetail(location, appUser));
    const initialAccountDetail = React.useRef(accountID === 'new' ? accountDetail : null);
    const isDirty = useIsDirtyPrompt(initialAccountDetail.current,accountDetail);
    const [createAssessmentDialogOpen, setCreateAssessmentDialogOpen] = React.useState<boolean>(false);
    
    React.useEffect(() => {
        if(accountID && accountID !== 'new'){
            setLoading(true);
            let accountUniqueID = parseInt(accountID!);
            GetAccountDetail(accountUniqueID, appUser?.token)
                .then(response => response.json())
                .then(data => {
                    setAccountDetail(data);
                    initialAccountDetail.current = data;
                }).finally(() => {
                    setLoading(false);
                })

        }
    }, [accountID, appUser?.token]);

    React.useEffect(() => {
        if(!createChildDialogOpen){
            setChildFacilityType(null);
            setChildImportAddress(false);
        }
    }, [createChildDialogOpen]);

    const updateAccount = () => {
        if(!ModelIsValid(accountDetail)){
            setShowValidationText(true);
        }else{
            setSaving(true);

            if(accountID === 'new'){
                initialAccountDetail.current = accountDetail;
            }
            
            SaveAccountDetail(accountDetail, appUser?.token)
                .then(response => response.json())
                .then(data => {
                    if(accountID === 'new'){
                        navigate(`/accounts/${data.uniqueID}`);
                    }else{
                        setAccountDetail(data);
                        initialAccountDetail.current = data;
                    }
                }).finally(() => {
                    setSaving(false);
                    setShowValidationText(false);
                });
        }
    };

    const deleteAccount = () => {
        initialAccountDetail.current = accountDetail;
        DeleteAccount(accountDetail.uniqueID, appUser?.token)
            .then(response => {
                navigate("/accounts");
            });
    };

    const childMenuContent = (<RightMenu>
        <List component="nav">
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            <GoBack/>
            {userHasPermission("Account_Detail_Delete", appUser) &&
            <ListItemButton onClick={() => setDeleteDialogOpen(true)}>
                Delete
            </ListItemButton>
            }
            {userHasPermission("Account_Detail_Edit", appUser) && accountDetail.facilityType && accountDetail.facilityType.value === 8 &&
            <ListItemButton onClick={() => setCreateChildDialogOpen(true)}>
                Create New Child
            </ListItemButton>
            }
            {userHasPermission("Survey_Detail_Edit", appUser) &&
            <ListItemButton onClick={() => setCreateAssessmentDialogOpen(true)}>
                Create Assessment
            </ListItemButton>

            }
            {userHasPermission("Account_Detail_Edit", appUser) && accountDetail.facilityType?.value === 8 &&
            <ListItemButton onClick={() => setShowMergeDialog(true)}>
                Merge Account
            </ListItemButton>
            } 
            <Divider/>
            <ListItem>
                <Typography variant="h6">Links</Typography>
            </ListItem>
            {accountDetail.reportModels.map(reportModel => 
                <ListItemButton 
                    key={reportModel} 
                    component='a' 
                    href={GetReportURL(reportModel, accountDetail.uniqueID)}
                    target="_blank"
                >{reportModel} AAR</ListItemButton>
            )
            }
            {userHasPermission("Case_List_View", appUser) &&
            <ListItemButton component={Link} to={`/cases?facilityID=${accountDetail.uniqueID}&facilityName=${accountDetail.name}`}>
                Cases
            </ListItemButton>
            }
            {userHasPermission("Survey_List_View", appUser) &&
            <ListItemButton 
                component={Link} 
                to={`/assessments`} 
                state={{componentState: {...CreateDefaultAssessmentSearchRequest(), facilityIDs: [{label: accountDetail.name, value: accountDetail.uniqueID}]}}}
            >
                Assessments
            </ListItemButton>
            }
            {userHasPermission("Document_List_View", appUser) &&
            <ListItemButton onClick={() => navigate("/documents/", { relative: "path", state: { componentState: { ...CreateDefaultDocumentSearchRequest(undefined, undefined), isCurrent: false, facilityIDs: [{value: accountDetail.uniqueID, label: accountDetail.name}] }}})}>
                Documents
            </ListItemButton>
            }
            {userHasPermission("Validation_List_View", appUser) &&
            <ListItemButton component={Link} to={`/validations?facilityID=${accountDetail.uniqueID}&facilityName=${accountDetail.name}`}>
                Validations
            </ListItemButton>
            }
            <Divider/>
            <ListItem>
                <Typography variant="h6">Associations</Typography>
            </ListItem>
            {userHasPermission("Account_List_View", appUser) && !accountDetail.parentAccount &&
            <ListItemButton component={Link} to={`/accounts?parentAccountUniqueID=${accountDetail.uniqueID}&parentAccountName=${accountDetail.name}`}>
                Child Accounts
            </ListItemButton>
            }
            {userHasPermission("Account_Detail_View", appUser) && accountDetail.parentAccount && 
            <ListItemButton component={Link} to={`/accounts/${accountDetail.parentAccount.value}`}>
                Parent Account: {accountDetail.parentAccount.label}
            </ListItemButton>
            }
            {userHasPermission("Account_Detail_View", appUser) && accountDetail.facilityType && accountDetail.facilityType.value !== 14 && accountDetail.networkAccounts.map(x => {
                return (
                    <ListItemButton key={x.value} component={Link} to={`/accounts/${x.value}`}>
                        Network: {x.label}
                    </ListItemButton>
                )
            })}
        </List>
    </RightMenu>);

    return (
        <BaseLayout childMenu={(accountID !== 'new') ? childMenuContent : false} childMenuOpen={(accountID !== 'new')}>
            {(saving || loading) && <Loading />}
            {userHasPermission("Account_Detail_Edit", appUser) &&
                <Box display="flex" justifyContent="flex-end">
                    <Fab size="medium" color="primary" aria-label="save" variant="extended" onClick={updateAccount} disabled={!isDirty}>
                        {accountID === 'new' ? "Create" : "Save"}
                    </Fab>
                </Box>
            }
            <Typography variant="h6" gutterBottom>
                Account Details
            </Typography>
            <Grid container spacing={3} sx={{paddingBottom: "40px"}}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled
                        label="HIMSS ID"
                        value={accountDetail.uniqueID ?? ''}
                        fullWidth
                        variant='standard'
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Name"
                        fullWidth
                        required
                        value={accountDetail.name ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAccountDetail({...accountDetail, name: event.target.value})}
                        variant='standard'
                        error={showValidationText && !accountDetail.name}
                        helperText={showValidationText && !accountDetail.name ? "This field is required." : undefined}
                        disabled={!userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeAhead
                        value={accountDetail.facilityType}
                        label="Facility Type"
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => {
                            setAccountDetail({...accountDetail, facilityType: newValue, typeOfHealthCareSetting: null, primaryService: ''});
                        }}
                        searchCallback={(searchTerm?: string) => GetFacilityTypeTypeAheadSearch(appUser?.token!, searchTerm, [1,3,8,14])}
                        required
                        error={showValidationText && !accountDetail.facilityType}
                        helperText={showValidationText && !accountDetail.facilityType ? "This field is required." : undefined}
                        disabled={!userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeAhead
                        value={accountDetail.assignedToUser}
                        label="Assigned To"
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setAccountDetail({...accountDetail, assignedToUser: newValue})}
                        searchCallback={(searchTerm?: string) => GetUserTypeAheadSearch(appUser?.token!, searchTerm, true)}
                        required
                        error={showValidationText && !accountDetail.assignedToUser}
                        helperText={showValidationText && !accountDetail.assignedToUser ? "This field is required." : undefined}
                        disabled={!userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeAhead
                        value={accountDetail.parentAccount}
                        label="Parent Account"
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setAccountDetail({...accountDetail, parentAccount: newValue})}
                        searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, [8], null, null)}
                        disabled={!accountDetail.facilityType || accountDetail.facilityType?.value === 14 || accountDetail.facilityType?.value === 8 ||
                            !userHasPermission("Account_Detail_Edit", appUser)}
                        required={!!accountDetail.facilityType && accountDetail.facilityType?.value !== 14 && accountDetail.facilityType?.value !== 8}
                        error={showValidationText && !!accountDetail.facilityType && accountDetail.facilityType?.value !== 14 && accountDetail.facilityType?.value !== 8 && !accountDetail.parentAccount}
                        helperText={showValidationText && !!accountDetail.facilityType && accountDetail.facilityType?.value !== 14 && accountDetail.facilityType?.value !== 8 && !accountDetail.parentAccount ? "This field is required." : undefined}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TypeAheadMulti
                        value={accountDetail.networkAccounts}
                        label="Network Accounts"
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setAccountDetail({...accountDetail, networkAccounts: newValue})}
                        searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, [14], null, null)}
                        disabled={!accountDetail.facilityType || accountDetail.facilityType?.value === 14 || accountDetail.facilityType?.value === 8 ||
                            !userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PrimaryServiceDropDown
                        value={accountDetail.primaryService}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: string | null) => setAccountDetail({...accountDetail, primaryService: newValue ?? ''})}
                        facilityTypeID={accountDetail.facilityType ? accountDetail.facilityType.value : null}
                        disabled={!accountDetail.parentAccount || !userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeOfHealthCareSettingDropDown
                        value={accountDetail.typeOfHealthCareSetting}
                        facilityTypeID={accountDetail.facilityType ? accountDetail.facilityType.value : null}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setAccountDetail({...accountDetail, typeOfHealthCareSetting: newValue})}
                        disabled={!accountDetail.parentAccount || !userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Address Line 1"
                        value={accountDetail.address1 ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setAccountDetail({...accountDetail, address1: event.target.value})}
                        required={accountDetail.facilityType?.value !== 14}
                        fullWidth
                        variant="standard"
                        error={showValidationText && !accountDetail.address1 && accountDetail.facilityType?.value !== 14}
                        helperText={showValidationText && !accountDetail.address1 && accountDetail.facilityType?.value !== 14 ? "This field is required." : undefined}
                        disabled={!userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        value={accountDetail.address2 ?? ""}
                        label="PO Box/Suite"
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setAccountDetail({...accountDetail, address2: event.target.value})}
                        fullWidth
                        variant='standard'
                        disabled={!userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        value={accountDetail.city ?? ''}
                        label="City"
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setAccountDetail({...accountDetail, city: event.target.value})}
                        fullWidth
                        required={accountDetail.facilityType?.value !== 14}
                        variant="standard"
                        error={showValidationText && !accountDetail.city && accountDetail.facilityType?.value !== 14}
                        helperText={showValidationText && !accountDetail.city && accountDetail.facilityType?.value !== 14 ? "This field is required." : undefined}
                        disabled={!userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        value={accountDetail.postalCode ?? ''}
                        label="Postal Code"
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setAccountDetail({...accountDetail, postalCode: event.target.value})}
                        fullWidth
                        required={accountDetail.facilityType?.value !== 14}
                        variant="standard"
                        error={showValidationText && !accountDetail.postalCode && accountDetail.facilityType?.value !== 14}
                        helperText={showValidationText && !accountDetail.postalCode && accountDetail.facilityType?.value !== 14 ? "This field is required." : undefined}
                        disabled={!userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeAhead
                        value={accountDetail.country}
                        label="Country"
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setAccountDetail({...accountDetail, country: newValue, geographicalRegion: null})}
                        searchCallback={(searchTerm?: string) => GetCountryTypeAheadSearch(appUser?.token!, searchTerm)}
                        required
                        error={showValidationText && !accountDetail.country}
                        helperText={showValidationText && !accountDetail.country ? "This field is required." : undefined}
                        disabled={!userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeAhead
                        value={accountDetail.geographicalRegion}
                        label="Geographic Region/State"
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setAccountDetail({...accountDetail, geographicalRegion: newValue})}
                        searchCallback={(searchTerm?: string) => GetGeographicRegionTypeAheadSearch(appUser?.token!, searchTerm, accountDetail.country ? [accountDetail.country.value] : [])}
                        required={accountDetail.facilityType?.value !== 14}
                        disabled={!accountDetail.country || !userHasPermission("Account_Detail_Edit", appUser)}
                        error={showValidationText && !accountDetail.geographicalRegion && accountDetail.facilityType?.value !== 14}
                        helperText={showValidationText && !accountDetail.geographicalRegion && accountDetail.facilityType?.value !== 14 ? "This field is required." : undefined}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={accountDetail.region}
                        disabled
                        fullWidth
                        variant="standard"
                        label="Region"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        value={accountDetail.phone ?? ''}
                        label="Phone"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAccountDetail({...accountDetail, phone: event.target.value})}
                        required={accountDetail.facilityType?.value !== 14}
                        fullWidth
                        variant="standard"
                        error={showValidationText && !accountDetail.phone && accountDetail.facilityType?.value !== 14}
                        helperText={showValidationText && !accountDetail.phone && accountDetail.facilityType?.value !== 14 ? "This field is required." : undefined}
                        disabled={!userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid> 
                <Grid item xs={12} sm={6}>
                    <TextField
                        value={accountDetail.numberOfBeds ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setAccountDetail({...accountDetail, numberOfBeds: event.target.value})}
                        fullWidth
                        variant="standard"
                        label="Number of Beds"
                        type="number"
                        disabled={accountDetail.facilityType?.value === 3 || accountDetail.facilityType?.value === 14 || 
                            !userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControlLabel 
                        control={
                            <Switch
                                checked={accountDetail.doNotContact === true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setAccountDetail({...accountDetail, doNotContact: checked})}
                                disabled={!accountDetail.facilityType || accountDetail.facilityType?.value === 14 ||
                                    !userHasPermission("Account_Detail_Edit", appUser)}
                            />
                        }
                        label="Do Not Contact"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={accountDetail.testOrg === true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setAccountDetail({...accountDetail, testOrg: checked})}
                                disabled={!userHasPermission("Account_Detail_Edit", appUser)}
                            />
                        }
                        label="Test Organization"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={accountDetail.isUniversity === true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setAccountDetail({...accountDetail, isUniversity: checked})}
                                disabled={accountDetail.facilityType?.value !== 1 || !userHasPermission("Account_Detail_Edit", appUser)}
                            />
                        }
                        label="University?"
                    />
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Website"
                        value={accountDetail.website ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setAccountDetail({...accountDetail, website: event.target.value})}
                        fullWidth
                        variant='standard'
                        disabled={!userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Email Convention"
                        value={accountDetail.emailConvention ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setAccountDetail({...accountDetail, emailConvention: event.target.value})}
                        fullWidth
                        variant='standard'
                        disabled={!accountDetail.facilityType || accountDetail.facilityType?.value === 14 || !userHasPermission("Account_Detail_Edit", appUser)}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        External ID Mappings
                    </Typography>
                    <AccountDetailsExternalMapping 
                        accountDetail={accountDetail}
                        setRows={(newRows) => setAccountDetail({...accountDetail, externalMappingIDs: newRows as ExternalIDMapping[] ?? []})}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        value={ accountDetail.lastCreatedSurveyDate ? moment(accountDetail.lastCreatedSurveyDate).format("MM/DD/YYYY") : ""}
                        label="Last Created Assessment Date"
                        fullWidth
                        disabled
                        variant='standard'
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        value={accountDetail.lastCompletedSurveyDate ? moment(accountDetail.lastCompletedSurveyDate).format("MM/DD/YYYY") : ""}
                        label="Last Completed Assessment Date"
                        fullWidth
                        disabled
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        variant='standard'
                        value={accountDetail.dateModified ? moment(accountDetail.dateModified).format("MM/DD/YYYY") : ''}
                        disabled
                        label="Last Modified Date"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        variant='standard'
                        value={accountDetail.modifiedByUserName ?? ''}
                        disabled
                        label="Last Modified By User"
                    />
                </Grid>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                >
                    <DialogTitle>Action Confirmation</DialogTitle>
                    <DialogContent>
                        {!accountDetail.hasChildren &&
                        <DialogContentText>
                            Please confirm you wish to delete this account.
                        </DialogContentText>
                        }
                        {accountDetail.hasChildren && 
                        <DialogContentText>
                            We are unable to delete this account. Child facilities are associated with this record and need to be merged first in order to delete: <Link to={`/accounts?parentAccountUniqueID=${accountDetail.uniqueID}&parentAccountName=${accountDetail.name}`}>View Children</Link>
                        </DialogContentText>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                        <Button onClick={deleteAccount} autoFocus disabled={accountDetail.hasChildren}>Confirm</Button>
                    </DialogActions>
                </Dialog>
                <Dialog id="createChildDialog" open={createChildDialogOpen} onClose={() => setCreateChildDialogOpen(false)}>
                    <DialogTitle>Create Child Account</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Please select the facility type for the child account.</DialogContentText>
                        <TypeAhead
                            value={childFacilityType}
                            label="Facility Type"
                            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setChildFacilityType(newValue)}
                            searchCallback={(searchTerm?: string) => GetFacilityTypeTypeAheadSearch(appUser?.token!, searchTerm, [1,3])}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={childImportAddress}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setChildImportAddress(checked)}
                                />
                            }
                            label="Copy Address?"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCreateChildDialogOpen(false)}>Cancel</Button>
                        <Button 
                            onClick={() => {
                                let model = CreateDefaultAccountDetail();
                                navigate('/accounts/new');
                                setAccountDetail({
                                    ...model,
                                    facilityType: childFacilityType,
                                    parentAccount: {label: accountDetail.name, value: accountDetail.uniqueID},
                                    address1: childImportAddress ? accountDetail.address1 : '',
                                    address2: childImportAddress ? accountDetail.address2 : '',
                                    city: childImportAddress ? accountDetail.city : '',
                                    postalCode: childImportAddress ? accountDetail.postalCode : '',
                                    country: childImportAddress ? accountDetail.country : null,
                                    geographicalRegion: childImportAddress ? accountDetail.geographicalRegion : null
                                });
                                setCreateChildDialogOpen(false);
                            }}
                            disabled={!childFacilityType}
                        >Create</Button>
                    </DialogActions>
                </Dialog>
                <AccountMergeDialog
                    open={showMergeDialog}
                    onClose={() => setShowMergeDialog(false)}
                    uniqueID={accountDetail.uniqueID}
                />
                <AccountCreateAssessmentDialog
                    open={createAssessmentDialogOpen}
                    onClose={() => setCreateAssessmentDialogOpen(false)}
                    accountDetail={accountDetail}
                />
            </Grid>
        </BaseLayout>
    );
};

export default AccountDetails;