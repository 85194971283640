import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import Loading from "../../components/loading/Loading.component";
import { ResetPassword } from "./UserAccountDetail.service";

interface UserAccountResetPasswordDialogProps {
    open: boolean,
    onClose: () => void,
    userID: number
};


const UserAccountResetPasswordDialog = (props: UserAccountResetPasswordDialogProps) => {
    const [saving, setSaving] = React.useState<boolean>(false);
    const {appUser} = useApiAuthorization();
    const [responseLink, setResponseLink] = React.useState<string>('');

    const handleClose = () => {
        setResponseLink('');
        props.onClose();
    };

    const sendResetPassword = () => {
        setSaving(true);
        ResetPassword(props.userID, appUser!.token).then(response => {
            return response.json();
        }).then(data => setResponseLink(data)).finally(() => {
            setSaving(false);
        });
    };

    return (
        <React.Fragment>
            {saving && <Loading />}
            {!saving &&
            <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth='sm'>
                <DialogTitle>Reset User Password Link</DialogTitle>
                <DialogContent>
                    {responseLink === '' &&
                    <DialogContentText>
                        Click the Ok button below to create a link that can be sent to the user that will allow them to reset their password.
                    </DialogContentText>
                    }
                    {responseLink !== '' &&
                    <DialogContentText>
                        Copy the link below and forward this the user to reset thier password.
                    </DialogContentText>
                    }
                    {responseLink !== '' &&
                    <TextField
                        fullWidth
                        variant='standard'
                        value={responseLink}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={!!responseLink ? handleClose : sendResetPassword}>Ok</Button>
                </DialogActions>
            </Dialog>
            }
        </React.Fragment>
    );
}

export default UserAccountResetPasswordDialog;