import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { MapChart, MapPolygonSeries, geoMercator } from "@amcharts/amcharts5/map";
import regionGeoData from "@amcharts/amcharts5-geodata/data/countries2"
import countryGeoData from "@amcharts/amcharts5-geodata/worldLow";
import { ColorSet, ISpritePointerEvent, Tooltip, color } from "@amcharts/amcharts5";
import NumericDropDownModel from "../../../../models/NumericDropDown.model";
import { MapModel, PublishedScoresDataset } from "../PublishedScores/PublishedScores.models";
import { forEach } from "lodash";
import { Typography } from "@mui/material";
import { GeographicDataVisualization } from "../LatestPublishedScoresOverview/LatestPublishedScoresOverview.models";

export interface CountryMapProps {
    handleOnCountryChange: (selection:NumericDropDownModel) => void,
    mapData: PublishedScoresDataset[]|MapModel[]|GeographicDataVisualization[],
    countryToolTip: string,
    title?: string
};

function CountryMap(props: CountryMapProps){
    const countryMapRef = useRef<MapPolygonSeries>();
    const {handleOnCountryChange, mapData, countryToolTip, title} = props;
    
    const onCountryClick = useCallback((ev: ISpritePointerEvent) => {
        let dataItem = ev.target.dataItem;
        let data:any = dataItem!.dataContext;

        if (data.map === undefined) {
            return false;
        }

        const hardSetCountry:NumericDropDownModel = { 
            value: data.countryID, 
            label: data.name
        }; 

        handleOnCountryChange(hardSetCountry);
    }, [handleOnCountryChange]);
    
    //initializes the dom object we're going to manipulate with the map
    useLayoutEffect(() => {
        let root = am5.Root.new("chartdiv");
        
        let colors = ColorSet.new(root, {
            colors: [
                color(0x1e22aa),
                color(0xa0c7dc),
                color(0xaaaaaa)
            ]
        });

        root.setThemes([
          am5themes_Animated.new(root)
        ]);
    
        let customTooltip = Tooltip.new(root, {
            getFillFromSprite: false
        });

        customTooltip.get("background")?.setAll({
            fill: color(0xffffff),
            fillOpacity: 1,
            stroke: color(0x000000),
            strokeOpacity: 1
        });

        let chart = root.container.children.push(MapChart.new(root, {
            projection: geoMercator(),
            wheelY: "none"
        }));

        let countryMap = chart.series.push(MapPolygonSeries.new(root, {
            geoJSON: countryGeoData,
            exclude: ["AQ"],
            tooltip: customTooltip
        }));

        countryMap.mapPolygons.template.setAll({
            tooltipHTML: `<div style='text-align:center; font-size:10px'><strong style='font-size:12px;'>{name}</strong><br /> <span style='text-align:left'>${countryToolTip}</span></div>`,
            stateAnimationDuration: 50,
            interactive: false,
            fill: colors.getIndex(2), //gray
            templateField: "visualSettings",
            fillOpacity: 1
        });
        
        countryMap.mapPolygons.template.states.create("hover", {
            fill: colors.getIndex(1) //light blue
        });

        //World View Click on a Country event
        countryMapRef.current = countryMap;

        return () => {
            root.dispose();
        };
    }, [countryToolTip]);


    useEffect(() => {
        countryMapRef.current!.mapPolygons.template.events.off("click");
        countryMapRef.current!.mapPolygons.template.events.on("click", onCountryClick); // this event needs to be updated whenever the onCountryClick even changes so it does not unnecessarily fire the useLayoutEffect - bad for performance
        let formattedCountryData:any = [];
        forEach(mapData, (record) => {
            let defaultFillColor = color(0x1e22aa);
            let country = regionGeoData[record.countryCode];
        
            formattedCountryData.push({...record,                
                id: record.countryCode,
                map: country.maps[0],
                countryID: record.countryID,
                hasResults: true,
                visualSettings: {
                    fill: defaultFillColor
                } 
            });
            
        });
        countryMapRef.current!.data.setAll(formattedCountryData);
    }, [mapData, onCountryClick]);

    return (
        <>
            {title &&
            <Typography variant="h6" align="center">{title}</Typography>         
            }
            <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>    
        </>
    );
};

export default CountryMap;