import { Location } from "react-router-dom";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";
import { SearchResponseBase } from "../../models/SearchResponseBase.model";

export interface ReleaseNoteSearchRequest extends SearchRequestBase {
    active: boolean|null,
};

export interface ReleaseNoteSearchResponse extends SearchResponseBase {
    releaseNoteID: number,
    active: boolean,
    title: string,
    details: string,
    dateCreated: Date,
    createdBy: number,
    createdByFullName: string,
    dateModified: Date,
    modifiedBy: number,
    modifiedByFullName: string,
};

export const CreateDefaultReleaseNoteSearchRequest = (location: Location):ReleaseNoteSearchRequest => {
    if (location && location.state?.componentState) {
        return location.state.componentState;
    }

    return {
        active: null,
        page: 0,
        pageSize: 100,
        sortColumn: "datecreated",
        sortDirection: "desc",
    }
};

export interface ReleaseNoteDetailModel {
    releaseNoteID: number,
    active: boolean,
    title: string,
    details: string,
    dateCreated: Date|null,
    createdBy: number|null,
    createdByFullName: string|null,
    dateModified: Date|null,
    modifiedBy: number|null,
    modifiedByFullName: string|null,
};

export const ModelIsValid = (model:ReleaseNoteDetailModel):Boolean => {
    let baseIsValid = !!model.title && !!model.details;

    return baseIsValid;
}

export const CreateDefaultReleaseNoteDetail = ():ReleaseNoteDetailModel => {
    return {
        releaseNoteID: 0,
        active: false,
        title: '',
        details: '',
        dateCreated: null,
        createdBy: null,
        createdByFullName: null,
        dateModified: null,
        modifiedBy: null,
        modifiedByFullName: null,
    }
};