import { ApiBaseRoute } from "../api_helper";

export function GetCaseTypeAheadSearch(token: string, searchTerm: string|undefined): Promise<Response> {
    return fetch(ApiBaseRoute+"cases/typeaheadSearch?searchTerm="+(searchTerm ? searchTerm : ''), {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};
