import { ApiBaseRoute } from "../api_helper";

export function GetPrimaryServiceSearch(token: string, facilityTypeID: number): Promise<Response> {
    return fetch(`${ApiBaseRoute}PrimaryServices/lookup?facilityTypeID=${facilityTypeID}`, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};