import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { ApiBaseRoute } from "../../api_helper";
import { AssessmentTypeSearchRequest } from "./AssessmentType.models";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Button } from "@mui/material";


export const GetAssessmentTypeListColumns = (callback:(surveyTypeID: number)=>void):GridColDef[] => {
    return [
        {field: "download", headerName: "Download", flex: .3, filterable: false, sortable: false, align: "center", headerAlign: "center", renderCell: (params) => {
            return <Button onClick={() => callback(params.id as number)}><DownloadForOfflineIcon/></Button>
        }},
        {field: "id", headerName: "Survey Type ID", flex: .3, filterable: false},
        {field: "surveyType", headerName: "Name", filterable: false, flex: 1},
        {field: "dateCreated", headerName: "Date Created", filterable: false, flex: .3, align: "center", headerAlign: "center", renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        },
        {field: "dateModified", headerName: "Date Modified", filterable: false, flex: .3, align: "center", headerAlign: "center", renderCell: (params) => {
            return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        }
    ]
};

export const SearchAssessmentTypes = (request: AssessmentTypeSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {...request};
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "SurveyTypes/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            ...requestCopy,
            surveyTypeIDs: requestCopy.surveyTypes.map(x => x.value),
        })
    });
};

export const DownloadQuestionsAndAnswers = (surveyTypeID: number, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + `SurveyTypes/DownloadQuestionsAndAnswers/${surveyTypeID}`, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/octet-stream"
        }
    });
};

export const UploadQuestionsAndAnswers = (fileUpload: File, token: string|undefined):Promise<Response> => {
    let payload = new FormData();
    payload.append('file', fileUpload);
    return fetch(`${ApiBaseRoute}SurveyTypes/UploadQuestionsAndAnswers`, {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            "accept": "application/octet-stream"
        },
        body: payload
    });
};