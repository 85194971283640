import { ApiBaseRoute } from "../../api_helper";
import { CaseDetailModel } from "./Case.models";

export const GetCaseDetail = (caseID: number, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "Cases/Detail?caseID=" + caseID, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const FileCase = (token: string|undefined, caseDetail: CaseDetailModel):Promise<Response> => {
    return fetch(ApiBaseRoute + "Cases/FileCase",{
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(caseDetail)
    });
};

export const DeleteCaseDetail = (caseID: number, token: string|undefined):Promise<Response> => {
    return fetch(`${ApiBaseRoute}Cases/DeleteCase?caseID=${caseID}`, {
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};