import { ApiBaseRoute } from "../api_helper";

export function GetFacilityTypeTypeAheadSearch(token: string, searchTerm: string|undefined, allowedFacilityTypes?: number[]): Promise<Response> {
    return fetch(`${ApiBaseRoute}facilityTypes/typeaheadSearch`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            searchTerm: searchTerm ?? '',
            allowedFacilityTypes: allowedFacilityTypes ?? []
        })
    });
};