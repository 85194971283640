import moment from "moment";
import NumericDropDownModel from "./NumericDropDown.model";

export interface MostRecentPublishedScoresRequest{
    modelID: NumericDropDownModel|null,
    modelIDs: NumericDropDownModel[],
    regionIDs: NumericDropDownModel[],
    countryIDs: NumericDropDownModel[],
    validationDateRange: [moment.Moment|null, moment.Moment|null],
    validationExpirationDateRange: [moment.Moment|null, moment.Moment|null],
    publicOnly: boolean,
    includeExpiredResults: boolean
};

export const CreateDefaultMostRecentPublishedScoresRequest = ():MostRecentPublishedScoresRequest => {
    return {
        modelID: {label: "EMRAM 2022", value: 12},
        modelIDs: [],
        regionIDs: [], 
        countryIDs: [],
        validationDateRange: [null,null],
        validationExpirationDateRange: [null, null],
        publicOnly: true,
        includeExpiredResults: false
    }
};