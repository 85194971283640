import { Box } from "@mui/material";

export const PrintHeader = () => {
    return (
        <Box
            id={"pageHeader"}
            sx={{
                display: "none",
                position: "relative",
                top: '0px',
                left: '0px',
                width: "100%",
                background: "#1e22aa",                   
                borderTop: "5px solid #55c1e9",

                "@media print": {
                    display: "block",
                    position: "fixed",
                    
                },
            }}
        >
            <Box sx={{
                height: "50px",
                width: "100%",
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                padding: "0px 50px"
                
            }}>
                <Box
                    component="img"
                    sx={{
                        height: "50px",
                        
                    }}
                    src="https://ha-static-content.s3.amazonaws.com/images/HIMSSlogo_Hwhitelogotype_RGB.svg"
                />
            </Box>
            
        </Box>
    );
};
