import { Divider, FormControl, Grid, InputLabel, List, ListItem, ListItemButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { DataGridPro as DataGrid, GridCallbackDetails, GridCellParams, GridColDef, GridPaginationModel, GridRowSelectionModel, GridSortModel, MuiEvent } from "@mui/x-data-grid-pro";
import { DateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import { forEach } from "lodash";
import React, { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { CreateDefaultAssessmentSearchRequest, AssessmentSearchRequest } from "./Assessment.models";
import { GetAssessmentListColumns, SearchAssessments } from "./Assessment.service";
import AssessmentImportCSVDialog from "./AssessmentImportCSVDialog.component";
import AssessmentMassDeleteDialog from "./AssessmentMassDeleteDialog.component";
import AssessmentMassUpdate from "./AssessmentMassUpdate.component";
import { GetUserTypeAheadSearch, userHasPermission } from "../../services/User.service";
import { GetAssessmentTypeAheadSearch } from "../../services/Assessment.service";
import { GetFacilityTypeAheadSearch } from "../../services/FacilityTypeAhead.service";
import { GetAssessmentTypeTypeAheadSearch } from "../../services/AssessmentType.service";
import moment from "moment";

function AssessmentList(){
    const {appUser} = useApiAuthorization();
    const columns: GridColDef[] = useMemo(() => GetAssessmentListColumns(appUser), [appUser]);
    const navigate = useNavigate();
    const location = useLocation();
    const [queryOptions, setQueryOptions] = React.useState<AssessmentSearchRequest>(CreateDefaultAssessmentSearchRequest(location, appUser));
    const [rowData, setRowData] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [showMassUpdate, setShowMassUpdate] = React.useState<boolean>(false);
    const [showMassDelete, setShowMassDelete] = React.useState<boolean>(false);
    const [showAssessmentImportDialog, setShowAssessmentImportDialog] = React.useState<boolean>(false);

    React.useEffect(() => {
        if(appUser?.token && !showMassUpdate && !showMassDelete){
            setIsLoading(true);
            SearchAssessments(queryOptions, appUser.token)
            .then((response) => response.json())
            .then((data) => {
                setRowData(data);
                if(data && data.length > 0){
                    setRowCount(data[0].totalCount);
                } else {
                    setRowCount(0);
                }
            }).finally(() => setIsLoading(false));
        }
    }, [queryOptions, appUser?.token, showMassUpdate, showMassDelete]); //only run useEffect if the queryOptions change

    const onPageChange = React.useCallback((newPage: number) => {
        setQueryOptions({...queryOptions, page: newPage});
    }, [queryOptions]);

    const onSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions({...queryOptions, sortColumn: sortParam.field, sortDirection: sortParam.sort});
        });
    }, [queryOptions]);

    const childMenuContent = (<RightMenu>
        <List component="nav">
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            {userHasPermission("Survey_Detail_Edit", appUser) &&
            <ListItemButton component={Link} to={"/assessments/new"}>
                Create New Assessment
            </ListItemButton>
            }
            {userHasPermission("Survey_List_Edit", appUser) &&
            <ListItemButton onClick={() => setShowMassUpdate(true)} disabled={selectionModel.length === 0}>
                Mass Update
            </ListItemButton>
            }
            {userHasPermission("Survey_List_Delete", appUser) &&
            <ListItemButton onClick={() => setShowMassDelete(true)} disabled={selectionModel.length === 0}>
                Mass Delete
            </ListItemButton>
            }
            <ListItemButton onClick={() => setQueryOptions(CreateDefaultAssessmentSearchRequest(location, appUser))} key="reset">
                Reset Filters
            </ListItemButton>
            <Divider/>
            <ListItem>
                <Typography variant="h6">Filters</Typography>
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, surveyIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetAssessmentTypeAheadSearch(appUser?.token!, searchTerm)}
                    value={queryOptions.surveyIDs}
                    label="Assessments"
                />
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, facilityIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, undefined, null, null)}
                    value={queryOptions.facilityIDs}
                    label="Facilities"
                />
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    value={queryOptions.surveyTypeIDs}
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, surveyTypeIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetAssessmentTypeTypeAheadSearch(appUser?.token!, searchTerm)}
                    label="Assessment Types"
                />
            </ListItem>
            <ListItem>
                <TypeAheadMulti 
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, assignedToUserIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetUserTypeAheadSearch(appUser?.token!, searchTerm, true)}
                    value={queryOptions.assignedToUserIDs}
                    label="Assigned To Users"
                />
            </ListItem>
            <ListItem>
                <TypeAheadMulti 
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, delegateUserIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetUserTypeAheadSearch(appUser?.token!, searchTerm, false)}
                    value={queryOptions.delegateUserIDs}
                    label="Assessment Users"
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel variant="standard">Statuses</InputLabel>
                    <Select
                        value={queryOptions.statuses}
                        multiple
                        onChange={(event: SelectChangeEvent<string[]>, child: React.ReactNode) => setQueryOptions({...queryOptions, statuses: event.target.value as string[], page: 0})}
                        label="Statuses"
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem value="Preparing">Preparing</MenuItem>
                        <MenuItem value="In Progress">In Progress</MenuItem>
                        <MenuItem value="Not Started">Not Started</MenuItem>
                        <MenuItem value="Submitted to HA">Submitted to HA</MenuItem>
                        <MenuItem value="In Review">In Review</MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
            <ListItem>
                <DateRangePicker
                    onAccept={(value: [moment.Moment|null, moment.Moment|null]) => setQueryOptions({...queryOptions, createdDateRange: value, page: 0})}
                    value={queryOptions.createdDateRange}
                    label="Created Date"
                    slots={{ field: SingleInputDateRangeField }}
                    slotProps={{textField: {variant: 'standard', fullWidth: true}}}
                    timezone="UTC"
                />
            </ListItem>
        </List>
    </RightMenu>);
    
    return (
        <BaseLayout childMenu={childMenuContent}>         
            <Grid sx={{display: "flex", height: "100%"}}>
                <DataGrid
                    columns={columns}
                    rows={rowData}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    paginationMode="server"
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: 'Assessments',
                            gridConfigName: 'assessmentGridState',
                            defaultConfig: {
                                columns:{
                                    columnVisibilityModel: {
                                        surveyGuid: false
                                    },
                                }
                            }
                        }
                    }}
                    onCellClick={(params: GridCellParams<any, any, any>, event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>, details: GridCallbackDetails<any>) => {
                        //this is a hack to get around setting the click event on the row to navigate when we also want to use links in cells
                        if(params.field === "externalUrl" || params.field === "internalUrl"){
                            event.stopPropagation();
                            return;
                        }
                        if (params.field === "__check__") {
                            return;
                        } 
                        //if it's not one of the above situations navigate to the record
                        navigate(`${params.id}`, {
                            state: {
                                fromPath: location.pathname,
                                componentState: {...queryOptions, createdDateRange: [queryOptions.createdDateRange[0] ? queryOptions.createdDateRange[0].toDate() : null, queryOptions.createdDateRange[1] ? queryOptions.createdDateRange[1].toDate() : null]}
                            }
                        });
                    
                    }}
                    checkboxSelection={appUser?.permissions && appUser.permissions.indexOf("Survey_List_Edit") > -1}
                    onRowSelectionModelChange={(selectionModel: GridRowSelectionModel) => setSelectionModel(selectionModel)}
                    rowSelectionModel={selectionModel}
                />
            </Grid>
            <AssessmentMassUpdate show={showMassUpdate} onClose={() => setShowMassUpdate(false)} selectedIDs={selectionModel} />
            <AssessmentMassDeleteDialog open={showMassDelete} onClose={() => setShowMassDelete(false)} selectedIDs={selectionModel} />
            <AssessmentImportCSVDialog open={showAssessmentImportDialog} onClose={() => setShowAssessmentImportDialog(false)}/>
        </BaseLayout>
    );
}

export default AssessmentList;