import { Divider, FormControl, Grid, InputLabel, List, ListItem, ListItemButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { DataGridPro as DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridRowSelectionModel, GridSortModel } from "@mui/x-data-grid-pro";
import { forEach } from "lodash";
import React, { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import BaseLayout from "../../layout/BaseLayout.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { CaseSearchRequest, CreateCaseSearchRequest } from "./Case.models";
import { GetCaseListColumns, SearchCases } from "./CaseList.service";
import RightMenu from "../../layout/RightMenu.component";
import { useQueryString } from "../../components/queryString/QueryString.provider";
import CaseMassUpdate from "./CaseMassUpdate.component";
import { GetCaseTypeAheadSearch } from "../../services/CaseNumber.service";
import { GetUserTypeAheadSearch, userHasPermission } from "../../services/User.service";
import { GetFacilityTypeAheadSearch } from "../../services/FacilityTypeAhead.service";
import { GetAssessmentTypeAheadSearch } from "../../services/Assessment.service";

function CaseList(){
    const {appUser} = useApiAuthorization();
    const columns: GridColDef[] = useMemo(() => GetCaseListColumns(appUser!), [appUser]);
    const presetParams = useQueryString();
    const [rowData, setRowData] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [showMassUpdate, setShowMassUpdate] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [queryOptions, setQueryOptions] = React.useState<CaseSearchRequest>(CreateCaseSearchRequest(presetParams, appUser, location));

    React.useEffect(() => {
        if(appUser?.token && !showMassUpdate){
            setIsLoading(true);
            SearchCases(queryOptions, appUser.token)
            .then((response) => response.json())
            .then((data) => {
                setRowData(data);
                if(data && data.length > 0){
                    setRowCount(data[0].totalCount);
                } else {
                    setRowCount(0);
                }
            }).finally(() => setIsLoading(false));
        }
    }, [queryOptions, showMassUpdate, appUser?.token]); //only run useEffect if the queryOptions change

    const onPageChange = React.useCallback((newPage: number) => {
        setQueryOptions({...queryOptions, page: newPage});
    }, [queryOptions]);

    const onSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions({...queryOptions, sortColumn: sortParam.field, sortDirection: sortParam.sort});
        });
    }, [queryOptions]);

    const childMenuContent = (<RightMenu>
        <List component="nav">
            {userHasPermission("Case_List_Edit", appUser) &&
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            }
            {userHasPermission("Case_List_Edit", appUser) &&
            <ListItemButton onClick={() => setShowMassUpdate(true)} disabled={selectionModel.length === 0}>
                Mass Update
            </ListItemButton>
            }
            <ListItemButton onClick={() => setQueryOptions(CreateCaseSearchRequest(presetParams, appUser, location))}>
                Reset Filters
            </ListItemButton>
            <Divider/>
            <ListItem>
                <Typography variant="h6">Filters</Typography>
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    searchCallback={(searchTerm?:string) => GetCaseTypeAheadSearch(appUser?.token!, searchTerm)}
                    onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, caseIDs: newValue, page: 0})}
                    value={queryOptions.caseIDs}
                    label={"Cases"}
                />
            </ListItem>
            <ListItem>
                <TypeAheadMulti 
                    onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, assignedToIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetUserTypeAheadSearch(appUser?.token!, searchTerm, true)}
                    value={queryOptions.assignedToIDs}
                    label="Assigned To"
                />
            </ListItem>
            <ListItem>
                <TypeAheadMulti 
                    onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, facilityIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, undefined, null, null)}
                    value={queryOptions.facilityIDs}
                    label="Facilities"
                />
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, surveyIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetAssessmentTypeAheadSearch(appUser?.token!, searchTerm)}
                    value={queryOptions.surveyIDs}
                    label="Assessments"
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel variant="standard">Status</InputLabel>
                    <Select
                        value={queryOptions.status}
                        onChange={(event:SelectChangeEvent) =>  setQueryOptions({...queryOptions, status: event.target.value})}
                        label="Status"
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem value="Any">Any</MenuItem>
                        <MenuItem value="New">New</MenuItem>
                        <MenuItem value="Closed">Closed</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
        </List>
    </RightMenu>);
    
    return (
        <BaseLayout childMenu={childMenuContent}>
            
            <Grid sx={{display: "flex", height: "100%"}}>
                <DataGrid
                    columns={columns}
                    rows={rowData}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    paginationMode="server"
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: 'Cases',
                            gridConfigName: 'caseGridState',
                            defaultConfig: {
                                columns: {
                                    columnVisibilityModel: {
                                        errorCode: false,
                                        description: false,
                                        status: false
                                    },
                                }
                            }
                        }
                    }}
                    onRowClick={(params: GridRowParams) => {
                        navigate(`${params.id}`, {
                            state: {
                                fromPath: location.pathname,
                                componentState: queryOptions
                            }
                        });
                    }}
                    checkboxSelection={appUser?.permissions && appUser.permissions.indexOf("Case_List_Edit") > -1}
                    onRowSelectionModelChange={(selectionModel: GridRowSelectionModel) => setSelectionModel(selectionModel)}
                    rowSelectionModel={selectionModel}
                />
            </Grid>
            <CaseMassUpdate show={showMassUpdate} onClose={() => setShowMassUpdate(false)} selectedIDs={selectionModel} />
        </BaseLayout>
    );
}

export default CaseList;