import { Select, MenuItem, FormControl, List, ListItem, Typography, ListItemButton, Divider, InputLabel, SelectChangeEvent, Grid } from "@mui/material";
import TypeAheadMulti from "../../../../components/formControls/TypeAheadMultiBase.component";
import BaseLayout from "../../../../layout/BaseLayout.component";
import RightMenu from "../../../../layout/RightMenu.component";
import NumericDropDownModel from "../../../../models/NumericDropDown.model";
import { GetCountryTypeAheadSearch } from "../../../../services/Country.service";
import { GetGeographicRegionTypeAheadSearch } from "../../../../services/GeographicRegion.service";
import { GetMaturityModelSearch } from "../../../../services/MaturityModel.service";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import CountryMap from "../Components/CountryMap.component";
import Loading from "../../../../components/loading/Loading.component";
import { DownloadPublishedScoresExport, GetPublishedScoresDataVizData } from "./PublishedScores.service";
import { useApiAuthorization } from "../../../../components/authorization/ApiAuthorizationProvider.component";
import { CreateDefaultPublishedScoresDataVisualization, PublishedScoresDataVisualization } from "./PublishedScores.models";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DateRange } from "@mui/x-date-pickers-pro/internals/models";
import { Moment } from "moment";
import { BarChart } from "@mui/x-charts/BarChart";
import { AchievementListSearchRequest, CreateDefaultAchievementListSearchRequest } from "../AchiementList/AchievementList.models";

export interface PublishedScoresProps {
}

function PublishedScores(props: PublishedScoresProps){
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {appUser} = useApiAuthorization();
    const [queryOptions, setQueryOptions] = useState<AchievementListSearchRequest>(CreateDefaultAchievementListSearchRequest(location));
    const [dataVizData, setDataVizData] = useState<PublishedScoresDataVisualization>(CreateDefaultPublishedScoresDataVisualization());

    useEffect(() => {
        if(appUser?.token){
            setIsLoading(true);
            GetPublishedScoresDataVizData(queryOptions, appUser?.token)
                .then(response => response.json())
                .then(data => {
                    setDataVizData(data); 
                }).finally(() => setIsLoading(false));
        }
        
    },[queryOptions, appUser?.token]);

    const downloadExport = useCallback(() => {
        setIsLoading(true);
        DownloadPublishedScoresExport(queryOptions, appUser?.token).then(response => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response.blob();
        }).then(blob => {
            if(blob != null)
            {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "PublishedScoresExport.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        }).finally(() => setIsLoading(false));
    },[queryOptions, appUser?.token]);

    const childMenuContent = (
        <RightMenu>
                <List component="nav">
                    <ListItem>
                        <Typography variant="h6">Action Items</Typography>
                    </ListItem>
                    <ListItemButton onClick={() => setQueryOptions(CreateDefaultAchievementListSearchRequest(location))} key="reset">
                        Reset Filters
                    </ListItemButton>
                    <ListItemButton onClick={downloadExport} disabled={isLoading}>
                        Download Export
                    </ListItemButton>
                    <Divider/>
                    <ListItem>
                        <Typography variant="h6">Filters</Typography>
                    </ListItem>                    
                    <ListItem>
                        <DateRangePicker
                            label="Published Date"
                            slots={{ field: SingleInputDateRangeField }}
                            slotProps={{
                                textField: {
                                    variant: "standard",
                                    fullWidth: true
                                }
                            }}
                            value={queryOptions.publishedDate ?? [null, null]}
                            onAccept={(value: DateRange<Moment>) => setQueryOptions((prevState) => { return {...prevState, publishedDate: value, page: 0}})}
                        />
                    </ListItem>
                    <ListItem>
                        <TypeAheadMulti
                            value={queryOptions.maturityModel}
                            onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => setQueryOptions((prevState) => { return {...prevState, maturityModel: newValue, page: 0} })}
                            label="Maturity Model"
                            searchCallback={(searchTerm?:string) => GetMaturityModelSearch(searchTerm, [7,8,9,10,11,12,13,15])}
                        />
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <InputLabel variant="standard">Stages</InputLabel>
                            <Select
                                value={queryOptions.stages}
                                multiple
                                onChange={(event: SelectChangeEvent<string[]>, child: React.ReactNode) => setQueryOptions((prevState) => { return {...prevState, stages: event.target.value as string[], page: 0} })}
                                label="Stages"
                                fullWidth
                                variant="standard"
                            >
                                <MenuItem value="0">Stage 0</MenuItem>
                                <MenuItem value="1">Stage 1</MenuItem>
                                <MenuItem value="2">Stage 2</MenuItem>
                                <MenuItem value="3">Stage 3</MenuItem>
                                <MenuItem value="4">Stage 4</MenuItem>
                                <MenuItem value="5">Stage 5</MenuItem>
                                <MenuItem value="6">Stage 6</MenuItem>
                                <MenuItem value="7">Stage 7</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <TypeAheadMulti
                            label="Search Countries"
                            value={queryOptions.countries}
                            onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => {
                                setQueryOptions((prevState) => { return {...prevState, countries: newValue, geographicRegions: [], page: 0} })}
                            }
                            searchCallback={(searchTerm?: string) => GetCountryTypeAheadSearch('', searchTerm)}
                        />
                    </ListItem>
                    <ListItem>
                        <TypeAheadMulti
                            label="Search States"
                            value={queryOptions.geographicRegions}
                            onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => setQueryOptions((prevState) => { return {...prevState, geographicRegions: newValue, page: 0} })}
                            searchCallback={(searchTerm?: string) => GetGeographicRegionTypeAheadSearch('', searchTerm, queryOptions.countries?.map(c => c.value))}
                            disabled={queryOptions.countries.length === 0}
                        />
                    </ListItem>
                </List>
            </RightMenu>
    );

    return (
        <BaseLayout childMenu={childMenuContent}>
            {isLoading && <Loading/>}
                <Grid container spacing={3} sx={{pb:10}}>
                    <Grid item xs={12} sm={6}>
                        <CountryMap
                            handleOnCountryChange={(newSelection) => {
                                let existingFilter = queryOptions.countries.filter(countrySelection => newSelection.value === countrySelection.value);
                                let countriesToSet = existingFilter.length > 0 ? queryOptions.countries.filter(country => country.value !== newSelection.value) : [...queryOptions.countries, newSelection];
                                setQueryOptions({...queryOptions, countries: countriesToSet});
                            }}
                            mapData={dataVizData.geographicDistributions}
                            title="Maturity Model Global Adoption"
                            countryToolTip="Stage 0 Facilities: {stage0Facilities} <br />Stage 1 Facilities: {stage1Facilities} <br />Stage 2 Facilities: {stage2Facilities} <br />Stage 3 Facilities: {stage3Facilities} <br />Stage 4 Facilities: {stage4Facilities} <br />Stage 5 Facilities: {stage5Facilities} <br />Stage 6 Facilities: {stage6Facilities} <br /> Stage 7 Facilities: {stage7Facilities}"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='h6' align="center">
                            Stage Distribution by Model
                        </Typography>
                        <BarChart
                            slotProps={{
                                legend: {
                                    direction: "column",
                                    position: {
                                        vertical:'middle',
                                        horizontal: 'right',
                                    },
                                }
                            }}
                            margin={{
                                right: 140
                            }}
                            dataset={dataVizData.stageDistributions}
                            height={500}
                            xAxis={[{scaleType: "band", dataKey: "stage"}]}
                            series={[
                                {dataKey: "amam", label: "AMAM", stack:"total"},
                                {dataKey: "ccomm", label: "C-COMM", stack:"total"},
                                {dataKey: "cisom", label: "CISOM", stack:"total"},
                                {dataKey: "dhi", label: 'DHI-CCMM', stack:"total"},
                                {dataKey: "diam", label: "DIAM", stack:"total"},
                                {dataKey: "emram", label: "EMRAM", stack:"total"},
                                {dataKey: "infram", label: "INFRAM", stack:"total"},
                                {dataKey: "oemram", label: 'O-EMRAM', stack:"total"},
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center">Model Distribution Totals</Typography>
                        <BarChart
                            margin={{
                                bottom: 80
                            }}
                            height={200}
                            dataset={dataVizData.modelDistributions}
                            layout="horizontal"
                            series={[
                                {dataKey: "amam", label: "AMAM", stack:"total"},
                                {dataKey: "ccomm", label: "C-COMM", stack:"total"},
                                {dataKey: "cisom", label: "CISOM", stack:"total"},
                                {dataKey: "dhi", label: "DHI", stack:"total"},
                                {dataKey: "diam", label: "DIAM", stack:"total"},
                                {dataKey: "emram", label: "EMRAM", stack:"total"},
                                {dataKey: "infram", label: "INFRAM", stack:"total"},
                                {dataKey: "oemram", label: "O-EMRAM", stack:"total"},

                            ]}
                            slotProps={{
                                legend: {
                                    direction: "row",
                                    position: {
                                        vertical:'bottom',
                                        horizontal: 'middle',
                                    },
                                }
                            }}
                        />
                    </Grid>
                </Grid>
        </BaseLayout>
      
    );
}

export default PublishedScores;