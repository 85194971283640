import { styled } from "@mui/material/styles";
import Container from '@mui/material/Container'


export const drawerWidth: number = 240;
export const childMenuWidth: number = 300;

const MainContainer = styled(Container, { shouldForwardProp: (prop:string) => !['open', 'childmenuopen'].includes(prop) })<{
  open?: boolean;
  childmenuopen?: boolean;
}>(({ theme, open, childmenuopen }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginTop: 80,
  marginLeft: 0,
  marginRight: 0,
  width: '100%',
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px !important`,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
  ...(childmenuopen && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: `${childMenuWidth}px !important`,
    width: `calc(100% - ${childMenuWidth}px)`,
  }),
  ...(open && childmenuopen && { 
    width: `calc(100% - ${childMenuWidth + drawerWidth}px)`,
  }),
  '@media print': {
    transition: 'none !important',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    marginTop: "20px"
  }
}));

export default MainContainer;