import { GridColDef, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GetAssessmentImportListColumns, GetImportStatusesTypeAhead, ProcessAssessmentImport, SearchAssessmentImports, UpdateAssessmentImportRow, ValidateAssessmentImport } from "./Imports.service";
import { ImportDetailSearchRequest, CreateDefaultAssessmentImportSearchRequest } from "./Import.models";
import { forEach } from "lodash";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import { Divider, Grid, List, ListItem, ListItemButton, Typography } from "@mui/material";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { DataGridPro } from "@mui/x-data-grid-pro";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import { useParams } from "react-router-dom";
import GoBack from "../../components/rightMenuControls/GoBack.component";
import { userHasPermission } from "../../services/User.service";

function AssessmentImportList(){
    const {appUser} = useApiAuthorization();
    const { importID } = useParams();
    const columns: GridColDef[] = useMemo(() => GetAssessmentImportListColumns(), []);
    const [queryOptions, setQueryOptions] = useState<ImportDetailSearchRequest>(CreateDefaultAssessmentImportSearchRequest(importID!));
    const [rowData, setRowData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [projectStatus, setProjectStatus] = useState('');


    useEffect(() => {
        if(appUser?.token){
            setIsLoading(true);
            SearchAssessmentImports(queryOptions, appUser.token)
            .then((response) => response.json())
            .then((data) => {
                setRowData(data);
                if(data && data.length > 0){
                    setRowCount(data[0].totalCount);
                    setProjectName(data[0].projectName);
                    setProjectStatus(data[0].projectStatus);
                } else {
                    setRowCount(0);
                }
            }).finally(() => setIsLoading(false));
        }
    }, [queryOptions, appUser?.token]); //only run useEffect if the queryOptions change

    const updateImportRow = useCallback((newRow:any, oldRow: any) => {
        let model = {
            id: newRow.id,
            importID: newRow.importID,
            statusID: newRow.statusID,
            isValid: newRow.isValid,
            skip: newRow.skip,
            errors: newRow.errors,
            networkID: newRow.networkID,
            parentID: newRow.parentID,
            facilityIDs: newRow.facilityIDs,
            recipients: newRow.recipients,
            surveyTypeName: newRow.surveyTypeName,
            assignedToEmail: newRow.assignedToEmail,
            dhtp: newRow.dhtp,
            language: newRow.language,
            reasonForAssessment: newRow.reasonForAssessment,
            createEmptySurvey: newRow.createEmptySurvey,
            doNotSendCompletionEmail: newRow.doNotSendCompletionEmail
        }
        setIsLoading(true);
        UpdateAssessmentImportRow(model, appUser?.token)
            .then((response) => {
                SearchAssessmentImports(queryOptions, appUser?.token)
                    .then((response) => response.json())
                    .then((data) => {
                        setRowData(data);
                        if(data && data.length > 0){
                            setRowCount(data[0].totalCount);
                            setProjectName(data[0].projectName);
                            setProjectStatus(data[0].projectStatus);
                        } else {
                            setRowCount(0);
                        }
                    }).finally(() => setIsLoading(false));
            });
        return newRow;
    }, [appUser?.token, queryOptions]);

    const validateImport = useCallback(() => {
        if(importID){
            setIsLoading(true);
            ValidateAssessmentImport(importID!, appUser?.token)
                .then(() => {
                    SearchAssessmentImports(queryOptions, appUser?.token)
                        .then((response) => response.json())
                        .then((data) => {
                            setRowData(data);
                            if(data && data.length > 0){
                                setRowCount(data[0].totalCount);
                                setProjectName(data[0].projectName);
                                setProjectStatus(data[0].projectStatus);
                            } else {
                                setRowCount(0);
                            }
                        });
                })
                .finally(() => setIsLoading(false));
        }
    }, [appUser?.token, importID, queryOptions]);

    const processImport = useCallback(() => {
        if(importID){
            setIsLoading(true);
            ProcessAssessmentImport(importID, appUser?.token)
                .then(() => {
                    SearchAssessmentImports(queryOptions, appUser?.token)
                        .then((response) => response.json())
                        .then((data) => {
                            setRowData(data);
                            if(data && data.length > 0){
                                setRowCount(data[0].totalCount);
                                setProjectName(data[0].projectName);
                                setProjectStatus(data[0].projectStatus);
                            } else {
                                setRowCount(0);
                            }
                        });
                })
                .finally(() => setIsLoading(false));
        }
    }, [appUser?.token, importID, queryOptions]);

    const onPageChange = useCallback((newPage: number) => {
        setQueryOptions({...queryOptions, page: newPage});
    }, [queryOptions]);

    const onSortModelChange = useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions({...queryOptions, sortColumn: sortParam.field, sortDirection: sortParam.sort});
        });
    }, [queryOptions]);

    const childMenuContent = (<RightMenu>
        <List component="nav">
            <ListItem>
                <Typography variant="h6">{`Project: ${projectName}`}</Typography>
            </ListItem>
            <ListItem>
                <Typography variant="body1">{`Status: ${projectStatus}`}</Typography>
            </ListItem>
            <Divider/>
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            <GoBack/>
            <ListItemButton onClick={() => setQueryOptions(CreateDefaultAssessmentImportSearchRequest(importID!))} key="reset">
                Reset Filters
            </ListItemButton>
            {userHasPermission("Import_Detail_Edit", appUser) && 
            <ListItemButton onClick={validateImport} disabled={projectStatus !== 'New' && projectStatus !== 'Validating'} key="validate">
                Validate Batch
            </ListItemButton>
            }
            {userHasPermission("Import_Detail_Edit", appUser) && 
            <ListItemButton onClick={processImport} disabled={projectStatus !== 'Validated'} key="process">
                Process Batch
            </ListItemButton>
            }
            <Divider/>
            <ListItem>
                <Typography variant="h6">Filters</Typography>
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, statusIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetImportStatusesTypeAhead(searchTerm,appUser?.token!)}
                    value={queryOptions.statusIDs}
                    label="Statuses"
                />
            </ListItem>
        </List>
    </RightMenu>);

    return (
        <BaseLayout childMenu={childMenuContent}>
            
            <Grid sx={{display: "flex", height: "100%"}}>
                <DataGridPro
                    columns={columns}
                    rows={rowData}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    paginationMode="server"
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: 'AssessmentImports',
                            gridConfigName: 'assessmentImportGridState'
                        }
                    }}
                    processRowUpdate={updateImportRow}
                    onProcessRowUpdateError={(error) => console.log(error)}
                    isCellEditable={(params) => (params.row.statusName === 'New' || params.row.statusName === 'Validating') && (params.row.projectStatus === "New" || params.row.projectStatus === "Validating")}
                    
                />
            </Grid>
            
        </BaseLayout>
    );
}

export default AssessmentImportList;