import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    Typography,
} from "@mui/material";
import {
    DataGridPro as DataGrid,
    GridCallbackDetails,
    GridCellParams,
    GridColDef,
    GridPaginationModel,
    GridRowSelectionModel,
    GridSortModel,
    MuiEvent,
} from "@mui/x-data-grid-pro";
import { forEach } from "lodash";
import React, { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import {
    CreateDefaultAARSearchRequest,
    AARSearchRequest,
    AARSearchResponse,
} from "./AAR.models";
import moment from "moment";
import { GetAARListColumns, SearchAARs } from "./AAR.service";
import {
    GetAARAssessmentTypeTypeAheadSearch,
    GetAssessmentTypeTypeAheadSearch,
} from "../../services/AssessmentType.service";
import { GetFacilityTypeAheadSearch } from "../../services/FacilityTypeAhead.service";
import { GetFacilityTypeTypeAheadSearch } from "../../services/FacilityType.service";
import { useQueryString } from "../../components/queryString/QueryString.provider";
import { GetReportURL } from "../Accounts/Account.service";

function AARList() {
    const { appUser } = useApiAuthorization();
    const columns: GridColDef[] = useMemo(() => GetAARListColumns(), []);
    const navigate = useNavigate();
    const location = useLocation();
    const presetQueryOptions = useQueryString();
    const [queryOptions, setQueryOptions] = React.useState<AARSearchRequest>(
        CreateDefaultAARSearchRequest(presetQueryOptions, location, appUser)
    );
    const [rowData, setRowData] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectionModel, setSelectionModel] =
        React.useState<GridRowSelectionModel>([]);

    React.useEffect(() => {
        if (appUser?.token) {
            setIsLoading(true);
            SearchAARs(queryOptions, appUser.token)
                .then((response) => response.json())
                .then((data) => {
                    setRowData(data);
                    if (data && data.length > 0) {
                        setRowCount(data[0].totalCount);
                    } else {
                        setRowCount(0);
                    }
                })
                .finally(() => setIsLoading(false));
        }
    }, [queryOptions, appUser?.token]); //only run useEffect if the queryOptions change

    const onPageChange = React.useCallback(
        (newPage: number) => {
            setQueryOptions({ ...queryOptions, page: newPage });
        },
        [queryOptions]
    );

    const onSortModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            forEach(sortModel, (sortParam) => {
                setQueryOptions({
                    ...queryOptions,
                    sortColumn: sortParam.field,
                    sortDirection: sortParam.sort,
                });
            });
        },
        [queryOptions]
    );

    const allowTextSelectionPreventClick = () =>
        window.getSelection()!.type === "Range" ? true : false;

    const childMenuContent = (
        <RightMenu>
            <List component="nav">
                <ListItem>
                    <Typography variant="h6">Action Items</Typography>
                </ListItem>
                <ListItemButton
                    onClick={() =>
                        setQueryOptions(
                            CreateDefaultAARSearchRequest(
                                presetQueryOptions,
                                location,
                                appUser
                            )
                        )
                    }
                    key="reset"
                >
                    Reset Filters
                </ListItemButton>
                <Divider />
                <ListItem>
                    <Typography variant="h6">Filters</Typography>
                </ListItem>
                <ListItem>
                    <TypeAheadMulti
                        onChange={(
                            event: React.SyntheticEvent,
                            newValue: NumericDropDownModel[]
                        ) =>
                            setQueryOptions({
                                ...queryOptions,
                                facilityTypeIDs: newValue,
                                page: 0,
                            })
                        }
                        searchCallback={(searchTerm?: string) =>
                            GetFacilityTypeTypeAheadSearch(
                                appUser?.token!,
                                searchTerm,
                                [1, 3, 8, 14]
                            )
                        }
                        value={queryOptions.facilityTypeIDs}
                        label="Facility Types"
                    />
                </ListItem>
                <ListItem>
                    <TypeAheadMulti
                        onChange={(
                            event: React.SyntheticEvent,
                            newValue: NumericDropDownModel[]
                        ) =>
                            setQueryOptions({
                                ...queryOptions,
                                facilityIDs: newValue,
                                page: 0,
                            })
                        }
                        searchCallback={(searchTerm?: string) =>
                            GetFacilityTypeAheadSearch(
                                appUser?.token!,
                                searchTerm,
                                undefined,
                                undefined,
                                undefined,
                                queryOptions.facilityTypeIDs.map(
                                    (x) => x.value
                                ),
                                null,
                                null
                            )
                        }
                        value={queryOptions.facilityIDs}
                        label="Facilities"
                    />
                </ListItem>
                <ListItem>
                    <TypeAheadMulti
                        value={queryOptions.surveyTypeIDs}
                        onChange={(
                            event: React.SyntheticEvent<Element, Event>,
                            newValue: NumericDropDownModel[]
                        ) =>
                            setQueryOptions({
                                ...queryOptions,
                                surveyTypeIDs: newValue,
                                page: 0,
                            })
                        }
                        searchCallback={(searchTerm?: string) =>
                            GetAARAssessmentTypeTypeAheadSearch(
                                appUser?.token!,
                                searchTerm
                            )
                        }
                        label="Assessment Types"
                    />
                </ListItem>
            </List>
        </RightMenu>
    );

    return (
        <BaseLayout childMenu={childMenuContent}>
            <Grid sx={{ display: "flex", height: "100%" }}>
                <DataGrid
                    initialState={{
                        columns: {
                            orderedFields: columns.map((col) => col.field),
                        },
                    }}
                    columns={columns}
                    rows={rowData.map((item: AARSearchResponse, index) => ({
                        id: index,
                        ...item,
                    }))}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{
                        page: queryOptions.page,
                        pageSize: queryOptions.pageSize,
                    }}
                    paginationMode="server"
                    onPaginationModelChange={(model: GridPaginationModel) =>
                        onPageChange(model.page)
                    }
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: "Reports",
                            gridConfigName: "reportsGridState",
                            defaultConfig: {
                                columns: {
                                    columnVisibilityModel: {
                                        surveyGuid: false,
                                    },
                                },
                            },
                        },
                    }}
                    onCellClick={(
                        params: GridCellParams<any, any, any>,
                        event: MuiEvent<
                            React.MouseEvent<HTMLElement, MouseEvent>
                        >,
                        details: GridCallbackDetails<any>
                    ) => {
                        //this is a hack to get around setting the click event on the row to navigate when we also want to use links in cells
                        if (
                            params.field === "accountUrl" ||
                            params.field === "internalUrl"
                        ) {
                            event.stopPropagation();
                            return;
                        }

                        allowTextSelectionPreventClick()
                            ? event.stopPropagation()
                            : window.open(
                                  GetReportURL(
                                      params.row.surveyType,
                                      params.row.uniqueID
                                  ),
                                  "_blank"
                              );
                    }}
                    onRowSelectionModelChange={(
                        selectionModel: GridRowSelectionModel
                    ) => setSelectionModel(selectionModel)}
                    rowSelectionModel={selectionModel}
                />
            </Grid>
        </BaseLayout>
    );
}

export default AARList;
