import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React from "react";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { GetTypeOfHealthCareSettingsSearch } from "../../services/TypeOfHealthCareSettings.service";
import { useApiAuthorization } from "../authorization/ApiAuthorizationProvider.component";

interface TypeOfHealthCareSettingDropDownProps {
    onChange(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel|null):void,
    value: NumericDropDownModel|null,
    disabled?: boolean,
    label?: string,
    required?: boolean,
    facilityTypeID: number|null
};

const TypeOfHealthCareSettingDropDown = (props:TypeOfHealthCareSettingDropDownProps) => {
    const {appUser} = useApiAuthorization();
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<NumericDropDownModel[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        let active = true;

        if (active && props.facilityTypeID && open) {
            setLoading(true);
            GetTypeOfHealthCareSettingsSearch(appUser?.token!, props.facilityTypeID)
            .then((response) => response.json())
            .then((data) => {
                if(props.value){
                    //ensure we have a localized option of the value already selected
                    data = [props.value, ...data.filter((option:NumericDropDownModel) => option.value !== props.value?.value)]
                }
                setOptions(data);
            }).finally(() => setLoading(false));
        }

        return () => {
            active = false;
        };
    }, [ appUser?.token, props.value, props.facilityTypeID, open]);

    return (
        <Autocomplete
            fullWidth
            disabled={!props.facilityTypeID || props.disabled}
            onChange={props.onChange}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={props.value ?? null}
            getOptionLabel={(option) => option.label}
            options={options}
            loading={loading}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.value}>{option.label}</li>
                );
            }}
            renderInput={(params) => (
                <TextField
                {...params}
                label={props.label ?? "Type of Health Care Setting"}
                variant="standard"
                required={props.required}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                    ),
                }}
                />
            )}
        />
    );
};

export default TypeOfHealthCareSettingDropDown;