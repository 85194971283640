import { useCallback, useEffect, useState } from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import { AccountCreateAssessmentModel, AccountDetailModel, CreateDefaultAccountCreateAssessmentModel } from "./Account.models";
import { useNavigate } from "react-router-dom";
import { CreateDefaultAssessmentDetail } from "../Assessments/Assessment.models";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import TypeAhead from "../../components/formControls/TypeAheadBase.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { GetAssessmentTypeTypeAheadSearch } from "../../services/AssessmentType.service";


interface AccountCreateAssessmentDialogProps {
    open: boolean,
    onClose: () => void,
    accountDetail: AccountDetailModel
}

const AccountCreateAssessmentDialog = (props: AccountCreateAssessmentDialogProps) => {
    const {appUser} = useApiAuthorization();
    const [createAssessmentModel, setCreateAssessmentModel] = useState<AccountCreateAssessmentModel>(CreateDefaultAccountCreateAssessmentModel());
    const [isValid, setIsValid] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsValid(!!createAssessmentModel.assessmentType && !!props.accountDetail.facilityType?.value);
    }, [createAssessmentModel.assessmentType, props.accountDetail.facilityType?.value]);
    
    const handleClose = useCallback(() => {
        setCreateAssessmentModel(CreateDefaultAccountCreateAssessmentModel());
        props.onClose();
    }, [props]);
    
    const createAssessment = useCallback(() => {
        let createModel = CreateDefaultAssessmentDetail(appUser);
        createModel.surveyType = createAssessmentModel.assessmentType;
        
        if (createModel.surveyType?.value === 33) {
            createModel.assignedToUser = { label: 'HA.System@user.org', value: 1 };
            createModel.digitalHealthTechnologyPartner = null;
            createModel.facilities = [{label: props.accountDetail.name, value: props.accountDetail.uniqueID}];
            createModel.parentFacility = null;
            createModel.lockFieldsForSES = true;
        } else if (props.accountDetail.facilityType?.value === 8 || props.accountDetail.facilityType?.value === 14){
            createModel.parentFacility = {label: props.accountDetail.name, value: props.accountDetail.uniqueID};
        } else {
            createModel.facilities = [{label: props.accountDetail.name, value: props.accountDetail.uniqueID}];
        }
        
        navigate('/assessments/new', {
            state:{
                componentState:createModel
            }
        });
    }, [appUser, createAssessmentModel.assessmentType, navigate, props.accountDetail.name, props.accountDetail.uniqueID, props.accountDetail.facilityType?.value]);

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>Create Assessment</DialogTitle>
            <DialogContent>
                <DialogContentText>Select the assessment type to be created.</DialogContentText>
                <TypeAhead
                    label="Assessment Type"
                    value={createAssessmentModel.assessmentType}
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setCreateAssessmentModel({...createAssessmentModel, assessmentType: newValue})}
                    searchCallback={(searchTerm?: string) => GetAssessmentTypeTypeAheadSearch(appUser?.token!, searchTerm, props.accountDetail.facilityType?.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={createAssessment} disabled={!isValid}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AccountCreateAssessmentDialog;