import { Grid, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { LatestPublishedScoresDetailsProps, StagePercentAchievedByFocusAreaModel } from "./LatestPublishedScoresDetails.models";
import { BarChart } from "@mui/x-charts";
import { useApiAuthorization } from "../../../../components/authorization/ApiAuthorizationProvider.component";
import { GetLatestPublishedScoresDetailsDataVisualization } from "./LatestPublishedScoresDetails.service";
import { GlobalColors } from "../../../../constants/GlobalColors.const";

const StagePercentAchievedByFocusArea = (props: LatestPublishedScoresDetailsProps) => {
    const [dataVizData, setDataVizData] = useState<StagePercentAchievedByFocusAreaModel[]>([]);
    const { onLoad, queryOptions } = props;
    const { appUser } = useApiAuthorization();     
    
    const handleOnLoad = useRef(onLoad);

    useEffect(() => {
        handleOnLoad.current(true);
        GetLatestPublishedScoresDetailsDataVisualization(queryOptions, "5", appUser?.token)
            .then(response => response.json())
            .then(data => {
                setDataVizData(data);
            })
            .finally(() => handleOnLoad.current(false));

    }, [queryOptions, handleOnLoad, appUser?.token]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle1" textAlign="center">
                    Stage Percent Achieved By Focus Area
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <BarChart
                    sx={{
                        '& .MuiBarLabel-root': {
                            fill: '#fff',
                        }
                    }}
                    slotProps={{
                        legend: {
                            direction: "row",
                            position: {
                                vertical: 'bottom',
                                horizontal: 'middle',
                            },
                        }
                    }}
                    height={500}
                    margin={{ top: 5, bottom: 160 }}
                    xAxis={[
                        {
                            scaleType: 'band',
                            data: ['Stage 1', 'Stage 2', 'Stage 3', 'Stage 4', 'Stage 5', 'Stage 6', 'Stage 7']
                        },

                    ]}
                    yAxis={[{
                        min: 0,
                        max: 100
                    }]}
                    series={dataVizData.map(x => {
                        return {
                            data: [x.stage1, x.stage2, x.stage3, x.stage4, x.stage5, x.stage6, x.stage7],
                            label: x.focusArea,
                            valueFormatter: (value, context) => {
                                return value !== null ? `${(value ?? 0).toFixed(0)}%` : 'N/A'
                            }
                        }
                    })}
                    barLabel={props.axisLable}
                    colors={GlobalColors}
                />
            </Grid>
        </Grid>
    )
};

export default StagePercentAchievedByFocusArea;