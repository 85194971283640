import { useAuth0 } from '@auth0/auth0-react';
import BaseLayout from '../../layout/BaseLayout.component';

const Forbidden = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    return (
            <BaseLayout>
                <h3>Access Denied</h3>
                { !isAuthenticated && (
                    <div>
                        <h3>You aren't supposed to be here.  Please login.</h3>
                        <button onClick={() => loginWithRedirect()}>Log In</button>
                    </div>
                )}
                { isAuthenticated && (
                    <div>
                        You do not have the necessary permissions to view this page. If you need access, please submit a request through our ticketing system.
                        <br/>
                        <a href="https://forms.monday.com/forms/e5b458c4e1634a1fdcb8d9d5671e8833?r=use1" target="_blank" rel="noreferrer">Submit Access Request</a>

                    </div>
                )}
            </BaseLayout>
    );
};
  
export default Forbidden;