import { BarItem } from "@mui/x-charts";
import { MostRecentPublishedScoresRequest } from "../../../../models/MostRecentPublishedScoresRequest.model";

export interface GeographicDataVisualization
{
    country: string,
    countryCode: string,
    countryID: number,
    geographicalRegion: string,
    geographicalRegionID: number,
    stateProvinceCode: string,
    modelID: number,
    modelName: string
};

export interface ModelStageDistribution {
    stage0Count: number,
    stage1Count: number,
    stage2Count: number,
    stage3Count: number,
    stage4Count: number,
    stage5Count: number,
    stage6Count: number,
    stage7Count: number,
    model: string
};

export type LatestPublishedScoresOverviewResponse =
{
    modelStageDistributions: ModelStageDistribution[],
    healthSystemCount: number,
    facililtyCount: number,
    geographicRegionData: GeographicDataVisualization[],
    stage6Count: number,
    stage7Count: number
};

export const CreateDefaultLatestPublishedScoresOverviewDataVisualization = (): LatestPublishedScoresOverviewResponse => {

    return {
        modelStageDistributions: [],
        healthSystemCount: 0,
        facililtyCount: 0,
        geographicRegionData: [],
        stage6Count: 0,
        stage7Count: 0
    }
};

export interface LatestPublishedScoresOverviewProps {
    queryOptions: MostRecentPublishedScoresRequest,
    onLoad: (isLoading: boolean) => void,
    axisLable?: (itemL:BarItem) => string|undefined,
    updateQueryOptions?: (options:MostRecentPublishedScoresRequest) => void
};