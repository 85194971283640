import { GridColDef } from "@mui/x-data-grid-pro";
import { ApiBaseRoute } from "../../api_helper";
import { UserRoleDetailModel } from "./UserRole.models";
import { Switch } from "@mui/material";

export const GetUserRole = (userRoleID: number, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "UserRoles/" + userRoleID, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const FileUserRole = (userRoleModel: UserRoleDetailModel, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "UserRoles/FileUserRole", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(userRoleModel)
    });
};

export const GetPermissions = (token: string, searchTerm?: string):Promise<Response> => {
    return fetch(ApiBaseRoute + `Permissions/TypeAhead?searchTerm=${searchTerm??''}`, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    })
};

export const GetUserRolePermissionsListColumns = ():GridColDef[] => {
    return [
        { field: "moduleName", headerName: "Module", flex: 1, filterable: false, sortable:false, renderCell:(params) => {
            return params.row.moduleName + ' ' + params.row.moduleType
        } },
        { field: "view", headerName: "View", flex: 1, filterable: false, sortable:false, headerAlign:"center", align:"center", renderCell: (params) => {
            return <Switch disabled checked={params.value}/>
        }},
        { field: "edit", headerName: "Edit", flex: 1, filterable: false, sortable:false, headerAlign:"center", align:"center", renderCell: (params) => {
            return <Switch disabled checked={params.value}/>
        }},
        { field: "delete", headerName: "Delete", flex: 1, filterable: false, sortable:false, headerAlign:"center", align:"center", renderCell: (params) => {
            return <Switch disabled checked={params.value}/>
        }},
        { field: "export", headerName: "Export", flex: 1, filterable: false, sortable:false, headerAlign:"center", align:"center", renderCell: (params) => {
            return <Switch disabled checked={params.value}/>
        }}
    ]
};