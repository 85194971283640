import { Drawer } from "@mui/material";

interface RightMenuProps {
    children?: React.ReactNode,
};
const RightMenu = (props: RightMenuProps) => {
    return (
        <Drawer 
            sx={{
                width: 300,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 300,
                    boxSizing: 'border-box',
                    top: "65px",
                    zIndex: "1000",
                    paddingBottom: "70px"
                },
            }}
            variant="persistent" 
            open={true} 
            anchor="right">
                {props.children}
        </Drawer>
    );
};
export default RightMenu;