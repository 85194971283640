import { useCallback, useState } from "react";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import { RemoveFacilites } from "./Assessment.service";
import Loading from "../../components/loading/Loading.component";
import { Alert, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

interface RemoveFacilitiesDialogProps {
    surveyID: number,
    open: boolean,
    onClose: () => void,
    facilites: NumericDropDownModel[]
}
const RemoveFacilitiesDialog = (props: RemoveFacilitiesDialogProps) => {
    const [removeFacilitesModel, setRemoveFacilitesModel] = useState<NumericDropDownModel[]>([]);
    const [processing, setProcessing] = useState<boolean>(false);
    const {appUser} = useApiAuthorization();
    const [confirmed, setConfirmed] = useState<boolean>(false);

    const handleClose = useCallback(() => {
        setRemoveFacilitesModel([]);
        setConfirmed(false);
        props.onClose();
    }, [props]);

    const handleConfirm = useCallback(() => {
        setProcessing(true);
        RemoveFacilites(props.surveyID, removeFacilitesModel, appUser?.token).then(response => {
            if(response.status === 200){
                setProcessing(false);
                handleClose();
            }
        }).catch(error => {
            //show error here
        }).finally(() => {
            setProcessing(false);
        });
    }, [appUser?.token, handleClose, removeFacilitesModel, props.surveyID]);

    return (
        <>
            {processing && <Loading/>}
            {!processing && 
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>Remove Facilites</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please select the facilites to remove from this assessment:</DialogContentText>
                    <Autocomplete
                        multiple
                        value={removeFacilitesModel}
                        options={props.facilites}
                        renderInput={
                            (params) => 
                            <TextField 
                                required 
                                {...params} 
                                label="Facilites to Remove" 
                                variant="standard"
                                error={removeFacilitesModel.length === 0}
                                helperText={removeFacilitesModel.length === 0 ? "At least one facility is required." : undefined}
                            />
                        }
                        onChange={(event: React.SyntheticEvent<Element, Event>, value: NumericDropDownModel[]) => setRemoveFacilitesModel(value)}
                    />
                    {confirmed &&
                    <Alert severity="warning">
                        Are you certain you wish to exclude the above selections from this assessment? Please note that all data entered up to this point will be irreversibly lost.
                    </Alert>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {confirmed && 
                    <Button onClick={handleConfirm} disabled={removeFacilitesModel.length === 0 || removeFacilitesModel.length === props.facilites.length}>Confirmed</Button>
                    }
                    {!confirmed &&
                    <Button onClick={() => setConfirmed(true)}>Remove</Button>
                    }
                </DialogActions>
            </Dialog>
            }
        </>
    );
};

export default RemoveFacilitiesDialog;