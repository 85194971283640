import { useCallback } from "react";
import PublishedScores from "./DataVisualizations/PublishedScores/PublishedScores.component";
import AchievementList from "./DataVisualizations/AchiementList/AchievementList.component";
import { useParams } from "react-router";
import Loading from "../../components/loading/Loading.component";
import PrivateRoute from "../../components/authorization/PrivateRoute.container";
import NotFound from "../NotFound/NotFound.component";
import LatestPublishedScoresOverview from "./DataVisualizations/LatestPublishedScoresOverview/LatestPublishedScoresOverview.component";
import LatestPublishedScoresDetails from "./DataVisualizations/LatestPublishedScoresDetails/LatestPublishedScoresDetails.component";

export interface InsightDetailProps {

}

const InsightDetail = (props: InsightDetailProps) => {
    const {dashboard} = useParams();
    const mapPathToComponent = useCallback(() => {
        if(dashboard){
            switch(dashboard!.toLowerCase()){
                case 'publishedscores':
                    return <PrivateRoute accessToken="InsightsPublishedScores_Detail_View"><PublishedScores/></PrivateRoute>;
                case 'achievementlist':
                    return <AchievementList/>;
                case 'standalone':
                    return <AchievementList hideHeader={true} hideNavigation={true} />;
                case 'latestpublishedscoresoverview':
                    return <PrivateRoute accessToken='InsightsLatestPublishedScoresOverview_Detail_View'><LatestPublishedScoresOverview/></PrivateRoute>;
                case 'latestcompletedassessmentdetails':
                    return <PrivateRoute accessToken="InsightsLatestPublishedScoresDetail_Detail_View"><LatestPublishedScoresDetails/></PrivateRoute>
                default:
                    return <NotFound/>
            }
        }
        return <Loading/>;
    }, [dashboard]);

    return (mapPathToComponent())
}

export default InsightDetail;