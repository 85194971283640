import NumericDropDownModel from "../../models/NumericDropDown.model";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";

export interface AssessmentTypeSearchRequest extends SearchRequestBase {
    surveyTypes: NumericDropDownModel[],
}

export function CreateDefaultAssessmentTypeSearchRequest():AssessmentTypeSearchRequest {
    return {
        page: 0,
        pageSize: 100,
        sortColumn: "surveyType",
        sortDirection: "ASC",
        surveyTypes: []
    }
}