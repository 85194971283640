import { ApiBaseRoute } from "../api_helper";

export const GetDHTPTypeAhead = (searchTerm: string|undefined, surveyTypeID: number|undefined, token: string|undefined):Promise<Response> => {
    return fetch(`${ApiBaseRoute}DigitalHealthTechnologyPartners/TypeAheadSearch?searchTerm=${searchTerm ?? ''}&surveyTypeID=${surveyTypeID ?? ''}`, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};