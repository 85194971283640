import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { ApiBaseRoute } from "../../api_helper";
import { AssessmentDetailModel, AssessmentMassUpdateModel, AssessmentResendEmailModel, AssessmentSearchRequest } from "./Assessment.models";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { GridAlignment } from "@mui/x-data-grid-pro";
import { User } from "../../models/User.model";
import { userHasPermission } from "../../services/User.service";


export const GetAssessmentListColumns = (appUser?:User):GridColDef[] => {

    return [
        // { field: "id", headerName: "Validation ID", flex: .4, filterable: false },
        { field: "surveyID", headerName: "Assessment ID", flex: .4, filterable: false },
        { field: "surveyName", headerName: "Name", flex: 1, filterable: false},
        { field: "surveyType", headerName: "Assessment Type", flex: .6, filterable: false},
        { field: "assignedToUserName", headerName: "Assigned To", flex: 1, filterable: false},
        { field: "status", headerName: "Status", flex: .6, filterable: false},
        {
            field: "dateCreated",
            headerName: "Date Created",
            flex: .6,
            filterable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment.utc(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment.utc(params.value).format("MM/DD/YYYY") : "";
            }
        },
        { field: "isParent", headerName: "Is Parent", flex: .6, sortable: false, filterable: false, align: "center", headerAlign: "center", renderCell: (params) => {
            return params.value ? <CheckIcon></CheckIcon> : <CloseIcon></CloseIcon>
        }},
        { field:"surveyGuid", headerName: "Assessment GUID", flex: .4, sortable: false, filterable: false},
        ...(userHasPermission('Global_AssessmentLinks_View', appUser)) ? [
            {
                field: "externalUrl",
                headerName: "External Link",
                flex: .5,
                sortable: false,
                filterable: false,
                align: "center" as GridAlignment,
                headerAlign:"center" as GridAlignment,
                valueGetter: (params:any) => `${process.env.REACT_APP_EXTERNAL_BASE_SURVEY_URL}surveyLanding/${params.row.surveyGuid}/home`,
                renderCell: (params:any) =>{
                    return <Link href={`${process.env.REACT_APP_EXTERNAL_BASE_SURVEY_URL}surveyLanding/${params.row.surveyGuid}/home`} target="_blank"><OpenInNewIcon/></Link>
                }
            },
            {
                field: "internalUrl",
                headerName: "Internal Link",
                flex: .5,
                sortable: false,
                filterable: false,
                align: "center" as GridAlignment,
                headerAlign:"center" as GridAlignment,
                valueGetter: (params:any) => `${process.env.REACT_APP_INTERAL_BASE_SURVEY_URL}surveys/${params.id}`,
                renderCell: (params:any) =>{
                    return <Link href={`${process.env.REACT_APP_INTERAL_BASE_SURVEY_URL}surveys/${params.id}`} target="_blank"><OpenInNewIcon/></Link>
                }
            }
        ] : [],
    ]
};

export const SearchAssessments = (request: AssessmentSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {
        ...request, 
        surveyIDs: request.surveyIDs.map(x => x.value),
        assignedToUserIDs: request.assignedToUserIDs.map(x => x.value),
        delegateUserIDs: request.delegateUserIDs.map(x => x.value),
        surveyTypeIDs: request.surveyTypeIDs.map(x => x.value),
        parentSurvey: request.parentSurvey === 'Any' ? undefined : request.parentSurvey === 'Yes',
        facilityIDs: request.facilityIDs.map(x => x.value),
        createdDate: request.createdDateRange[0] ?  request.createdDateRange[0].startOf("day") : null,
        createdDateEnd: request.createdDateRange[1] ? request.createdDateRange[1].startOf("day") : null
    };
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "Surveys/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(requestCopy)
    });
};

export const MassUpdateAssessments = (model: AssessmentMassUpdateModel, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "Surveys/MassUpdate", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(model)
    });
};

export const GetAssessmentDetail = (surveyID: number, token: string|undefined): Promise<Response> => {
    return fetch(`${ApiBaseRoute}Surveys/Details?id=${surveyID}`, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const FileAssessmentDetail = (model: AssessmentDetailModel, token: string|undefined): Promise<Response> => {
    return fetch(ApiBaseRoute + "Surveys/Details", {
        method: model.surveyID === 0 ? "POST" : "PUT",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({...model, surveyGUID: model.surveyGUID === '' ? null : model.surveyGUID })
    });
};

export const DeleteAssessmentDetail = (surveyID: number, token: string|undefined): Promise<Response> => {
    return fetch(`${ApiBaseRoute}Surveys/Details?id=${surveyID}`, {
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const MassDeleteAssessmentDetail = (surveyIDs: number[], token: string|undefined): Promise<Response> => {
    return fetch(`${ApiBaseRoute}Surveys/MassDelete`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(surveyIDs)
    });
};

export const ResendNotifications = (model: AssessmentResendEmailModel, token?: string): Promise<Response> => {
    return fetch(`${ApiBaseRoute}Surveys/ResendNotifications`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(model)
    });
};

export const RemoveFacilites = (surveyID: number, facilitiesToRemove: NumericDropDownModel[], token?: string): Promise<Response> => {
    return fetch(`${ApiBaseRoute}Surveys/RemoveFacilities?surveyID=${surveyID}`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(facilitiesToRemove.map(f => f.value))
    });
};