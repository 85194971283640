import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, useGridApiContext } from "@mui/x-data-grid";
import moment from "moment";
import { useApiAuthorization } from "../authorization/ApiAuthorizationProvider.component";
import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { Button } from "@mui/material";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { userHasPermission } from "../../services/User.service";

export interface NoFilterToolbarProps {
    moduleName?: string,
    gridConfigName?: string,
    defaultConfig?: GridInitialStatePro
}

const NoFilterToolBar = (props: NoFilterToolbarProps) => {
    const apiRef = useGridApiContext();

    const defaultInitialState = useMemo(() => {
        return apiRef.current.exportState();
        // we only want this code to run on the initial render, by adding dependencies below, this code will run on each detected change of those dependencies so we are excluding them here
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);

    const initialState = useMemo<GridInitialStatePro>(() => {
        const stateFromLocalStorage = props.gridConfigName ? localStorage?.getItem(props.gridConfigName) : '{}';
        let localConfig = stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : props.defaultConfig ?? {};
        return localConfig;
        // we only want this code to run on the initial render, by adding dependencies below, this code will run on each detected change of those dependencies so we are excluding them here
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveSnapshot = useCallback(() => {
        if (apiRef?.current?.exportState && localStorage && props.gridConfigName) {
            const currentState = apiRef.current.exportState();
            currentState.pagination = undefined;
            localStorage.setItem(props.gridConfigName, JSON.stringify(currentState));
        }
    }, [apiRef, props.gridConfigName]);

    useEffect(() => {
        if(initialState && initialState.columns){
            apiRef.current.restoreState(initialState);
        }
    }, [initialState, apiRef]);

    useLayoutEffect(() => {
        window.addEventListener('beforeunload', saveSnapshot);
        return () => {
            window.removeEventListener('beforeunload', saveSnapshot);
            saveSnapshot();
        };
    }, [saveSnapshot, initialState, apiRef]);

    const resetLayout = useCallback(() => {
        let configToRestore = { 
            ...defaultInitialState,
            ...props.defaultConfig ?? {}
        };
        apiRef.current.restoreState(configToRestore);
    }, [props.defaultConfig, defaultInitialState, apiRef]);

    const {appUser, isAuthenticated} = useApiAuthorization();
    const getCurrentDateString = () => {
        var now = new Date();
        
        return moment(now).format("YYYYMMDD");
    };

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton onResize={undefined} onResizeCapture={undefined} />
            <GridToolbarDensitySelector onResize={undefined} onResizeCapture={undefined} />
            {isAuthenticated && userHasPermission("Global_List_Export", appUser) &&
                <GridToolbarExport
                    csvOptions={{
                        fileName: `Beacon_${props.moduleName ?? ''}_${getCurrentDateString()}`,
                        utf8WithBom: true
                    }}
                />
            }
            {props.gridConfigName &&
                <Button 
                    onClick={resetLayout} 
                    startIcon={<SettingsBackupRestoreIcon/>}
                >
                    Reset Layout
                </Button>

            }
        </GridToolbarContainer>
    )
};

export default NoFilterToolBar;