import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItemButton } from "@mui/material";
import { Link } from "react-router-dom";
import { AssessmentSearchResponse } from "./Assessment.models";

interface AssessmentDetailValidationModalProps {
    open: boolean,
    message: string|null,
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void,
    onConfirm: () => void,
    existingAssessments: AssessmentSearchResponse[]
}
const AssessmentDetailValidationModal = (props: AssessmentDetailValidationModalProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Duplicates assessments have been found.</DialogTitle>
            <DialogContent>
                <DialogContentText>The following assessments were found for this organization and assessment type. Ignore duplicates and save?</DialogContentText>
                <List>
                    {props.existingAssessments.map(assessment => <ListItemButton key={assessment.surveyID} component={Link} to={`/assessments/${assessment.surveyID}`}>Assessment ID:{assessment.surveyID} - {assessment.surveyName}</ListItemButton>)}
                </List>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button onClick={props.onConfirm}>Ignore/Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssessmentDetailValidationModal;