import { Grid, List, ListItem,ListItemButton, Typography } from "@mui/material";
import { DataGridPro as DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
import { forEach } from "lodash";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import BaseLayout from "../../layout/BaseLayout.component";
import { ReleaseNoteSearchRequest, CreateDefaultReleaseNoteSearchRequest } from "./ReleaseNote.models";
import { GetReleaseNoteListColumns, SearchReleaseNotes } from "./ReleaseNote.service";
import RightMenu from "../../layout/RightMenu.component";
import { userHasPermission } from "../../services/User.service";

function ReleaseNotesList(){
    const {appUser} = useApiAuthorization();
    const columns:GridColDef[] = useMemo(() => GetReleaseNoteListColumns(), []);
    const location = useLocation();
    const [queryOptions, setQueryOptions] = React.useState<ReleaseNoteSearchRequest>(CreateDefaultReleaseNoteSearchRequest(location));
    const [rowData, setRowData] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        if(appUser?.token){
            setIsLoading(true);
            SearchReleaseNotes(queryOptions, appUser.token)
            .then((response) => response.json())
            .then((data) => {
                setRowData(data);
                if(data && data.length > 0){
                    setRowCount(data[0].totalCount);
                } else {
                    setRowCount(0);
                }
            }).finally(() => setIsLoading(false));
        }
    }, [queryOptions, appUser?.token]);

    const onPageChange = React.useCallback((newPage: number) => {
        setQueryOptions({...queryOptions, page: newPage});
    }, [queryOptions]);

    const onSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions({...queryOptions, sortColumn: sortParam.field, sortDirection: sortParam.sort});
        });
    }, [queryOptions]);

    const childMenuContent = (<RightMenu>
        <List component="nav">
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            {userHasPermission("ReleaseNote_Detail_Edit", appUser) &&
            <ListItemButton onClick={() => navigate('new')}>
                Create New Release Note 
            </ListItemButton>
            }
        </List>
    </RightMenu>);

    return (
        <BaseLayout childMenu={childMenuContent}>
            
            <Grid sx={{display: "flex", height: "100%"}}>
                <DataGrid
                    columns={columns}
                    rows={rowData}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    paginationMode="server"
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: 'ReleaseNotes',
                            gridConfigName: 'releaseNoteGridState'
                        }
                    }}
                    onRowClick={(params: GridRowParams) => {
                        navigate(`${params.id}`, {
                            state: {
                                fromPath: location.pathname,
                                componentState: queryOptions
                            }
                        });
                    }}
                />
            </Grid>
        </BaseLayout>
    );
}

export default ReleaseNotesList;