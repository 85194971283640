import { GridColDef } from "@mui/x-data-grid";
import { ApiBaseRoute } from "../../api_helper";
import { UserRoleSearchRequest } from "./UserRole.models";

export const GetUserRoleListColumns = ():GridColDef[] => {
    return [
        { field: "id", headerName: "Role ID", flex: .4, filterable: false },
        { field: "userRole", headerName: "Role Name", flex: 1, filterable: false},
        { field: "userRoleDescription", headerName: "Description", flex: 1, filterable: false},
    ]
};

export const SearchUserRoles = (request: UserRoleSearchRequest, token: string):Promise<Response> => {
    let requestCopy = {...request};
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "UserRoles/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            ...requestCopy,
            userRoleIDs: requestCopy.userRoleIDs.map(x => x.value)
        })
    });
};

export const GetRoles = (token: string):Promise<Response> => {
    return fetch(ApiBaseRoute + "UserRoles", {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};