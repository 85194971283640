import { Location } from "react-router-dom";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";
import { SearchResponseBase } from "../../models/SearchResponseBase.model";

export interface EmailTemplateDetailModel {
    emailTemplateID: number|null,
    name: string,
    fileName: string,
    surveyTypes: NumericDropDownModel[],
    language: NumericDropDownModel|null,
    type: string,
    content: string,
}

export const CreateDefaultEmailTemplateDetail = (defaultValue?: EmailTemplateDetailModel):EmailTemplateDetailModel => {
    return {
        emailTemplateID: null,
        name: '',
        fileName: '',
        surveyTypes: [],
        language: null,
        type: '',
        content: '',
    };
};

export interface EmailTemplateSearchRequest extends SearchRequestBase {
    emailTemplateIDs: NumericDropDownModel[],
    surveyTypeIDs: NumericDropDownModel[],
    languageIDs: NumericDropDownModel[],
    types: string[],
};

export const CreateDefaultEmailTemplateListSearchRequest = (location?: Location):EmailTemplateSearchRequest => {
    if (location && location.state?.componentState) {
        return location.state.componentState;
    }

    return {
        page: 0,
        pageSize: 100,
        sortColumn: "Name",
        sortDirection: "ASC",
        emailTemplateIDs: [],
        surveyTypeIDs: [],
        languageIDs: [],
        types: [],
    }
};

export interface EmailTemplateSearchResponse extends SearchResponseBase {
    id: number,
    emailTemplateID: number,
    name: string,
}