import { Grid, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { BarChart } from "@mui/x-charts";
import { useApiAuthorization } from "../../../../components/authorization/ApiAuthorizationProvider.component";
import { GetLatestPublishedScoresDataViz } from "./LatestPublishedScoresOverview.service";
import { AssessmentsCompletedByRegionDataViz, CreateDefaultAssessmentsCompletedByRegionDataViz } from "../LatestPublishedScoresDetails/LatestPublishedScoresDetails.models";
import { LatestPublishedScoresOverviewProps } from "./LatestPublishedScoresOverview.models";
import { GlobalColors, ModelColors } from "../../../../constants/GlobalColors.const";

const AssessmentsCompletedByRegion = (props: LatestPublishedScoresOverviewProps) => {
    const [dataVizData, setDataVizData] = useState<AssessmentsCompletedByRegionDataViz>(CreateDefaultAssessmentsCompletedByRegionDataViz());
    const { onLoad, queryOptions } = props;
    const { appUser } = useApiAuthorization();     
    const handleOnLoad = useRef(onLoad);

    useEffect(() => {
        handleOnLoad.current(true);
        GetLatestPublishedScoresDataViz(queryOptions, 2, appUser?.token)
            .then(response => response.json())
            .then(data => {
                setDataVizData(data);
            })
            .finally(() => handleOnLoad.current(false));

    }, [queryOptions, handleOnLoad, appUser?.token]);

    return(
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle1" textAlign="center" sx={{mt:5, mb:5}}>
                Published Scores By Region
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <BarChart
                    sx={{
                        '& .MuiBarLabel-root': {
                            fill: '#fff'
                        }
                    }}
                    slotProps={{
                        legend: {
                            direction: "row",
                            position: {
                                vertical:'bottom',
                                horizontal: 'middle',
                            },
                        }
                    }}
                    height={500}
                    margin={{ top: 5, bottom: 90 }}
                    xAxis={[{data: dataVizData.regions, scaleType: "band"}]}
                    series={
                        dataVizData?.models?.map(x => {
                            return {
                                data: dataVizData?.regions.map(region => {
                                    return dataVizData?.assessmentsCompletedByRegions.filter(record => record.region === region && record.model === x)[0].total ?? 0;
                                }),
                                label: x,
                                stack: "total",
                                color: ModelColors[x] ?? "#1e22aa"
                            }
                        })
                    }
                    barLabel={props.axisLable}
                    colors={GlobalColors}
                />
            </Grid>
        </Grid>
    );
};

export default AssessmentsCompletedByRegion;