import moment from "moment";
import { ApiBaseRoute } from "../../api_helper";
import { DocumentDetailRequestModel, DocumentSearchRequest } from "./Document.models";
import { Stack, Tooltip, IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export const GetDocumentListColumns = (callback:(fileName: string, s3Link: string) => void):GridColDef[] => {
    return [
        {
            field: 'actions',
            headerName: '',
            flex: 0,
            filterable: false,
            sortable: false,
            align: 'center',
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Stack
                        direction='row'
                        spacing={1}
                    >
                        <Tooltip
                            title='Download'
                            placement='top'
                        >
                            <IconButton
                                aria-label='Download File'
                                color='primary'
                                size='small'
                                onClick={ (e) =>
                                    {callback(params.row.originalFileName, params.row.s3Link);
                                    e.stopPropagation();}
                                }
                            >
                                <DownloadIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            }
        },
        { field: "originalFileName", headerName: "File Name", flex: 1, filterable: false},
        { field: "questionText", headerName: "Question", flex: 1, filterable: false},
        { field: "createdByFullName", headerName: "Created By", flex: .6, filterable: false},
        { field: "uniqueID", headerName: "Facility ID", flex: .4, filterable: false},
        {
            field: "createdDate",
            headerName: "Date Created",
            flex: .4,
            filterable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        },
        {
            field: "isCurrent",
            headerName: "Most Recent",
            flex: .6,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return params.value ? <CheckIcon></CheckIcon> : <CloseIcon></CloseIcon>
            }
        },
    ]
};

export const SearchDocuments = (request: DocumentSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {
        ...request,
        assessmentIDs: request.assessmentIDs.map(x => x.value),
        facilityIDs: request.facilityIDs.map(x => x.value)
    };
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "Documents/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(requestCopy)
    });
};

export const GetDocumentDetail = (request: DocumentDetailRequestModel, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "Documents/Detail", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(request)
    });
};

export const DownloadDocument = (s3Data: FormData, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "Documents/GetDocumentByFileName", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
        },
        body: s3Data
    });
};