import { DataGridPro, GridColDef, GridPaginationModel, GridRowParams, GridRowSelectionModel, GridSortModel } from "@mui/x-data-grid-pro";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import { GetImportListColumns, GetImportStatusesTypeAhead, SearchImports } from "./Imports.service";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateDefaultImportSearchRequest, ImportSearchRequest } from "./Import.models";
import { useCallback, useEffect, useMemo, useState } from "react";
import { forEach } from "lodash";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import { Divider, Grid, List, ListItem, ListItemButton, TextField, Typography } from "@mui/material";
import { GetUserTypeAheadSearch, userHasPermission } from "../../services/User.service";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import AccountImportDialog from "./AccountImportDialog.component";
import AssessmentImportDialog from "./AssessmentImportDialog.component";
import MassDeleteImport from "./MassDeleteImportDialog.component";

function ImportList(){
    const {appUser} = useApiAuthorization();
    const columns: GridColDef[] = useMemo(() => GetImportListColumns(), []);
    const navigate = useNavigate();
    const location = useLocation();
    const [queryOptions, setQueryOptions] = useState<ImportSearchRequest>(CreateDefaultImportSearchRequest(appUser, location));
    const [rowData, setRowData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showAccountImport, setShowAccountImport] = useState<boolean>(false);
    const [showAssessmentImport, setShowAssessmentImport] = useState<boolean>(false);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    useEffect(() => {
        if(appUser?.token && !showAssessmentImport && !showDeleteDialog && !showAccountImport){
            setIsLoading(true);
            SearchImports(queryOptions, appUser.token)
            .then((response) => response.json())
            .then((data) => {
                setRowData(data);
                if(data && data.length > 0){
                    setRowCount(data[0].totalCount);
                } else {
                    setRowCount(0);
                }
            }).finally(() => setIsLoading(false));
        }
    }, [queryOptions, appUser?.token, showAssessmentImport, showDeleteDialog, showAccountImport]); //only run useEffect if the queryOptions change

    const onPageChange = useCallback((newPage: number) => {
        setQueryOptions({...queryOptions, page: newPage});
    }, [queryOptions]);

    const onSortModelChange = useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions({...queryOptions, sortColumn: sortParam.field, sortDirection: sortParam.sort});
        });
    }, [queryOptions]);

    const childMenuContent = (<RightMenu>
        <List component="nav">
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            {userHasPermission("Import_List_Edit", appUser) &&
            <ListItemButton onClick={() => setShowAccountImport(true)}>
                Create New Account Import
            </ListItemButton>
            }
            {userHasPermission("Import_List_Edit", appUser) && 
            <ListItemButton onClick={() => setShowAssessmentImport(true)}>
                Create New Assessment Import
            </ListItemButton>
            }
            {userHasPermission("Import_List_Edit", appUser) && 
            <ListItemButton onClick={() => setShowDeleteDialog(true)} disabled={selectionModel.length === 0}>
                Mass Delete Imports
            </ListItemButton>
            }
            <ListItemButton onClick={() => setQueryOptions(CreateDefaultImportSearchRequest(appUser,location))} key="reset">
                Reset Filters
            </ListItemButton>
            <Divider/>
            <ListItem>
                <Typography variant="h6">Filters</Typography>
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, userIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetUserTypeAheadSearch(appUser?.token!, searchTerm, true)}
                    value={queryOptions.userIDs}
                    label="Created By Users"
                />
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, statusIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetImportStatusesTypeAhead(searchTerm,appUser?.token!)}
                    value={queryOptions.statusIDs}
                    label="Statuses"
                />
            </ListItem>

            <ListItem>
                <TextField
                    fullWidth
                    variant="standard"
                    value={queryOptions.projectName ?? ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setQueryOptions({...queryOptions, projectName: event.target.value, page:0})}
                    label="Project Name"
                />
            </ListItem>
        </List>
    </RightMenu>);

    return (
        <BaseLayout childMenu={childMenuContent}>
            
            <Grid sx={{display: "flex", height: "100%"}}>
                <DataGridPro
                    columns={columns}
                    rows={rowData}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    paginationMode="server"
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: 'Imports',
                            gridConfigName: 'importGridState'
                        }
                    }}
                    onRowClick={(params: GridRowParams) => {
                        if(params.row.importTypeID === 1){
                            navigate(`AccountImports/${params.row.importID}`, {
                                state: {
                                    fromPath: location.pathname,
                                    componentState: queryOptions
                                }
                            });
                        }else if(params.row.importTypeID === 2){
                            navigate(`AssessmentImports/${params.row.importID}`, {
                            state: {
                                fromPath: location.pathname,
                                componentState: queryOptions
                            }
                        });
                        }
                        
                    }}
                    getRowId={(row) => row.importID}
                    checkboxSelection
                    onRowSelectionModelChange={(selectionModel: GridRowSelectionModel) => setSelectionModel(selectionModel)}
                    rowSelectionModel={selectionModel}
                    isRowSelectable={(params: GridRowParams) => params.row.statusName === 'New' || params.row.statusName === "Validating"}
                />
            </Grid>
            <MassDeleteImport show={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} selectedIDs={selectionModel}/>
            <AccountImportDialog open={showAccountImport} onClose={() => setShowAccountImport(false)}/>
            <AssessmentImportDialog open={showAssessmentImport} onClose={() => setShowAssessmentImport(false)}/>
        </BaseLayout>
    );
}

export default ImportList;