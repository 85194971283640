import { Location } from "react-router-dom";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";
import { SearchResponseBase } from "../../models/SearchResponseBase.model";

export interface UserAccountSearchRequest extends SearchRequestBase
{
    userIDs: NumericDropDownModel[],
    userRoleIDs: NumericDropDownModel[],
    isInternalText: string,
    isInternal?: boolean,
    surveyIDs: NumericDropDownModel[],
};

export interface UserAccountSearchResponse extends SearchResponseBase
{
    id: number,
    auth0ID: string,
    userName: string
};

export function CreateUserAccountListRequest(location?: Location):UserAccountSearchRequest{
    if(location && location.state?.componentState){
        return location.state.componentState;
    }
    return {
        userIDs: [],
        isInternal: undefined,
        isInternalText: "Any",
        userRoleIDs: [],
        page: 0,
        pageSize: 100,
        sortColumn: "ID",
        sortDirection: "ASC",
        surveyIDs: []
    }
};

export interface UserDetailModel {
    userID: number,
    auth0ID?: string,
    userName?: string,
    firstName?: string,
    lastName?: string,
    fullName?: string,
    password?: string,
    email: string,
    status?: string,
    isInternalFlag: boolean,
    createdByID: number,
    createdByName: string,
    modifiedByID?: number,
    modifiedByName?: string,
    dateCreated?: Date,
    dateModified?: Date,
    roles: NumericDropDownModel[],
    emailVerified: boolean
}

export const CreateDefaultUserDetailModel = (defaultValue?:UserDetailModel):UserDetailModel => {
    
    return {
        userID: defaultValue?.userID ?? 0,
        auth0ID: defaultValue?.auth0ID ?? '',
        userName: '',
        firstName: '',
        lastName: '',
        fullName: '',
        password: '',
        email: '',
        status: '',
        isInternalFlag: false,
        createdByID: 0,
        createdByName: '',
        modifiedByID: 0,
        modifiedByName: '',
        dateCreated: new Date(),
        dateModified: new Date(),
        roles: [],
        emailVerified: false
    }
}

export interface UserDetailCheckModel {
    userExistsInAuth0: boolean,
    userExistsInHADb: boolean
}