import { GridRowSelectionModel } from "@mui/x-data-grid";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import { useState } from "react";
import Loading from "../../components/loading/Loading.component";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DeleteImports } from "./Imports.service";

export interface MassDeleteImportProps {
    show: boolean,
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void,
    selectedIDs: GridRowSelectionModel
}

const MassDeleteImport = (props: MassDeleteImportProps) => {
    const {appUser} = useApiAuthorization();
    const [saving, setSaving] = useState<boolean>(false);

    const massUpdate = () => {        
        setSaving(true);
        DeleteImports(props.selectedIDs.map(id => id.toString()), appUser?.token).then((request) =>{
            
        }).finally(() => {
            setSaving(false);
            handleClose();
        })
    };

    const handleClose = () => {
        props.onClose();
    };

    return (
        <>
            {saving && <Loading />}
            <Dialog open={props.show} onClose={handleClose}>
                
                <DialogTitle>Delete Imports</DialogTitle>
                <DialogContent>
                    <DialogContentText>{`The ${props.selectedIDs.length} selected imports will be removed.`}</DialogContentText>                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={massUpdate}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default MassDeleteImport;