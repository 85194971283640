import { useEffect, useState } from "react";
import { unstable_usePrompt as usePrompt } from "react-router-dom";

export const useIsDirtyPrompt = (initialModelState: any, currentModelState: any, message?:string) => {
    const [isDirty, setIsDirty] = useState(false);
    usePrompt({when: isDirty, message: message ?? "This form is unsaved, are you sure you want to leave?"});
    useEffect(() => {
        var initialStateString = JSON.stringify(initialModelState);
        var currentStateString = JSON.stringify(currentModelState);
        if(!!initialModelState && initialStateString !== currentStateString){
            setIsDirty(true);
        }else{
            setIsDirty(false);
        }
    }, [initialModelState, currentModelState]);
    return isDirty;
}