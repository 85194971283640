import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useCallback } from 'react';
import { EmailTemplateDetailModel } from './EmailTemplate.models';
import CloseIcon from '@mui/icons-material/Close';

interface EmailTemplateDialogProps{
    open: boolean,
    onClose: () => void,
    selectedEmailTemplate: EmailTemplateDetailModel,
    isLoading: boolean,
}

const EmailTemplateDialog = (props: EmailTemplateDialogProps) => {
    const handleClose = useCallback(() => {
        props.onClose();
    }, [props]);


    return (
        <Dialog
            open={props.open && !props.isLoading}
            onClose={handleClose}
            maxWidth={'lg'}
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography fontWeight={'bold'}>{ props.selectedEmailTemplate.name }</Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                onClick={(e) => {e.preventDefault()}}
                dangerouslySetInnerHTML={{ __html: props.selectedEmailTemplate.content }}
            />
        </Dialog>
    )
}

export default EmailTemplateDialog;