import moment from "moment";
import { ApiBaseRoute } from "../../../../api_helper";
import { LatestCompletedAssessmentDetailsSearch } from "./LatestPublishedScoresDetails.models";

export const GetLatestPublishedScoresDetailsDataVisualization = (request: LatestCompletedAssessmentDetailsSearch, section:string, token?:string):Promise<Response> => 
    {
        let requestCopy = {
            ...request, 
            countryIDs: request.countryIDs.map(x => x.value),
            regionIDs: request.regionIDs.map(x => x.value),
            modelIDs: request.modelID ? [request.modelID.value] : [],
            completionDateStart: request.completionDateRange[0] ?  moment.utc(request.completionDateRange[0]).startOf("day") : null,
            completionDateEnd: request.completionDateRange[1] ?  moment.utc(request.completionDateRange[1]).startOf("day") : null,
        };
         return fetch(`${ApiBaseRoute}Insights/LatestPublishedScoresDetailsDataVisualization?section=${section}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
                "accept": "application/json"
            },
            body: JSON.stringify(requestCopy)
        });
    };

    export const DownLoadPublishedScoresDetailsExport =  (request: LatestCompletedAssessmentDetailsSearch, token: string|undefined):Promise<Response> => {
        let requestCopy = {
            ...request, 
            countryIDs: request.countryIDs.map(x => x.value),
            regionIDs: request.regionIDs.map(x => x.value),
            modelIDs: request.modelID ? [request.modelID.value] : [],
            completionDateStart: request.completionDateRange[0] ?  moment.utc(request.completionDateRange[0]).startOf("day") : null,
            completionDateEnd: request.completionDateRange[1] ?  moment.utc(request.completionDateRange[1]).startOf("day") : null,
        };
        return fetch(ApiBaseRoute + `Insights/LatestPublishedScoresDetailsDataExport`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
                "accept": "application/octet-stream"
            },
            body: JSON.stringify(requestCopy)
        });
    };