import { ApiBaseRoute } from "../../../../api_helper";
import { AchievementListSearchRequest } from "../AchiementList/AchievementList.models";

export const GetPublishedScoresDataVizData = (request: AchievementListSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {
        ...request, 
        maturityModelIDs: request.maturityModel.map(x => x.value),
        stages: request.stages,
        countryIDs: request.countries.map(x => x.value),
        geographicRegionIDs: request.geographicRegions.map(x => x.value),
        startDate: request.publishedDate?.[0],
        endDate: request.publishedDate?.[1]
    };
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "Insights/PublishedScoresDataVisualization", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(requestCopy)
    });
};

export const DownloadPublishedScoresExport = (request: AchievementListSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {
        ...request, 
        maturityModelIDs: request.maturityModel.map(x => x.value),
        stages: request.stages,
        countryIDs: request.countries.map(x => x.value),
        geographicRegionIDs: request.geographicRegions.map(x => x.value),
        startDate: request.publishedDate?.[0],
        endDate: request.publishedDate?.[1]
    };
    return fetch(ApiBaseRoute + `Insights/DownloadPublishedScoresExport`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/octet-stream"
        },
        body: JSON.stringify(requestCopy)
    });
};