import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { ApiAuthorizationProvider } from './components/authorization/ApiAuthorizationProvider.component';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const domain:string = process.env.REACT_APP_AUTH0_DOMAIN ?? "";
const clientId:string = process.env.REACT_APP_AUTH0_CLIENTID ?? "";
const audience:string = process.env.REACT_APP_AUTH0_AUDIENCE ?? "";
const redirect: string = process.env.REACT_APP_AUTH0_REDIRECT ?? "http://localhost:3000";

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirect,
        audience: audience,
        scope: "read:current_user"
      }}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <ApiAuthorizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <App />
        </LocalizationProvider>
      </ApiAuthorizationProvider>
    </Auth0Provider>
  </React.StrictMode>
);
