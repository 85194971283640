import NumericDropDownModel from "../../models/NumericDropDown.model";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";
import { SearchResponseBase } from "../../models/SearchResponseBase.model";
import { Location } from "react-router-dom";


export interface UserRoleSearchResponse extends SearchResponseBase {
    userRoleID: number,
    userRole: string,
    userRoleDescription: string
};

export interface UserRoleSearchRequest extends SearchRequestBase {
    userRoleIDs: NumericDropDownModel[]
};

export interface UserRolePermission {
    id: number,
    moduleName: string,
    moduleType: string,
    view?: boolean,
    edit?: boolean,
    delete?: boolean,
    export?: boolean
}

export const CreateUserRoleListRequest = (location: Location, defaultValue?: UserRoleSearchRequest):UserRoleSearchRequest => {
    if(location && location.state?.componentState){
        return location.state.componentState;
    }
    return {
        page: 0,
        pageSize: 100,
        sortColumn: "UserRole",
        sortDirection: "ASC",
        userRoleIDs: []
    }
};

export interface UserRoleDetailModel {
    userRoleID: number,
    userRole: string,
    userRoleDescription: string,
    permissions: NumericDropDownModel[],
    userRolePermissions: UserRolePermission[]
}

export const CreateUserRoleDetailModel = (defaultValue?: UserRoleDetailModel):UserRoleDetailModel => {
    return {
        userRoleID: 0,
        userRole: "",
        userRoleDescription: "",
        permissions: [],
        userRolePermissions: []
    };
};