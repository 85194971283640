import { useCallback, useRef, useState } from "react";
import BaseLayout from "../../../../layout/BaseLayout.component";
import { useApiAuthorization } from "../../../../components/authorization/ApiAuthorizationProvider.component";
import RightMenu from "../../../../layout/RightMenu.component";
import { Box, Divider, FormControlLabel, Grid, List, ListItem, ListItemButton, Switch, Tab, Tabs, Typography } from "@mui/material";
import { userHasPermission } from "../../../../services/User.service";
import TypeAheadMulti from "../../../../components/formControls/TypeAheadMultiBase.component";
import NumericDropDownModel from "../../../../models/NumericDropDown.model";
import { GetMaturityModelSearch } from "../../../../services/MaturityModel.service";
import { GetRegionTypeAheadSearch } from "../../../../services/Region.service";
import { GetCountryTypeAheadSearch } from "../../../../services/Country.service";
import TabPanel, { a11yProps } from "../../../../components/tabs/TabPanel.component";
import StageAchievedDistribution from "./StageAchievedDistribution.component";
import AverageFocusAreaStageAchievement from "./AverageFocusAreaStageAchievement.component";
import FocusAreaStageAchievementByFacility from "./FocusAreaStageAchievementByFacility.component";
import StagePercentAchievedByFocusArea from "./StagePercentAchievedByFocusArea.component";
import AverageStagePercentAchievement from "./AverageStagePercentAchievement.component";
import TypeAhead from "../../../../components/formControls/TypeAheadBase.component";
import Loading from "../../../../components/loading/Loading.component";
import { toPng } from "html-to-image";
import { DownLoadPublishedScoresDetailsExport } from "./LatestPublishedScoresDetails.service";
import moment from "moment";
import { CreateDefaultLatestCompletedAssessmentDetailsSearch, LatestCompletedAssessmentDetailsSearch } from "./LatestPublishedScoresDetails.models";
import { DateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";

const LatestPublishedScoresDetails = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [queryOptions, setQueryOptions] = useState<LatestCompletedAssessmentDetailsSearch>(CreateDefaultLatestCompletedAssessmentDetailsSearch());
    const {appUser} = useApiAuthorization();
    const visualizationRef = useRef<HTMLDivElement>(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [showAxisCounts, setShowAxisCounts] = useState<boolean>(false);

        
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        // event.type can be equal to focus with selectionFollowsFocus.
        setCurrentTab(newValue);
    };

    const downloadVisualization = useCallback(() => {
        if (visualizationRef.current === null) {
            return
        }

        toPng(visualizationRef.current, { cacheBust: true, style: {
            padding: '15px',
            backgroundColor: '#fff'
        }}).then((dataUrl) => {
            const link = document.createElement('a');
            let tabLabel = "";
            switch (currentTab) {
                case 4:
                    tabLabel = 'StagePercentAchievedByFocusArea';
                    break;
                case 3: 
                    tabLabel = 'FocusAreaStageAchievementByFacility';
                    break;
                case 2:
                    tabLabel = 'AverageFocusAreaStageAchievement';
                    break;
                case 1:
                    tabLabel = 'StageAssessedDistribution';
                    break;
                case 0:            
                default:
                    tabLabel = 'AverageStagePercentAchievement';
                    break;
            };

            link.download = `${queryOptions.modelID ? queryOptions.modelID.label : 'All Models'}_${tabLabel}_${moment().format("MM-DD-YYYY")}.png`;
            link.href = dataUrl;
            link.click();
        }).catch((err) => {
            console.log(err)
        });
    }, [visualizationRef, currentTab, queryOptions.modelID]);

    const downloadExport = useCallback(() => {
        setIsLoading(true);
        DownLoadPublishedScoresDetailsExport(queryOptions, appUser?.token).then(response => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response.blob();
        }).then(blob => {
            if(blob != null)
            {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "LatestCompletedAssessmentDetailExport.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        }).finally(() => setIsLoading(false));
    }, [appUser?.token, queryOptions]);

    const childMenu = (
        <RightMenu>
            <List component="nav">
                <ListItem>
                    <Typography variant="h6">Action Items</Typography>
                </ListItem>
                <ListItemButton disabled={isLoading} onClick={() => setQueryOptions(CreateDefaultLatestCompletedAssessmentDetailsSearch())} key="reset">
                    Reset Filters
                </ListItemButton>
                {userHasPermission("InsightsLatestPublishedScoresDetail_Detail_Export", appUser) && 
                <ListItemButton onClick={downloadExport} disabled={isLoading}>
                    Download Export
                </ListItemButton>
                }
                <ListItemButton onClick={downloadVisualization} disabled={isLoading}>
                    Download Visualization
                </ListItemButton>
                <Divider/>
                <ListItem>
                    <Typography variant="h6">Settings</Typography>
                </ListItem>
                <ListItem>
                    <FormControlLabel 
                        control={
                            <Switch
                                disabled={isLoading}
                                checked={showAxisCounts === true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setShowAxisCounts(checked)}
                            />
                        }
                        label="Display Axis Counts"
                    />
                </ListItem>
                <Divider/>
                <ListItem>
                    <Typography variant="h6">Filters</Typography>
                </ListItem>
                <ListItem>
                    <TypeAhead
                        value={queryOptions.modelID}
                        disabled={isLoading}
                        onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel|null) => setQueryOptions((prevState) => { return {...prevState, modelID: newValue} })}
                        label="Maturity Model"
                        searchCallback={(searchTerm?:string) => GetMaturityModelSearch(searchTerm, [6,7,8,9,10,11,13])}
                        disableClear={true}
                    />
                </ListItem>
                <ListItem>
                    <TypeAheadMulti
                        value={queryOptions.regionIDs}
                        disabled={isLoading}
                        label="Regions"
                        onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => setQueryOptions((prevState) => { return {...prevState, regionIDs: newValue} })}
                        searchCallback={(searchTerm?:string) => GetRegionTypeAheadSearch(appUser?.token, searchTerm, [2,4,5,7,1001,1004])}
                    />
                </ListItem>
                <ListItem>
                    <TypeAheadMulti
                        label="Countries"
                        disabled={isLoading}
                        value={queryOptions.countryIDs}
                        onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => {
                            setQueryOptions((prevState) => { return {...prevState, countryIDs: newValue} })}
                        }
                        searchCallback={(searchTerm?: string) => GetCountryTypeAheadSearch('', searchTerm, queryOptions.regionIDs)}
                    />
                </ListItem>
                <ListItem>
                <DateRangePicker
                        onAccept={(value: [moment.Moment|null, moment.Moment|null]) => setQueryOptions({...queryOptions, completionDateRange: value})}
                        value={queryOptions.completionDateRange}
                        label="Assessment Completion Date"
                        slots={{ field: SingleInputDateRangeField }}
                        slotProps={{textField: {variant: 'standard', fullWidth: true}}}
                        disabled={isLoading}
                        timezone="UTC"
                        minDate={moment().add(-3, "years").add(-3, "months")}
                    />
                </ListItem>
            </List>
        </RightMenu>
    );


    return (
        <BaseLayout childMenu={childMenu} hideHeader={false}>
            {isLoading && <Loading/>}
            <Grid container spacing={3} sx={{pb:10,backgroundColor: "#ffffff", margin:0, width:"100%"}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width:"100%" }}>
                    <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label='Average Stage Percent Achievement' {...a11yProps(0)} />
                        <Tab label='Stage Assessed Distribution' {...a11yProps(1)} />
                        <Tab disabled={!queryOptions.modelID} label='Average Focus Area Stage Achievement' {...a11yProps(2)} />
                        <Tab disabled={!queryOptions.modelID} label='Focus Area Stage Achievement' {...a11yProps(3)} />
                        <Tab disabled={!queryOptions.modelID} label='Stage Percent Achieved By Focus Area' {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <Grid item xs={12} ref={visualizationRef}>
                    <TabPanel value={currentTab} index={0}>
                        <AverageStagePercentAchievement 
                            queryOptions={queryOptions} 
                            onLoad={(isLoading: boolean) => setIsLoading(isLoading)}
                            axisLable={(item) => {
                                return showAxisCounts ? item.value?.toFixed(2).toLocaleString() : undefined;
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <StageAchievedDistribution 
                            queryOptions={queryOptions} 
                            onLoad={(isLoading: boolean) => setIsLoading(isLoading)}
                            axisLable={(item) => {
                                return showAxisCounts ? item.value?.toLocaleString() : undefined;
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={currentTab} index={2}>
                        <AverageFocusAreaStageAchievement 
                            queryOptions={queryOptions} 
                            onLoad={(isLoading: boolean) => setIsLoading(isLoading)}
                            axisLable={(item) => {
                                return showAxisCounts ? item.value?.toFixed(2).toLocaleString() : undefined;
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={currentTab} index={3}>
                        <FocusAreaStageAchievementByFacility 
                            queryOptions={queryOptions} 
                            onLoad={(isLoading: boolean) => setIsLoading(isLoading)}
                            axisLable={(item) => {
                                return showAxisCounts ? item.value?.toFixed(0).toLocaleString() : undefined;
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={currentTab} index={4}>
                        <StagePercentAchievedByFocusArea 
                            queryOptions={queryOptions} 
                            onLoad={(isLoading: boolean) => setIsLoading(isLoading)}
                            axisLable={(item) => {
                                return showAxisCounts ? item.value?.toFixed(0).toLocaleString() : undefined;
                            }}
                        />
                    </TabPanel>
                    <Typography variant="caption" sx={{fontSize: 10}}>
                    *The counts shown represent the results of baseline assessments completed within the selected date range. These results have not necessarily been validated at this time. For organizations with multiple participations, only the most recent baseline assessment results are considered, excluding any previous participations.
                    </Typography>
                    <br/>
                    <Typography variant="caption" sx={{fontSize: 10}}>
                    ©{moment().year()} HIMSS. All rights reserved. This document and any of the information contained herein may not be reproduced or reprinted under any circumstance without the expressed written consent of HIMSS.
                    </Typography>
                </Grid>
            </Grid>
        </BaseLayout>
    );
};

export default LatestPublishedScoresDetails;