import { ApiBaseRoute } from "../../api_helper";
import { EmailTemplateSearchRequest } from "./EmailTemplate.models";
import { Stack, Tooltip, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

export const GetEmailTemplateListColumns = (callback:(emailTemplateID: number) => void):GridColDef[] => {
    return [
        {
            field: 'actions',
            headerName: '',
            flex: 0,
            filterable: false,
            sortable: false,
            align: 'center',
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Stack
                        direction='row'
                        spacing={1}
                    >
                        <Tooltip
                            title='Quick View'
                            placement='top'
                        >
                            <IconButton
                                aria-label='View Template'
                                color='primary'
                                size='small'
                                onClick={ (e) =>
                                    {callback(params.row.id);
                                    e.stopPropagation();}
                                }
                            >
                                <VisibilityIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            }
        },
        { field: 'name', headerName: 'Name', flex: 1, filterable: false },
        { field: 'surveyTypes', headerName: 'Assessment Types', flex: 1, filterable: false },
        { field: 'language', headerName: 'Language', flex: .5, filterable: false },
        { field: 'type', headerName: 'Type', flex: .5, filterable: false }
    ]
};

export const GetEmailTemplates = ():Promise<Response> => {
    return fetch(ApiBaseRoute + "EmailTemplates", {});
};

export const SearchEmailTemplates = (request: EmailTemplateSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {...request};
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "EmailTemplates/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            ...requestCopy,
            environment: process.env.REACT_APP_HOST_ENV,
            emailTemplateIDs: requestCopy.emailTemplateIDs.map(x => x.value),
            surveyTypeIDs: requestCopy.surveyTypeIDs.map(x => x.value),
            languageIDs: requestCopy.languageIDs.map(x => x.value),
            types: requestCopy.types,
        })
    });
};