import { Location } from "react-router-dom";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";
import { SearchResponseBase } from "../../models/SearchResponseBase.model";
import { User } from "../../models/User.model";

export interface CaseSearchRequest extends SearchRequestBase {
    caseIDs: NumericDropDownModel[],
    assignedToIDs: NumericDropDownModel[],
    status?: string,
    facilityIDs: NumericDropDownModel[],
    surveyIDs: NumericDropDownModel[]
}

export interface CaseMassUpdateModel {
    status: string,
    assignedToUser: NumericDropDownModel|null,
    caseIDs: number[]
};

export function CreateDefaultMassUpdateModel() {
    return {status: '', assignedToUser: null, caseIDs: []};
}

export interface CaseSearchResponse extends SearchResponseBase {
    id: number,
    caseID: number,
    surveyID?: number,
    uniquID?: number,
    orgName: string,
    assignedTo: string,
    errorCode: string,
    caseName: string,
    assignedToID?: number,
    description: string,
    status: string,
    dateCreated?: Date,
    dateModified?: Date
}

export interface CaseDetailModel {
    caseID: number,
    surveyID?: number,
    facility: NumericDropDownModel|null,
    assignedTo: NumericDropDownModel|null,
    assignedToID: number|undefined,
    errorCode: string,
    caseName: string,
    severity: string,
    qaReportCategory: string,
    qaReport: string,
    description: string,
    raComment: string,
    qaComment: string,
    status: string,
    dateCreated?: Date,
    dateModified?: Date
}

export function CreateDefaultCaseDetail(defaultValue?:CaseDetailModel):CaseDetailModel{
    return {
        caseID: 0,
        surveyID: undefined,
        facility: null,
        assignedTo: null,
        assignedToID: undefined,
        errorCode: '',
        caseName: '',
        severity: '',
        qaReportCategory: '',
        qaReport: '',
        description: '',
        raComment: '',
        qaComment: '',
        status: '',
        dateCreated: undefined,
        dateModified: undefined
    }
}

export function CreateCaseSearchRequest(presetParams: URLSearchParams|undefined, appUser: User|undefined, location: Location|undefined):CaseSearchRequest{
    if(location && location.state?.componentState){
        return location.state.componentState;
    }
    let facilityId = presetParams?.get("facilityID");
    let facilityLabel = presetParams?.get("facilityName");
    let hasFacility = !!facilityId && !!facilityLabel

    return {
        caseIDs: [],
        surveyIDs: [],
        facilityIDs: hasFacility ? [{label: facilityLabel!, value: parseInt(facilityId!)}] : [],
        assignedToIDs: hasFacility ? [] : appUser?.isInternalFlag ? [{label: appUser!.email, value: appUser!.userID}] : [],
        page: 0,
        pageSize: 100,
        sortColumn: "ID",
        sortDirection: "DESC",
        status: hasFacility ? "Any" : "New"
    };
}

