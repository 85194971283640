import { useCallback, useEffect, useMemo, useState } from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import { GridColDef, GridPaginationModel, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { GetInsightsListColumns, SearchInsights } from "./InsightsList.service";
import { useLocation, useNavigate } from "react-router-dom";
import { forEach } from "lodash";
import BaseLayout from "../../layout/BaseLayout.component";
import { Grid } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { AchievementListSearchRequest, CreateDefaultAchievementListSearchRequest } from "./DataVisualizations/AchiementList/AchievementList.models";
import InsightsAssessDialog from "./InsightsAccessDialog.component";

interface InsightsListProps {

}
 
function InsightsList(props: InsightsListProps){
    const [loading, setLoading] = useState<boolean>(false);
    const {appUser} = useApiAuthorization();
    const columns: GridColDef[] = useMemo(() => GetInsightsListColumns(), []);
    const navigate = useNavigate();
    const location = useLocation();
    const [queryOptions, setQueryOptions] = useState<AchievementListSearchRequest>(CreateDefaultAchievementListSearchRequest(location));
    const [rowData, setRowData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [showAccessDialog, setShowAccessDialog] = useState<boolean>(false);
    const [selectedRowName, setSelectedRowName] = useState<string>();

    useEffect(() => {
        setLoading(true);
        SearchInsights(queryOptions, appUser?.token)
        .then((response) => response.json())
        .then((data) => {
            setRowData(data);
            if(data && data.length > 0){
                setRowCount(data[0].totalCount);
            } else {
                setRowCount(0);
            }
        }).finally(() => setLoading(false));
    }, [queryOptions, appUser?.token]);

    const onPageChange = useCallback((newPage: number) => {
        setQueryOptions({...queryOptions, page: newPage});
    }, [queryOptions]);

    const onSortModelChange = useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions({...queryOptions, sortColumn: sortParam.field, sortDirection: sortParam.sort});
        });
    }, [queryOptions]);   

    return (
        <BaseLayout>
            <Grid sx={{display: "flex", height: "100%"}}>
                <DataGridPro
                    columns={columns}
                    rows={rowData}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    paginationMode="server"
                    pageSizeOptions={[100]}
                    loading={loading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    onRowClick={(params: GridRowParams) => {
                        if(params.row.enabled){
                            navigate(`${params.row.path}`);
                        } else {
                            setSelectedRowName(params.row.insightName)
                            setShowAccessDialog(true);
                        }
                    }}
                    getRowId={(row) => row.insightID}
                    slots={{
                        columnHeaders: () => null,
                    }}
                    getRowHeight={() => 'auto'}
                />
                <InsightsAssessDialog
                    open={showAccessDialog}
                    onClose={() => setShowAccessDialog(false)}
                    dashboard={selectedRowName ?? ''}
                    title="Access Denied"
                />
            </Grid>
        </BaseLayout>
    );
}

export default InsightsList;