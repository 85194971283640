import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import React, { useCallback } from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import Loading from "../../components/loading/Loading.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { CreateDefaultAssessmentResendEmailModel, AssessmentResendEmailModel } from "./Assessment.models";
import { ResendNotifications } from "./Assessment.service";

interface ResendEmailDialogProps {
    surveyID: number,
    open: boolean,
    onClose: () => void,
    potentialRecipients: string[],
    assignedUser: NumericDropDownModel|null,
    enableCompletionEmailOptions: boolean
}

const ResendEmailDialog = (props: ResendEmailDialogProps) => {
    const [resendEmailModel, setResendEmailModel] = React.useState<AssessmentResendEmailModel>(CreateDefaultAssessmentResendEmailModel(props.surveyID));
    const [processing, setProcessing] = React.useState<boolean>(false);
    const {appUser} = useApiAuthorization();
    const options = props.assignedUser ? [...props.potentialRecipients, props.assignedUser.label].filter((value, index, array) => array.indexOf(value) === index) : props.potentialRecipients;

    React.useEffect(() => {
        if(props.surveyID !== 0 && props.surveyID !== resendEmailModel.surveyID){
            setResendEmailModel(CreateDefaultAssessmentResendEmailModel(props.surveyID));
        }
    }, [props.surveyID, resendEmailModel.surveyID])

    const handleClose = useCallback(() => {
        setResendEmailModel(CreateDefaultAssessmentResendEmailModel(props.surveyID));
        props.onClose();
    }, [props]);

    const handleConfirm = useCallback(() => {
        setProcessing(true);
        ResendNotifications(resendEmailModel, appUser?.token).then(response => {
            if(response.status === 200){
                setProcessing(false);
                handleClose();
            }
        }).catch(error => {
            //show error here
        }).finally(() => {
            setProcessing(false);
        });
    }, [appUser?.token, handleClose, resendEmailModel]);

    return (
        <>
            {processing && <Loading/>}
            {!processing && 
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>Resend Email Notifications</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please select the email template to use:</DialogContentText>
                    <Grid item xs={12}>
                        <FormControlLabel 
                            label="Resend Welcome Email"
                            control={
                                <Switch 
                                    checked={resendEmailModel.resendWelcomeEmail} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setResendEmailModel({...resendEmailModel, resendWelcomeEmail: checked})}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Send Reminder Email"
                            control={
                                <Switch 
                                    checked={resendEmailModel.sendReminderEmail} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setResendEmailModel({...resendEmailModel, sendReminderEmail: checked})}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Resend Completion Email"
                            disabled={!props.enableCompletionEmailOptions}
                            control={
                                <Switch 
                                    checked={resendEmailModel.resendCompletionEmail} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setResendEmailModel({...resendEmailModel, resendCompletionEmail: checked})}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Send Notifications as Plain Text?"
                            control={
                                <Switch 
                                    checked={resendEmailModel.usePlainText} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setResendEmailModel({...resendEmailModel, usePlainText: checked})}
                                />
                            }
                        />
                    </Grid>
                    <Autocomplete
                        multiple
                        value={resendEmailModel.recipients}
                        options={options}
                        renderInput={
                            (params) => 
                            <TextField 
                                required 
                                {...params} 
                                label="Recipients To Notify" 
                                variant="standard"
                                error={resendEmailModel.recipients.length === 0}
                                helperText={resendEmailModel.recipients.length === 0 ? "At least one recipient is required." : undefined}
                            />
                        }
                        onChange={(event: React.SyntheticEvent<Element, Event>, value: string[]) => setResendEmailModel({...resendEmailModel, recipients: value})}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleConfirm} disabled={resendEmailModel.recipients.length === 0 || (!resendEmailModel.resendWelcomeEmail && !resendEmailModel.sendReminderEmail && !resendEmailModel.resendCompletionEmail)}>Ok</Button>
                </DialogActions>
            </Dialog>
            }
        </>
    );
};

export default ResendEmailDialog;