import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { ApiBaseRoute } from "../../api_helper";
import { User } from "../../models/User.model";
import { CaseMassUpdateModel, CaseSearchRequest } from "./Case.models";

export const GetCaseListColumns = (appUser: User):GridColDef[] => {
    return [
        {field: "id", headerName: "Case ID", flex: .3, filterable: false},
        {field: "caseName", headerName: "Case Name", filterable: false, flex: 1},
        {field: "errorCode", headerName: "Error Code", filterable: false},
        {field: "assignedTo", headerName: "Assigned To", flex: .4, filterable: false},
        {field: "surveyID", headerName: "Assessment ID", flex: .3, filterable: false},
        {field: "orgName", headerName: "Facility", flex: .6, filterable: false},
        {field: "description", headerName: "Description", filterable: false, flex: .7},
        {field: "status", headerName: "Status", filterable: false, flex: .2},
        {
            field: "dateModified",
            headerName: "Date Modified",
            filterable: false,
            flex: .3,
            align: "center",
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        }
    ]
};

export const SearchCases = (request: CaseSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {...request};
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "Cases/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            ...requestCopy,
            caseIDs: requestCopy.caseIDs.map(x => x.value),
            assignedToIDs: requestCopy.assignedToIDs.map(x => x.value),
            facilityIDs: requestCopy.facilityIDs.map(x => x.value),
            surveyIDs: requestCopy.surveyIDs.map(x => x.value),
            status: requestCopy.status === 'Any' ? null : requestCopy.status
        })
    });
};

export const MassUpdateCases = (request: CaseMassUpdateModel, token: string|undefined):Promise<Response> => {

    return fetch(ApiBaseRoute + "Cases/MassUpdate", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(request)
    });
};
