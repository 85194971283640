import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Input } from "@mui/material";
import { useCallback, useState } from "react";
import Loading from "../../components/loading/Loading.component";
import { UploadQuestionsAndAnswers } from "./AssessmentType.service";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";

interface QuestionAnswerImportDialogProps {
    open: boolean,
    onClose(): void
};

const QuestionAnswerImportDialog = (props: QuestionAnswerImportDialogProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [fileUpload, setFileUpload] = useState<File|null>(null);
    const [error, setError] = useState<boolean>(false);
    const {appUser} = useApiAuthorization();

    const handleClose = useCallback(() => {
        setLoading(false);
        setFileUpload(null);
        setError(false);
        props.onClose();
    },[props]);

    const handleImport = useCallback(() => {
        setLoading(true);
        UploadQuestionsAndAnswers(fileUpload!, appUser?.token)
        .then(response => {
            if(response.status === 200){
                handleClose();
            }
        })
        .catch(error => {
            setError(true);
        })
        .finally(() => {
            setLoading(false);
        });
    }, [appUser?.token, fileUpload, handleClose]);

    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = event.currentTarget.files && event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
        setFileUpload(newValue);
    }, []);



    return (
        <>
            {loading && <Loading/>}
            {!loading && 
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>Question and Answer Import</DialogTitle>
                <DialogContent>
                    {error && 
                    <DialogContentText>
                        <Alert severity="error">Oops!! There was an error importing your file upload. Please contact a system administrator for assistance.</Alert>
                    </DialogContentText>
                    }
                    {!error && 
                    <>
                    <DialogContentText>Using the excel file downloaded for questions and answers as a template, you can modify or add translations and upload them here.</DialogContentText>
                    <Grid item xs={12}>
                        <Input sx={{width: '100%'}} type="file" onChange={handleFileChange}/>
                    </Grid>
                    </>
                    }
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleImport} disabled={!fileUpload}>Import</Button>
                </DialogActions>
            </Dialog>
            }
        </>
    );
};

export default QuestionAnswerImportDialog;