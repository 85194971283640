import { GridColDef } from "@mui/x-data-grid";
import { ApiBaseRoute } from "../../api_helper";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";
import { Divider, Grid, Typography } from "@mui/material";

export const GetInsightsListColumns = ():GridColDef[] => {
    return [
        {field: "singleColumnLayout", flex:1, filterable:false, renderCell: (params) => {
            return (
                <Grid container sx={{p: 1}}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{params.row.insightName}</Typography>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} sm={params.row.mediaLink ? 8 : 12} sx={{margin: 'auto'}}>
                        <Typography variant="body1">{params.row.description}</Typography>
                    </Grid>
                    {params.row.mediaLink &&
                    <Grid item xs={12} sm={4} alignItems={"end"} sx={{p: 3}}>
                        <img style={{display: 'block', margin: 'auto', height: 150}} src={params.row.mediaLink} alt=''/>
                    </Grid>
                    }
                </Grid>
            )
        }}
    ];
}

export const SearchInsights = (request: SearchRequestBase, token?:string):Promise<Response> => {
    let requestCopy = {
        ...request, 
        page: request.page + 1
    };
    return fetch(ApiBaseRoute + "Insights/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(requestCopy)
    });
};