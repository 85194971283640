import { Grid, Typography } from "@mui/material";
import { AverageFocusAreaStageAchievementModel, CreateDefaultAverageFocusAreaStageAchievementModel, LatestPublishedScoresDetailsProps } from "./LatestPublishedScoresDetails.models";
import { useEffect, useRef, useState } from "react";
import { BarChart } from "@mui/x-charts";
import { useApiAuthorization } from "../../../../components/authorization/ApiAuthorizationProvider.component";
import { GetLatestPublishedScoresDetailsDataVisualization } from "./LatestPublishedScoresDetails.service";
import { GlobalColors } from "../../../../constants/GlobalColors.const";

const AverageFocusAreaStageAchievement = (props: LatestPublishedScoresDetailsProps) => {
    const [dataVizData, setDataVizData] = useState<AverageFocusAreaStageAchievementModel>(CreateDefaultAverageFocusAreaStageAchievementModel());
    const { onLoad, queryOptions } = props;
    const { appUser } = useApiAuthorization();     
    const handleOnLoad = useRef(onLoad);

    useEffect(() => {
        handleOnLoad.current(true);
        GetLatestPublishedScoresDetailsDataVisualization(queryOptions, "3", appUser?.token)
            .then(response => response.json())
            .then(data => {
                setDataVizData(data);
            })
            .finally(() => handleOnLoad.current(false));

    }, [queryOptions, handleOnLoad, appUser?.token]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle1" textAlign="center">
                    Average Focus Area Stage Achievement
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <BarChart
                    sx={{
                        '& .MuiBarLabel-root': {
                            fill: '#fff'
                        }
                    }}
                    slotProps={{
                        legend: {
                            direction: "column",
                            position: {
                                vertical:'middle',
                                horizontal: 'right',
                            },
                        }
                    }}
                    dataset={dataVizData.focusAreaAchievements}
                    height={500}
                    xAxis={[
                        {
                            scaleType: "band", 
                            dataKey: "focusArea", 
                            valueFormatter: (focusArea, context) => {
                                return context.location === 'tick' ?
                                `${focusArea.split(' ').join('\n')}` :
                                `${focusArea}`;
                            }
                        }
                    ]}                    
                    margin={{ top: 5, bottom: 180}}
                    series={[{dataKey: "stageAchieved"}]}
                    barLabel={props.axisLable}
                    colors={GlobalColors}
                />
            </Grid>
        </Grid>
    );
};

export default AverageFocusAreaStageAchievement;