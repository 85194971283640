import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useCallback } from "react";

export interface InsightsAssessDialogProps {
    open: boolean,
    onClose: () => void,
    title: string,
    dashboard: string
}

const InsightsAssessDialog = (props: InsightsAssessDialogProps) => {
    const {onClose} = props;
    const handleOnClose = useCallback(() => {
        onClose()
    }, [onClose]);

    return (
        <Dialog open={props.open} onClose={handleOnClose}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        We're sorry, but it seems you don't have access to the {props.dashboard} module. Please reach out to our customer support team for assistance. Contact us at <a href='mailto:customerservice@himss.org'>customerservice@himss.org</a>.
                    </DialogContentText>                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Ok</Button>
                </DialogActions>
            </Dialog>
    )
}

export default InsightsAssessDialog;