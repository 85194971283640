import NumericDropDownModel from "../../models/NumericDropDown.model";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";
import { SearchResponseBase } from "../../models/SearchResponseBase.model";
import { Location } from "react-router-dom";

export interface ValidationSearchRequest extends SearchRequestBase
{
    parentAccountUniqueIDs: NumericDropDownModel[],
    facilityIDs: NumericDropDownModel[],
    modelID: NumericDropDownModel|null,
    status?: string,
    validatedBy: string
};

export interface ValidationSearchResponse extends SearchResponseBase
{
    id: string,
    modelName: string,
    validationID: string,
    validationName: string,
    validationStatus: string,
    validationStage?: number,
    year?: number,
    validationDate?: Date,
    validationExpirationDate?: Date,
    validationBy: string
};

export function CreateValidationSearchRequest(location: Location, presetParams: URLSearchParams):ValidationSearchRequest{
    if(location && location.state?.componentState){
        return location.state.componentState;
    }
    
    let parentUniqueID = presetParams.get("parentAccountUniqueID");
    let parentName = presetParams.get("parentAccountName");
    let name = presetParams.get('facilityName');
    let id = presetParams.get('facilityID');

    return {
        parentAccountUniqueIDs: parentUniqueID && parentName ? [{label: parentName!, value: parseInt(parentUniqueID!)}] : [],
        modelID: null,
        facilityIDs: name && id ? [{label: name!, value: parseInt(id!)}] : [],
        status: 'Any',
        validatedBy: '',
        page: 0,
        pageSize: 100,
        sortColumn: "dateCreated",
        sortDirection: "desc"        
    }
};

export interface ValidationDetailModel {
    achievingStage7: string,
    assignedToID?: number,
    dateCreated?: Date,
    doNotDisplay: boolean,
    facilities: NumericDropDownModel[],
    model: NumericDropDownModel|null,
    validationBy: string,
    validationDate?: moment.Moment|null,
    validationExpirationDate?: moment.Moment|null,
    validationFailureReason: string,
    validationID: string|null,
    validationName: string,
    validationNotes: string,
    validationStage?: number,
    validationStatus: string,
    parentFacility: NumericDropDownModel|null,
    byPassValidationCheck: boolean
};

export function CreateDefaultValidationDetail(defaultValue?:ValidationDetailModel):ValidationDetailModel{
    return {
        achievingStage7: 'No',
        assignedToID: undefined,
        dateCreated: undefined,
        doNotDisplay: false,
        facilities: [],
        model: null,
        validationBy: '',
        validationDate: null,
        validationExpirationDate: null,
        validationFailureReason: '',
        validationID: null,
        validationName: '',
        validationNotes: '',
        validationStage: undefined,
        validationStatus: '',
        parentFacility: null,
        byPassValidationCheck: false
    };
};

export interface ValidationCheckModel {
    modelID: number,
    validationStage: number,
    uniqueIDList: number[],
    validationStatus: string
}

export function isValid(model:ValidationDetailModel):boolean {
    let baseIsValid = !!model.validationName && 
    !!model.validationStage && 
    !!model.parentFacility && 
    (model.facilities && model.facilities.length > 0) &&
    !!model.model &&
    !!model.validationDate;
    
    if(model.validationStatus === "Passed"){
        return baseIsValid && !!model.validationExpirationDate
    }

    if(model.validationStatus === "Failed"){
        return baseIsValid && !!model.validationFailureReason;
    }

    return baseIsValid;
};

export interface ValidationResultModel{
    isValid: boolean,
    entityID: number,
    errorMessage: string,
    errorDescription: string,
    errorCode: string,
}