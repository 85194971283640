import { Divider, Grid, List, ListItem, ListItemButton, TextField, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import Loading from "../../components/loading/Loading.component";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import { DocumentDetailModel, CreateDefaultDocumentDetail, DocumentDetailRequestModel } from "./Document.models";
import { DownloadDocument, GetDocumentDetail } from "./Document.service";
import GoBack from "../../components/rightMenuControls/GoBack.component";
import { userHasPermission } from "../../services/User.service";

const DocumentDetail = () => {
    const {appUser} = useApiAuthorization();
    const {documentID} = useParams();
    const location = useLocation();
    const [documentDetail, setDocumentDetail] = React.useState<DocumentDetailModel>(CreateDefaultDocumentDetail());
    const initialDocumentDetail = React.useRef<DocumentDetailModel|null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if(appUser) {
            setLoading(true);
            
            const documentDetailRequest: DocumentDetailRequestModel = {
                documentID: parseInt(documentID!),
                assessmentID: location.state?.assessmentID ? location.state.assessmentID : null,
                facilityID: location.state?.facilityID ? location.state.facilityID : null,
            }

            GetDocumentDetail(documentDetailRequest, appUser.token)
                .then(response => response.json())
                .then(data => {
                    setDocumentDetail(data);
                    initialDocumentDetail.current = data;
                })
                .finally(() => setLoading(false));
        }
    }, [appUser, documentID, location.state?.assessmentID, location.state?.facilityID]);

    const onDownloadDocument = () => {
        setLoading(true);

        var formData = new FormData();
        
        formData.append('BucketName', 'ha-assessment-content');
        formData.append('FileName', documentDetail.s3Link.replace('https://ha-assessment-content.s3.amazonaws.com/',''));

        DownloadDocument(formData, appUser!.token)
            .then(response => {
                return response.blob();
            })
            .then(blob => {
                const fileName = documentDetail.originalFileName;
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', fileName ? fileName : '');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .finally(() => setLoading(false));
    }

    const childMenuContent = (<RightMenu>
        <List component='nav'>
           <ListItem>
               <Typography variant="h6">Action Items</Typography>
           </ListItem>
           <GoBack/>
           <ListItemButton onClick={() => onDownloadDocument()}>Download Document</ListItemButton>
           <Divider />
           <ListItem>
               <Typography variant="h6">Links</Typography>
           </ListItem>
           {documentDetail.uniqueID && userHasPermission("Account_Detail_View", appUser) &&
               <ListItemButton component={Link} to={`/accounts/${documentDetail.uniqueID}`}>
                   {documentDetail.orgName}
               </ListItemButton>
           }
           <Divider />
           <ListItem>
               <Typography variant="h6">Assessments</Typography>
           </ListItem>
           {userHasPermission("Survey_Detail_View", appUser) && documentDetail.surveys && documentDetail.surveys.map(x => {
               return (
                   <ListItemButton key={x.value} component={Link} to={`/assessments/${x.value}`}>
                       {x.label}
                   </ListItemButton>
               )
           })}
        </List>
    </RightMenu>);

    return (
        <BaseLayout childMenu={childMenuContent}>
            {loading && <Loading />}
            
            <Typography variant="h6" gutterBottom>
                Document Information
            </Typography>
            <Grid container spacing={3} sx={{pb: 10}}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="documentID"
                        label="Document ID"
                        fullWidth
                        variant="standard"
                        value={documentDetail.documentID ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="createdDate"
                        label="Date Created"
                        fullWidth
                        variant="standard"
                        value={documentDetail.createdDate ? moment(documentDetail.createdDate).format("MM/DD/YYYY"): ""}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="uniqueID"
                        label="Account ID"
                        fullWidth
                        variant="standard"
                        value={documentDetail.uniqueID ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="orgName"
                        label="Account Name"
                        fullWidth
                        variant="standard"
                        value={documentDetail.orgName ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="assessmentType"
                        label="Assessment Type"
                        fullWidth
                        variant="standard"
                        value={documentDetail.surveyType ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="createdBy"
                        label="Created By"
                        fullWidth
                        variant="standard"
                        value={documentDetail.createdByFullName ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="fileName"
                        label="File Name"
                        fullWidth
                        variant="standard"
                        value={documentDetail.originalFileName ?? ""}
                        disabled
                    />
                </Grid>
            </Grid>
        </BaseLayout>
    );
};

export default DocumentDetail;