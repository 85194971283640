import { ApiBaseRoute } from "../api_helper";

export const GetLanguageOptions = (surveyTypeID: number|null, token: string|undefined):Promise<Response> => {
    return fetch(`${ApiBaseRoute}languages/DropDownOptions?surveyTypeID=${surveyTypeID??''}`, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};
