import { Fragment, useEffect, useState } from "react";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

export interface TypeAheadProps{
    searchCallback(searchTerm?: string):Promise<Response>,
    onChange(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel|null):void,
    value: NumericDropDownModel|null,
    disabled?: boolean,
    label: string,
    required?: boolean,
    error?: boolean,
    helperText?: string,
    disableClear?: boolean
}

const TypeAhead = (props: TypeAheadProps) => {
    // const {appUser} = useApiAuthorization();
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string|undefined>();
    const [options, setOptions] = useState<NumericDropDownModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);


    useEffect(() => {
        let active = true;

        if (active && open) {
            setLoading(true);
            props.searchCallback(searchTerm)
            .then((response) => response.json())
            .then((data) => {
                if(props.value){
                    //ensure we have a localized option of the value already selected
                    data = [props.value, ...data.filter((option:NumericDropDownModel) => option.value !== props.value?.value)]
                }
                setOptions(data);
            }).finally(() => setLoading(false));
        }

        return () => {
            active = false;
        };
    }, [ searchTerm, props, open]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearchTerm(undefined);
        }
    }, [open]);
    
    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptions([]);
        setSearchTerm(event.target.value);
    };

    const handleOnChange = (event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel|null) => {
        setSearchTerm(undefined);
        props.onChange(event, newValue);
    };
    
    return (
        <Autocomplete
            fullWidth
            disabled={props.disabled}
            disableClearable={props.disableClear}
            onChange={handleOnChange}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={props.value}
            options={options}
            loading={loading}
            filterOptions={(x) => x}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.value}>{option.label}</li>
                );
            }}
            renderInput={(params) => (
                <TextField
                {...params}
                label={props.label}
                variant="standard"
                onChange={onSearchChange}
                required={props.required}
                error={props.error}
                helperText={props.helperText}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </Fragment>
                    ),
                }}
                />
            )}
        />
    );
}

export default TypeAhead;