import { GridColDef } from "@mui/x-data-grid";
import { AchievementListSearchRequest } from "./AchievementList.models";
import { ApiBaseRoute } from "../../../../api_helper";


export const GetAchievementListColumns = ():GridColDef[] => {
    return [
        // { field: "id", headerName: "Validation ID", flex: .4, filterable: false },
        { field: "facilityName", headerName: "Name", flex: 1, filterable: false },
        { field: "healthSystem", headerName: "Health System", flex: 1, filterable: false },
        { field: "country", headerName: "Country", flex: 1, filterable: false},
        { field: "geographicalRegion", headerName: "State", flex: .5, filterable: false},
        { field: "facilityTypeName", headerName: "Facility Type", flex: .5, filterable: false},
        { field: "modelName", headerName: "Model", flex: .6, filterable: false},
        { field: "score", headerName: "Score", flex: .6, filterable: false}
    ]
};

export const SearchAchievementList = (request: AchievementListSearchRequest):Promise<Response> => {
    let requestCopy = {
        ...request, 
        maturityModelIDs: request.maturityModel ? request.maturityModel.map(x => x.value) : null,
        stages: request.stages,
        countryIDs: request.countries.map(x => x.value),
        geographicRegionIDs: request.geographicRegions.map(x => x.value)
    };
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "Insights/AchievementListSearch", {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(requestCopy)
    });
};