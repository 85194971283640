import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React from "react";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { GetValidationModels } from "../../pages/Validations/Validation.service";
import { useApiAuthorization } from "../authorization/ApiAuthorizationProvider.component";

interface ValidationModelDropDownProps {
    onChange(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel|null):void,
    value: NumericDropDownModel|null,
    disabled?: boolean,
    label?: string,
    required?: boolean,
    error?: boolean,
    helperText?: string
};

const ValidationModelDropDown = (props:ValidationModelDropDownProps) => {
    const {appUser} = useApiAuthorization();
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<NumericDropDownModel[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        let active = true;

        if (active && open) {
            setLoading(true)
            GetValidationModels(appUser?.token!)
            .then((response) => response.json())
            .then((data) => {
                if(props.value){
                    //ensure we have a localized option of the value already selected
                    data = [props.value, ...data.filter((option:NumericDropDownModel) => option.value !== props.value?.value)]
                }
                setOptions(data);
            }).finally(() => setLoading(false));
        }

        return () => {
        active = false;
        };
    }, [appUser?.token, props.value, open]);
    
    return (
        <Autocomplete
            fullWidth
            disabled={props.disabled}
            onChange={props.onChange}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={props.value ?? null}
            getOptionLabel={(option) => option.label}
            options={options}
            loading={loading}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.value}>{option.label}</li>
                );
            }}
            renderInput={(params) => (
                <TextField
                {...params}
                label={props.label ?? "Model"}
                variant="standard"
                required={props.required}
                error={props.error}
                helperText={props.helperText}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                    ),
                }}
                />
            )}
        />
    );
};

export default ValidationModelDropDown;