import { Autocomplete, AutocompleteInputChangeReason, Box, Checkbox, CircularProgress, Divider, FormControlLabel, Paper, TextField } from "@mui/material";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import React, { Fragment, SyntheticEvent, useCallback, useEffect, useState } from "react";

interface TypeAheadMultiProps {
    searchCallback: (searchTerm?: string) => Promise<Response>,
    onChange(event: SyntheticEvent<Element>, newValue: NumericDropDownModel[]):void,
    value: NumericDropDownModel[],
    disabled?: boolean,
    label: string,
    selectAllEnabled?: boolean,
    selectAllSearchCallback?: (searchTerm:string|undefined) => Promise<Response>|undefined,
    required?: boolean,
    error?: boolean,
    helperText?: string
}
const TypeAheadMulti = (props: TypeAheadMultiProps) => {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string|undefined>();
    const [options, setOptions] = useState<NumericDropDownModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const {value, searchCallback} = props
    useEffect(() => {
        let active = true;

        if (active && open) {
            setLoading(true);
            searchCallback(searchTerm)
            .then((response) => response.json())
            .then((data) => {
                if(value && value.length > 0){
                    //ensure we have a localized option of the value already selected
                    data = value.concat(data).filter((option:NumericDropDownModel, index:number, self:NumericDropDownModel[]) => {
                        return self.map((item:NumericDropDownModel) => item.value).indexOf(option.value) === index;
                    });
                }
                setOptions(data);
            }).finally(() => setLoading(false));
        }

        return () => {
            active = false;
        };
    }, [searchTerm, value, searchCallback, open]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearchTerm(undefined);
        }
    }, [open]);
    
    const onSearchChange = (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
        if(reason !== 'reset'){
            setOptions([]);
            setSearchTerm(value);
        }
    };

    const handleOnChange = useCallback((event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => {
        if(newValue.length < props.value.length){
            setSelectAll(false);
        }
        props.onChange(event, newValue);
    }, [props]);
    
    const handleToggleSelectAll = useCallback((event: React.SyntheticEvent<Element>) => {
        //we're switching over to selecting all options so the current state would not be enabled
        if(!selectAll && props.selectAllSearchCallback){
            const {selectAllSearchCallback} = props;
            setLoading(true);
            setOpen(false);
            selectAllSearchCallback?.(searchTerm)
            ?.then(response => response.json())
            ?.then(data => {
                setOptions(data);
                handleOnChange(event, data);
            })
            ?.finally(() => {
                setLoading(false);
                setSelectAll(!selectAll);
            });
        }else{
            setSelectAll(!selectAll);
            handleOnChange(event, []);
        }
    }, [selectAll, props, handleOnChange, searchTerm]);

    const selectAllComponent = useCallback((paperProps: React.HTMLAttributes<HTMLElement>):React.ReactElement<any, any>| null => {
        if( props.selectAllEnabled === true && !!props.selectAllSearchCallback) {
            const { children, ...restPaperProps } = paperProps;
            return (
                <Paper {...restPaperProps}>
                <Box
                    onMouseDown={(e) => e.preventDefault()} // prevent blur
                    pl={1.5}
                    py={0.5}
                >
                    <FormControlLabel
                    onClick={(e) => {
                        e.preventDefault(); // prevent blur
                        handleToggleSelectAll(e);
                    }}
                    label="Select all"
                    control={
                        <Checkbox id="select-all-checkbox" checked={selectAll} />
                    }
                    />
                </Box>
                <Divider />
                {children}
                </Paper>
            );
            
        };
            
        return null;
    }, [handleToggleSelectAll, props.selectAllEnabled, props.selectAllSearchCallback, selectAll]);

    return (
        <Autocomplete
            fullWidth
            multiple
            disabled={props.disabled}
            onChange={handleOnChange}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={props.value}
            options={options}
            loading={loading}
            filterOptions={(x) => x}
            disableCloseOnSelect={true}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.value}>{option.label}</li>
                );
            }}
            inputValue={searchTerm??''}
            onInputChange={onSearchChange}
            renderInput={(params) => (
                <TextField
                {...params}
                required={props.required}
                error={props.error}
                helperText={props.helperText}
                label={`${props.label}${props.value.length > 0 ? " (" + props.value.length.toString() + " Selected)": "" }`}
                variant="standard"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </Fragment>
                    ),
                }}
                />
            )}
            PaperComponent={props.selectAllEnabled ? selectAllComponent : undefined}
        />
    );
}

export default TypeAheadMulti;