import moment from "moment";
import { ApiBaseRoute } from "../../../../api_helper";
import { MostRecentPublishedScoresRequest } from "../../../../models/MostRecentPublishedScoresRequest.model";

export const GetLatestPublishedScoresDataViz = (request: MostRecentPublishedScoresRequest, section: number, token?:string):Promise<Response> => 
{
    let requestCopy = {
        ...request, 
        countryIDs: request.countryIDs.map(x => x.value),
        regionIDs: request.regionIDs.map(x => x.value),
        modelIDs: request.modelIDs.map(x => x.value),
        validationDateStart: request.validationDateRange[0] ?  moment.utc(request.validationDateRange[0]).startOf("day") : null,
        validationDateEnd: request.validationDateRange[1] ? moment.utc(request.validationDateRange[1]).startOf("day") : null,
        validationExpirationDateStart: request.validationExpirationDateRange[0] ? moment.utc(request.validationExpirationDateRange[0]).startOf("day") : null,
        validationExpirationDateEnd: request.validationExpirationDateRange[1] ? moment.utc(request.validationExpirationDateRange[1]).startOf("day") : null,
    };
     return fetch(`${ApiBaseRoute}Insights/LatestPublishedScoresOverviewDataVisualization?section=${section}`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(requestCopy)
    });
};

export const DownloadMostRecentPublishedScoresExport = (request: MostRecentPublishedScoresRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {
        ...request, 
        countryIDs: request.countryIDs.map(x => x.value),
        regionIDs: request.regionIDs.map(x => x.value),
        modelIDs: request.modelIDs.map(x => x.value),
        validationDateStart: request.validationDateRange[0] ?  request.validationDateRange[0].startOf("day") : null,
        validationDateEnd: request.validationDateRange[1] ? request.validationDateRange[1].startOf("day") : null,
        validationExpirationDateStart: request.validationExpirationDateRange[0] ? request.validationExpirationDateRange[0].startOf("day") : null,
        validationExpirationDateEnd: request.validationExpirationDateRange[1] ? request.validationExpirationDateRange[1].startOf("day") : null,
    };
    return fetch(ApiBaseRoute + `Insights/LatestPublishedScoresOverviewExport`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/octet-stream"
        },
        body: JSON.stringify(requestCopy)
    });
};