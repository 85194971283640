import { ListItemButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const GoBack = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const hasFromContext = location.state && location.state.fromPath;
    const navigateBack = () => {        
        navigate(location.state.fromPath, {
            state:  {
                componentState: location.state.componentState
            } 
        });
    };

    return (
        <>
        {hasFromContext && 
            <ListItemButton onClick={navigateBack}>Go Back</ListItemButton>
        }
        </>
    );
};

export default GoBack;