import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, Input, List, ListItem, ListItemText } from "@mui/material";
import React from "react";
import Loading from "../../components/loading/Loading.component";

interface AssessmentImportCSVDialogProps{
    open: boolean,
    onClose: () => void
}

const AssessmentImportCSVDialog = (props: AssessmentImportCSVDialogProps) => {
    const [saving, setSaving] = React.useState<boolean>(false);
    const [uploadedFiles, setUplaodedFiles] = React.useState<FileList|null>();
    const [isValid, setIsValid] = React.useState<boolean>(false);
    const [validationMessage, setValidationMessage] = React.useState<string|undefined>();
    React.useEffect(() => {
        //validate file number
        if(!!uploadedFiles && uploadedFiles?.length !== 1){
            setIsValid(false);
            setValidationMessage("Only one file may be uploaded at a time.")
        }else if(!!uploadedFiles && uploadedFiles.length === 1){
            // check file type
            let fileNameParts = uploadedFiles[0].name.split(".");
            let fileType = fileNameParts[fileNameParts.length -1];
            if(!fileType || fileType.toLowerCase() !== "csv"){
                setIsValid(false);
                setValidationMessage("Please select a csv file for upload.")
            }
        }
    }, [uploadedFiles]);

    React.useEffect(() => {
        if(isValid){
            setValidationMessage(undefined);
        }
    },[isValid]);

    const handleClose = React.useCallback(() => {
        props.onClose();
    }, [props]);

    const importCSV = React.useCallback(() => {
        setSaving(true);
    }, []);

    return (
        <>
        {saving && <Loading/>}
        {!saving && 
        <Dialog
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle>Assessment Import</DialogTitle>
            <DialogContent>
                Please format the import csv file as follows:
                <DialogContentText component={"div"}>
                    <List>
                        <ListItemText>Do not include a header row in the file.</ListItemText>
                        <ListItemText>
                            The logic used for facilities to be included in each assesment will be based on the Facility Unique IDs included in the same row and function as follows:
                        </ListItemText>
                        <ListItem >
                            <List>
                                <ListItemText>If for single Facility, include Facility UniqueId only (N/A for CCMM)</ListItemText>
                                <ListItemText>If for multiple Facilities for a Parent, include Parent UniqueId + Facility UniqueIds (';' delimited string)</ListItemText>
                                <ListItemText>If for ALL Facilities for a Parent, include Parent UniqueId only</ListItemText>
                                <ListItemText>If for Parent only (i.e. DHI), include Parent UniqueId only</ListItemText>
                            </List>
                        </ListItem>
                        <ListItemText>Columns must be in this order: Facility Unique IDs (';' delimited string), Recipients (';' delimited string), Assessment Type Name, Assessment Name, Assessment Wave Name (DOD Only), Assigned to Email, Certified Org Name, Language, Reason for Assessment</ListItemText>
                    </List>
                </DialogContentText>
                <Grid>
                    <FormControl fullWidth variant="standard" error={!isValid}>
                        <Input 
                            type="file"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUplaodedFiles(event.target.files);
                            }}
                            error={!isValid}
                        />
                    </FormControl>
                    {!isValid && <FormHelperText error={!isValid}>{validationMessage}</FormHelperText>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={importCSV} disabled={!isValid}>Upload</Button>
            </DialogActions>
        </Dialog>
        }
        </>
    );
};

export default AssessmentImportCSVDialog;