import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import Loading from "../../components/loading/Loading.component";
import { IsPasswordValid, SetUserPassword } from "./UserAccountDetail.service";

interface UserAccountSetPasswordDialogProps {
    open: boolean,
    onClose: () => void,
    auth0ID: string
};


const UserAccountSetPasswordDialog = (props: UserAccountSetPasswordDialogProps) => {
    const [password, setPassword] = React.useState<string>('');
    const [isValid, setIsValid] = React.useState<boolean>(false);
    const {appUser} = useApiAuthorization();
    const [saving, setSaving] = React.useState<boolean>(false);

    React.useEffect(() => {
        setIsValid(IsPasswordValid(password));
    }, [password]);

    const updatePassword = () => {
        setSaving(true);
        SetUserPassword(props.auth0ID, appUser!.token, password).then(response => {

        }).finally(() => {
            setSaving(false);
            handleClose();
        });
    };

    const handleClose = () => {
        setPassword('');
        props.onClose();
    }
    return (
        <>
        {saving && <Loading/>}
        {!saving && 
        <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Set User Password</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Overwrite the user password below.
                </DialogContentText>
                <form>
                    <TextField 
                        value={password}
                        fullWidth
                        variant="standard"
                        label="Password"
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setPassword(event.target.value)}
                        helperText={!isValid ? "Must be at least 8 characters long and contain at least three of the following four types of characters: lower case (a-z), upper case (A-Z), numbers (0-9), special characters (e.g. !@#$%^&*)" : undefined}
                        error={!isValid}
                        type="password"
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={updatePassword} disabled={!isValid}>Ok</Button>
            </DialogActions>
        </Dialog>
        }
        </>
    );
}

export default UserAccountSetPasswordDialog;