import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, List, ListItem, ListItemButton, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers-pro";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import TypeAhead from "../../components/formControls/TypeAheadBase.component";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import ValidationModelDropDown from "../../components/formControls/ValidationModelDropDown.component";
import Loading from "../../components/loading/Loading.component";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { ValidationDetailModel, CreateDefaultValidationDetail, isValid, ValidationResultModel } from "./Validation.models";
import { CheckValidation, DeleteValidation, GetValidation, SaveValidation } from "./Validation.service";
import { GetFacilityTypeAheadSearch } from "../../services/FacilityTypeAhead.service";
import DeleteDialog from "../../components/formComponents/DeleteDialog.component";
import { useIsDirtyPrompt } from "../../components/formComponents/useIsDirtyPrompt.component";
import GoBack from "../../components/rightMenuControls/GoBack.component";
import { userHasPermission } from "../../services/User.service";

const ValidationDetail = () => {
    const {appUser} = useApiAuthorization();
    const { validationID } = useParams();
    const [validationDetail, setValidationDetail] = React.useState<ValidationDetailModel>(CreateDefaultValidationDetail());
    const initialValidationDetail = React.useRef<ValidationDetailModel|null>(validationID === 'new' ? validationDetail : null);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showValidationText, setShowValidationText] = React.useState<boolean>(false);
    const [validationExpirationDisabled, setValidationExpirationDisabled] = React.useState<boolean>(true);
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [dialogMessage, setDialogMessage] = React.useState<string>('');
    const [dialogFacilities, setDialogFacilities] = React.useState<string[]>([]);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const isDirty = useIsDirtyPrompt(initialValidationDetail.current, validationDetail);

    React.useEffect(() => {
        if(appUser && validationID !== "new"){
            setLoading(true);
            GetValidation(appUser.token, validationID!)
                .then(response => response.json())
                .then(data => {
                    setValidationDetail(data);
                    initialValidationDetail.current = data;
                })
                .finally(() => setLoading(false));
        }
    }, [appUser, validationID]);

    React.useEffect(() => {
        setValidationExpirationDisabled(!userHasPermission("Validation_Detail_Edit", appUser) || validationDetail.validationStatus !== 'Passed');
    }, [appUser, validationDetail.validationStatus]);

    const updateValidation = () => {

        setSaving(true);

        if(validationID === 'new'){
            initialValidationDetail.current = validationDetail
        }

        SaveValidation(appUser?.token, {...validationDetail, byPassValidationCheck: true})
            .then((response) => {
                if(response.status === 400){
                    //don't think we actually need this one
                }
                return response.json();
            }).then((data) => {
                if(validationID === 'new'){
                    navigate(`/validations/${data.validationID}`);
                }
                else{
                    setValidationDetail(data);
                    initialValidationDetail.current = data;
                }
            }).finally(() => {
                setSaving(false);
            });
        
    };

    const updateValidationWithCheck = () => {
        let valid = isValid(validationDetail);
        
        if(!valid){
            setShowValidationText(true);
        }else{
            setShowValidationText(false);
            setSaving(true);
            CheckValidation(appUser?.token, {
                modelID: validationDetail.model!.value,
                validationStage: validationDetail.validationStage!,
                uniqueIDList:  validationDetail.facilities.map((facility) => facility.value),
                validationStatus: validationDetail.validationStatus
            }).then((response) => {
                if(response.status === 400){
                    return response.json();
                }
                return {};
            }).then((data) => {
                if(data.constructor === Array){
                    let parsedResponse = data as ValidationResultModel[];
                    setDialogMessage(parsedResponse[0].errorDescription);
                    setDialogFacilities(parsedResponse.map((record) => record.errorMessage));
                    setOpenDialog(true);
                }else{
                    updateValidation();
                }
                setSaving(false);
            }).finally(() => {
                setSaving(false);
            });
        }
    };

    const deleteValidation = ()=>{
        setSaving(true);
        initialValidationDetail.current = validationDetail;
        DeleteValidation(appUser?.token, validationID!).then((response) => {
        
        }).finally(() => {
            setSaving(false);
            navigate('/validations');
        })
    };

    const childMenuContent = (<RightMenu>
        <List component='nav'>
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            <GoBack/>
        {userHasPermission("Validation_Detail_Delete", appUser) && (
            <ListItemButton onClick={() => setShowDeleteDialog(true)}>Delete</ListItemButton>
        )}
        </List>
    </RightMenu>);

    return (
        <BaseLayout childMenu={(validationID !== 'new' && userHasPermission("Validation_Detail_Delete", appUser)) ? childMenuContent : false} childMenuOpen={(validationID !== 'new')}>
            {(saving || loading) && <Loading />}

            {userHasPermission("Validation_Detail_Edit", appUser) &&
            <Box display="flex" justifyContent="flex-end">
                <Fab size="medium" color="primary" aria-label="save" variant="extended" onClick={updateValidationWithCheck} disabled={!isDirty}>
                    {validationID === 'new' ? 'Create' : 'Save'}
                </Fab>
            </Box>
            }
            <Typography variant="h6" gutterBottom>
                Validation Information
            </Typography>
            <Grid container spacing={3} sx={{pb: 10}}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="validationID"
                        label="Validation ID"
                        fullWidth
                        variant="standard"
                        value={validationDetail.validationID ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="dateCreated"
                        label="Created Date"
                        fullWidth
                        variant="standard"
                        value={validationDetail.dateCreated ? moment(validationDetail.dateCreated).format("MM/DD/YYYY"): ""}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ValidationModelDropDown
                        onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel) => setValidationDetail({...validationDetail, model: newValue, parentFacility: null, facilities: []})}
                        value={validationDetail.model}
                        label="Maturity Model"
                        required
                        disabled={!userHasPermission("Validation_Detail_Edit", appUser) || validationID !== 'new'}
                        error={showValidationText && !validationDetail.model}
                        helperText={showValidationText && !validationDetail.model ? "This field is required." : undefined}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel required variant="standard" error={showValidationText && !validationDetail.validationStage}>Validation Stage</InputLabel>
                        <Select
                            value={validationDetail.validationStage ? validationDetail.validationStage.toString() : ''}
                            onChange={(event:SelectChangeEvent) => {
                                setValidationDetail({
                                    ...validationDetail, 
                                    validationStage: event.target.value ? parseInt(event.target.value) : undefined,
                                    achievingStage7: "No"
                                 });
                            }}
                            label="Validation Stage"
                            fullWidth
                            variant="standard"
                            required
                            error={showValidationText && !validationDetail.validationStage}
                            disabled={!userHasPermission("Validation_Detail_Edit", appUser) }
                        >
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                        </Select>
                    </FormControl>
                    {showValidationText && !validationDetail.validationStage && <FormHelperText error>This field is required.</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel required variant="standard" error={showValidationText && !validationDetail.validationStatus}>Outcome</InputLabel>
                        <Select
                            value={validationDetail.validationStatus}
                            onChange={(event:SelectChangeEvent) => {
                                setValidationDetail({
                                    ...validationDetail, 
                                    validationStatus: event.target.value, 
                                    validationExpirationDate: null, 
                                    validationFailureReason: "",
                                    doNotDisplay: false
                                });
                            }}
                            label="Outcome"
                            fullWidth
                            variant="standard"
                            required
                            error={showValidationText && !validationDetail.validationStatus}
                            disabled={!userHasPermission("Validation_Detail_Edit", appUser) }
                        >
                            <MenuItem value="Failed">Failed</MenuItem>
                            <MenuItem value="Passed">Passed</MenuItem>
                            <MenuItem value="Review Required">Review Required</MenuItem>
                        </Select>
                    </FormControl>
                    {showValidationText && !validationDetail.validationStatus && <FormHelperText error>This field is required.</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="validationName"
                        label="Validation Name"
                        fullWidth
                        variant="standard"
                        value={validationDetail.validationName ?? ""}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            setValidationDetail({...validationDetail, validationName: event.target.value });
                        }}
                        required
                        error={showValidationText && !validationDetail.validationName}
                        helperText={showValidationText && !validationDetail.validationName ? "This field is required." : "e.g. [Facility Name/Health System Name] - [Model Name] - [Stage]"}
                        disabled={!userHasPermission("Validation_Detail_Edit", appUser) }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="validationFailureReason"
                        label="Failure Reason"
                        fullWidth
                        variant="standard"
                        value={validationDetail.validationFailureReason ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            setValidationDetail({...validationDetail, validationFailureReason: event.target.value });
                        }}
                        required={validationDetail.validationStatus === "Failed"}
                        disabled={!userHasPermission("Validation_Detail_Edit", appUser) || validationDetail.validationStatus !== "Failed"}
                        error={showValidationText && validationDetail.validationStatus === "Failed" && !validationDetail.validationFailureReason}
                        helperText={showValidationText && validationDetail.validationStatus === "Failed" && !validationDetail.validationFailureReason ? "This field is required." : undefined}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeAhead
                        onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel|null) => {
                            setValidationDetail({...validationDetail, parentFacility: newValue, facilities: []})
                        }}
                        searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, validationDetail.model?.value, undefined, undefined, undefined, true, null)}
                        value={validationDetail.parentFacility}
                        label="Health System/Network"
                        disabled={!userHasPermission("Validation_Detail_Edit", appUser) || !validationDetail.model || validationID !== 'new'}
                        required
                        error={showValidationText && !validationDetail.parentFacility}
                        helperText={showValidationText && !validationDetail.parentFacility ? "This field is required." : !validationDetail.model && !validationDetail.parentFacility ? "Please select a Maturity Model first." : undefined}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TypeAheadMulti
                        onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]|null) => {
                            setValidationDetail({...validationDetail, facilities: newValue ?? []})
                        }}
                        searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, validationDetail.model?.value, undefined, validationDetail.parentFacility?.value, undefined, false, null)}
                        selectAllSearchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, validationDetail.model?.value, undefined, validationDetail.parentFacility?.value, undefined, false, true)}
                        value={validationDetail.facilities}
                        label="Facilities"
                        disabled={!userHasPermission("Validation_Detail_Edit", appUser) || !validationDetail.parentFacility}
                        required
                        error={showValidationText && validationDetail.facilities.length === 0}
                        helperText={showValidationText && validationDetail.facilities.length === 0 ? "This field is required." : undefined}
                        selectAllEnabled={!!validationDetail.parentFacility}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="validatedBy"
                        label="Validated By"
                        fullWidth
                        variant="standard"
                        value={validationDetail.validationBy ?? ""}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            setValidationDetail({...validationDetail, validationBy: event.target.value})
                        }}
                        disabled={!userHasPermission("Validation_Detail_Edit", appUser) }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="validationNotes"
                        label="Notes"
                        fullWidth
                        variant="standard"
                        value={validationDetail.validationNotes ?? ""}
                        multiline
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            setValidationDetail({...validationDetail, validationNotes: event.target.value})
                        }}
                        disabled={!userHasPermission("Validation_Detail_Edit", appUser) }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DatePicker
                        label="Validation Date"
                        timezone="UTC"
                        value={validationDetail.validationDate ? moment(validationDetail.validationDate) : null}
                        onChange={(value: moment.Moment|null, error: PickerChangeHandlerContext<DateValidationError>) => {
                            if (value?.isValid() && error.validationError === null && validationDetail.validationExpirationDate === null && !validationExpirationDisabled) {
                                setValidationDetail({
                                    ...validationDetail,
                                    validationDate: value,
                                    validationExpirationDate: value.clone().add(3, 'years').subtract(1, 'days')
                                });
                            } else {
                                setValidationDetail({...validationDetail, validationDate: value})
                            };
                        }}
                        disabled={!userHasPermission("Validation_Detail_Edit", appUser) }
                        slotProps={{textField: {variant: 'standard', fullWidth: true, required: true, error:showValidationText && !validationDetail.validationDate, helperText: showValidationText && !validationDetail.validationDate ? "This field is required." : undefined}}}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DatePicker
                        label="Validation Expiration Date"
                        timezone="UTC"
                        value={validationDetail.validationExpirationDate ? moment(validationDetail.validationExpirationDate) : null}
                        onChange={(value: moment.Moment|null) => {
                            setValidationDetail({...validationDetail, validationExpirationDate: value});
                        }}
                        disabled={validationExpirationDisabled}
                        slotProps={{
                            textField:{
                                fullWidth: true,
                                required: validationDetail.validationStatus === 'Passed',
                                error: showValidationText && validationDetail.validationStatus === 'Passed' && !validationDetail.validationExpirationDate,
                                variant: 'standard',
                                helperText: showValidationText && validationDetail.validationStatus === 'Passed' && !validationDetail.validationExpirationDate ? "This field is required." : undefined
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControlLabel 
                        control={
                            <Switch 
                                disabled={!userHasPermission("Validation_Detail_Edit", appUser) || validationDetail.validationStatus !== 'Passed'}
                                checked={validationDetail.doNotDisplay}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValidationDetail({...validationDetail, doNotDisplay: event.target.checked})}
                            />
                        }
                        label="Do NOT allow results to be published?"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControlLabel 
                        control={
                            <Switch 
                                disabled={!userHasPermission("Validation_Detail_Edit", appUser) || validationDetail.validationStage !== 6}
                                checked={validationDetail.achievingStage7 === 'Yes'}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValidationDetail({...validationDetail, achievingStage7: event.target.checked ? "Yes" : "No"})}
                            />
                        }
                        label="Organization is interested in achieving stage 7?"
                    />
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    <DialogContentText component="div">
                        {dialogMessage}
                        <br/>
                        <List sx={{fontStyle: "italic"}}>
                            {dialogFacilities.map((facility, index) => <ListItem key={index}>{facility}</ListItem>)}
                        </List>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={() => {
                        setOpenDialog(false);                 
                        updateValidation();
                    }}>Ignore Warning/Save</Button>
                </DialogActions>
            </Dialog>
            <DeleteDialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={deleteValidation}
                title="Please confirm you want to delete validation"
                content={`${validationDetail.validationName}\nValidation ID: ${validationID}`}
            />
        </BaseLayout>
    );
};

export default ValidationDetail;