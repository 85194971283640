import { Box } from "@mui/material";

interface TableWrapperProps {
    children?: React.ReactNode,
};

export const TableWrapper = (props: TableWrapperProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                "@media print": {
                    ".MuiGrid-root": { maxWidth: "100% !important" },
                },
            }}
        >
            {props.children}

        </Box>
    );
};
