import { Grid, List, ListItem, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import Loading from "../../components/loading/Loading.component";
import BaseLayout from "../../layout/BaseLayout.component";
import { CreateUserRoleDetailModel, UserRoleDetailModel } from "./UserRole.models";
import { GetUserRole, GetUserRolePermissionsListColumns } from "./UserRoleDetail.service";
import { DataGridPro } from "@mui/x-data-grid-pro";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import RightMenu from "../../layout/RightMenu.component";
import GoBack from "../../components/rightMenuControls/GoBack.component";

const UserRoleDetail = () => {
    const {appUser} = useApiAuthorization();
    const {userRoleID} = useParams();
    const userRoleIDNumber = parseInt(userRoleID!);
    const [userRole, setUserRole] = React.useState<UserRoleDetailModel>(CreateUserRoleDetailModel());
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setLoading(true);
        GetUserRole(userRoleIDNumber, appUser!.token)
            .then((response) => response.json())
            .then((data) => {
                setUserRole(data);
                setLoading(false);
            });
    }, [appUser, userRoleIDNumber]);

    const childMenuContent = (<RightMenu>
        <List component={"nav"}>
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            <GoBack/>
        </List>
    </RightMenu>);

    return (
        <BaseLayout childMenu={childMenuContent}>
            
            <React.Fragment>
                {loading && <Loading />}
                <Typography variant="h6" gutterBottom>
                    User Role Information
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="userRoleID"
                            label="User Role ID"
                            fullWidth
                            value={userRole.userRoleID}
                            disabled
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="userRole"
                            label="Role Name"
                            fullWidth
                            value={userRole.userRole}
                            variant="standard"
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                setUserRole({...userRole, userRole: event.target.value });
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="userRoleDescript"
                            label="Description"
                            fullWidth
                            multiline
                            value={userRole.userRoleDescription}
                            variant="standard"
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                setUserRole({...userRole, userRoleDescription: event.target.value });
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DataGridPro
                            autoHeight={true}
                            columns={GetUserRolePermissionsListColumns()}
                            rows={userRole.userRolePermissions}
                            slots={{
                                toolbar: NoFilterToolBar,
                            }}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        </BaseLayout>
    );
}

export default UserRoleDetail;