import { Divider, Grid, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { DataGridPro as DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridRowSelectionModel, GridSortModel } from "@mui/x-data-grid-pro";
import { forEach } from "lodash";
import React, { useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import { useQueryString } from "../../components/queryString/QueryString.provider";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { AccountSearchRequest, CreateDefaultAccountSearchRequest } from "./Account.models";
import { GetAccountListColumns, SearchAccounts } from "./Account.service";
import AccountMassUpdate from "./AccountMassUpdate.component";
import { GetFacilityTypeAheadSearch } from "../../services/FacilityTypeAhead.service";
import { GetFacilityTypeTypeAheadSearch } from "../../services/FacilityType.service";
import { GetUserTypeAheadSearch, userHasPermission } from "../../services/User.service";
import { GetCountryTypeAheadSearch } from "../../services/Country.service";
import { GetGeographicRegionTypeAheadSearch } from "../../services/GeographicRegion.service";
import AccountImportDialog from "../Imports/AccountImportDialog.component";

function AccountList(){
    const {appUser} = useApiAuthorization();
    const columns: GridColDef[] = useMemo(() => GetAccountListColumns(), []);
    const presetQueryOptions = useQueryString();
    const navigate = useNavigate();
    const location = useLocation();
    const [queryOptions, setQueryOptions] = React.useState<AccountSearchRequest>(CreateDefaultAccountSearchRequest(presetQueryOptions, location, appUser));
    const [rowData, setRowData] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [showMassUpdate, setShowMassUpdate] = React.useState<boolean>(false);
    const [showImport, setShowImport] = React.useState<boolean>(false);

    React.useEffect(() => {
        if(appUser?.token && !showMassUpdate){
            setIsLoading(true);
            SearchAccounts(queryOptions, appUser.token)
            .then((response) => response.json())
            .then((data) => {
                setRowData(data);
                if(data && data.length > 0){
                    setRowCount(data[0].totalCount);
                } else {
                    setRowCount(0);
                }
            }).finally(() => setIsLoading(false));
        }
    }, [queryOptions, appUser?.token, showMassUpdate]); //only run useEffect if the queryOptions change

    const onPageChange = React.useCallback((newPage: number) => {
        setQueryOptions({...queryOptions, page: newPage});
    }, [queryOptions]);

    const onSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions({...queryOptions, sortColumn: sortParam.field, sortDirection: sortParam.sort});
        });
    }, [queryOptions]);    

    const childMenuContent = (<RightMenu>
    <List component="nav">
        <ListItem>
            <Typography variant="h6">Action Items</Typography>
        </ListItem>
        {userHasPermission("Account_Detail_Edit", appUser) &&
        <ListItemButton component={Link} to={"/accounts/new"}>
            Create New Account
        </ListItemButton>
        }
        <ListItemButton onClick={() => setQueryOptions(CreateDefaultAccountSearchRequest(presetQueryOptions,location, appUser))} key="reset">
            Reset Filters
        </ListItemButton>
        {userHasPermission("Account_List_Edit", appUser) &&
        <ListItemButton onClick={() => setShowMassUpdate(true)} disabled={selectionModel.length === 0}>
            Mass Update
        </ListItemButton>
        }
        {userHasPermission("Import_List_Edit", appUser) &&
        <ListItemButton onClick={() => setShowImport(true)}>
            Import Accounts
        </ListItemButton>
        }
        <Divider/>
        <ListItem>
            <Typography variant="h6">Filters</Typography>
        </ListItem>
        <ListItem>
            <TypeAheadMulti
                onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, parentAccountUniqueIDs: newValue, page: 0})}
                searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, [8,14], null, null)}
                value={queryOptions.parentAccountUniqueIDs}
                label="Facilites By Parent"
            />
        </ListItem>
        <ListItem>
            <TypeAheadMulti
                onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, facilityTypeIDs: newValue, page: 0})}
                searchCallback={(searchTerm?: string) => GetFacilityTypeTypeAheadSearch(appUser?.token!, searchTerm, [1,3,8,14])}
                value={queryOptions.facilityTypeIDs}
                label="Facility Types"
            />
        </ListItem>
        <ListItem>
            <TypeAheadMulti
                onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, facilityIDs: newValue, page: 0})}
                searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, queryOptions.facilityTypeIDs.map(x => x.value), null, null)}
                value={queryOptions.facilityIDs}
                label="Facilities"
            />
        </ListItem>
        <ListItem>
            <TypeAheadMulti
                onChange={(event: React.SyntheticEvent<Element,Event>, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, assignedToUserIDs: newValue, page: 0})}
                searchCallback={(searchTerm?: string) => GetUserTypeAheadSearch(appUser?.token!, searchTerm, true)}
                value={queryOptions.assignedToUserIDs}
                label="Assigned To"
            />
        </ListItem>
        <ListItem>
            <TypeAheadMulti
                onChange={(event: React.SyntheticEvent<Element,Event>, newValue:NumericDropDownModel[]) => {
                    setQueryOptions({...queryOptions, countryIDs: newValue, stateIDs: [], page: 0})
                }}
                searchCallback={(searchTerm?: string) => GetCountryTypeAheadSearch(appUser?.token!, searchTerm)}
                value={queryOptions.countryIDs}
                label="Countries"
            />
        </ListItem>
        <ListItem>
            <TypeAheadMulti
                onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, stateIDs: newValue, page: 0})}
                searchCallback={(searchTerm?: string) => GetGeographicRegionTypeAheadSearch(appUser?.token!, searchTerm, queryOptions.countryIDs?.map(c => c.value))}
                value={queryOptions.stateIDs}
                label="States"
            />
        </ListItem>
    </List>
</RightMenu> )

    return (
        <BaseLayout childMenu={childMenuContent}>
            
            <Grid sx={{display: "flex", height: "100%"}}>
                <DataGrid
                    columns={columns}
                    rows={rowData}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    paginationMode="server"
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: 'Accounts',
                            gridConfigName: 'accountGridState',
                            defaultConfig: {
                                columns: {
                                    columnVisibilityModel: {
                                        lastCompletedSurveyDate: false,
                                        dateModified: false,
                                    },
                                }
                            }
                        }
                    }}
                    onRowClick={(params: GridRowParams) => {
                        navigate(`${params.id}`, {
                            state: {
                                fromPath: location.pathname,
                                componentState: queryOptions
                            }
                        });
                    }}
                    checkboxSelection={appUser?.permissions && appUser.permissions.indexOf("Account_List_Edit") > -1}
                    onRowSelectionModelChange={(selectionModel: GridRowSelectionModel) => setSelectionModel(selectionModel)}
                    rowSelectionModel={selectionModel}
                    isRowSelectable={(params: GridRowParams) => params.row.accountType !== 'Network' && params.row.accountType !== "Health System"}
                />
            </Grid>
            <AccountMassUpdate open={showMassUpdate} onClose={() => setShowMassUpdate(false)} selectedIDs={selectionModel}/>
            <AccountImportDialog open={showImport} onClose={() => setShowImport(false)}/>
        </BaseLayout>
    );
}

export default AccountList;