import { Divider, Grid, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { DataGridPro as DataGrid, GridCallbackDetails, GridCellParams, GridColDef, GridPaginationModel, GridSortModel, MuiEvent } from "@mui/x-data-grid-pro";
import { forEach } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { CreateDefaultAssessmentTypeSearchRequest, AssessmentTypeSearchRequest } from "./AssessmentType.models";
import { DownloadQuestionsAndAnswers, GetAssessmentTypeListColumns, SearchAssessmentTypes } from "./AssessmentType.service";
import QuestionAnswerImportDialog from "./QuestionAnswerImportDialog.component";
import { GetAssessmentTypeTypeAheadSearch } from "../../services/AssessmentType.service";
import { userHasPermission } from "../../services/User.service";

function AssessmentTypeList(){
    const {appUser} = useApiAuthorization();
    const [rowData, setRowData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const downloadCallback = useCallback((surveyTypeID: number) => {
        let filename:string|undefined;
        setIsLoading(true);
        DownloadQuestionsAndAnswers(surveyTypeID, appUser?.token).then(response => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            filename = response.headers.get('Content-Disposition')?.split("; ").filter(x => x.startsWith("filename="))[0].split("=")[1].replaceAll('"', '');
            return response.blob();
        }).then(blob => {
            if(blob != null)
            {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename!;//we've gotten a 200 response so it's safe to assume we have a filename
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        }).finally(() => setIsLoading(false));
    }, [appUser?.token]);
    const columns: GridColDef[] = useMemo(() => GetAssessmentTypeListColumns(downloadCallback), [downloadCallback]);
    const [queryOptions, setQueryOptions] = useState<AssessmentTypeSearchRequest>(CreateDefaultAssessmentTypeSearchRequest());
    const [showQuestionImport, setShowQuestionImport] = useState<boolean>(false);

    useEffect(() => {
        if(appUser?.token){
            setIsLoading(true);
            SearchAssessmentTypes(queryOptions, appUser.token)
            .then((response) => response.json())
            .then((data) => {
                setRowData(data);
                if(data && data.length > 0){
                    setRowCount(data[0].totalCount);
                } else {
                    setRowCount(0);
                }
            }).finally(() => setIsLoading(false));
        }
    }, [queryOptions, appUser?.token]); //only run useEffect if the queryOptions change

    const onPageChange = useCallback((newPage: number) => {
        setQueryOptions({...queryOptions, page: newPage});
    }, [queryOptions]);

    const onSortModelChange = useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions({...queryOptions, sortColumn: sortParam.field, sortDirection: sortParam.sort});
        });
    }, [queryOptions]);

    const childMenuContent = (<RightMenu>
        <List component="nav">
            {userHasPermission('Question_List_Edit', appUser) &&
            <>
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            <ListItemButton onClick={() => setShowQuestionImport(true)}>
                Import Questions/Answers
            </ListItemButton>
            <Divider/>
            </>
            }
            <ListItem>
                <Typography variant="h6">Filters</Typography>
            </ListItem>
            <ListItem>
                <TypeAheadMulti 
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, surveyTypes: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetAssessmentTypeTypeAheadSearch(appUser?.token!, searchTerm)}
                    value={queryOptions.surveyTypes}
                    label="Assessment Types"
                />
            </ListItem>
        </List>
    </RightMenu>);

    return (
        <BaseLayout childMenu={childMenuContent}>
            
            <Grid sx={{display: "flex", height: "100%"}}>
                <DataGrid
                    columns={columns}
                    rows={rowData}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    paginationMode="server"
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: 'AssessmentTypes',
                            gridConfigName: 'assessmentTypeGridState'
                        }
                    }}
                    onCellClick={(params: GridCellParams<any, any, any>, event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>, details: GridCallbackDetails<any>) => {
                        //this is a hack to get around setting the click event on the row to navigate when we also want to use links in cells
                        if(params.field === "download"){
                            event.stopPropagation();
                            return;
                        }
                        if (params.field === "__check__") {
                            return;
                        } 
                    }}
                />
                <QuestionAnswerImportDialog open={showQuestionImport} onClose={() => setShowQuestionImport(false)}/>
            </Grid>
        </BaseLayout>
    );
}

export default AssessmentTypeList;