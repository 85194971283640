import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";

const headerStyling = {
    background: "#1e22aa",
    color: "#ffffff",
    fontWeight: "700",
    padding: "5px 20px",
};

export const PerformanceIndicatorsTable = (item: any) => (
    <TableWrapper>
        <TableContainer>
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={headerStyling}>
                            Performance Indicator
                        </TableCell>
                        <TableCell align="center" sx={headerStyling}>
                           
                        </TableCell>
                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {item.data.rowData.map((row: any) => (
                        <TableRow
                            key={row.name}
                        >
                            <TableCell align="left">{row.col1}</TableCell>
                            <TableCell align="left">{row.col2}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
    </TableWrapper>
);
