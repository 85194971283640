import { ApiBaseRoute } from "../api_helper";

export function GetFacilityTypeAheadSearch(token: string, searchTerm: string|undefined, maturityModelID?: number, surveyTypeID?: number, parentUniqueID?: number, allowedFacilityTypeIDs?: number[], parentOnly?: boolean|null, getAllResults?: boolean|null): Promise<Response> {
    return fetch(ApiBaseRoute+`accounts/typeaheadSearch`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            searchTerm: searchTerm ?? null,
            maturityModelID: maturityModelID ?? null,
            surveyTypeID: surveyTypeID ?? null,
            parentUniqueID: parentUniqueID ?? null,
            allowedFacilityTypeIDs: allowedFacilityTypeIDs ?? [],
            parentOnly: parentOnly,
            getAllResults: getAllResults
        })
    });
};