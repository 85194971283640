import { ApiBaseRoute } from "../api_helper";
import NumericDropDownModel from "../models/NumericDropDown.model";

export function GetCountryTypeAheadSearch(token: string, searchTerm: string|undefined, globalRegionIDs?:NumericDropDownModel[]): Promise<Response> {
    return fetch(ApiBaseRoute+`countries/typeaheadSearch`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            searchTerm: searchTerm ?? '',
            globalRegionIDs: globalRegionIDs?.map(x => x.value) ?? []
        })
    });
};