import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React from "react";
import { GetPrimaryServiceSearch } from "../../services/PrimaryService.service";
import { useApiAuthorization } from "../authorization/ApiAuthorizationProvider.component";

interface PrimaryServiceDropDownProps {
    onChange(event: React.SyntheticEvent<Element>, newValue: string|null):void,
    value: string|null,
    disabled?: boolean,
    label?: string,
    required?: boolean,
    facilityTypeID: number|null
};

const PrimaryServiceDropDown = (props:PrimaryServiceDropDownProps) => {
    const {appUser} = useApiAuthorization();
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        let active = true;

        if (active && !!props.facilityTypeID && open) {
            setLoading(true);
            GetPrimaryServiceSearch(appUser?.token!, props.facilityTypeID)
            .then((response) => response.json())
            .then((data) => {
                if(props.value){
                    //ensure we have a localized option of the value already selected
                    data = [props.value, ...data.filter((option:string) => option !== props.value)]
                }
                setOptions(data);
            }).finally(() => setLoading(false));
        }

        return () => {
            active = false;
        };
    }, [appUser?.token, props.value, props.facilityTypeID, open]);
    
    return (
        <Autocomplete
            fullWidth
            disabled={!props.facilityTypeID || props.disabled}
            onChange={props.onChange}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={props.value}
            getOptionLabel={(option) => option}
            options={options}
            loading={loading}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option}>{option}</li>
                );
            }}
            renderInput={(params) => (
                <TextField
                {...params}
                label={props.label ?? "Primary Service"}
                variant="standard"
                required={props.required}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                    ),
                }}
                />
            )}
        />
    );
};

export default PrimaryServiceDropDown;