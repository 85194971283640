import React from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgressWithLabel from "./CircularProgressWithLabel.component";


const Loading = () => {
    return (
    <Backdrop
        open={true}
    >
        <CircularProgressWithLabel
            color='primary'
            label={'Loading...'}
        />
    </Backdrop>
    );
}

export default Loading;