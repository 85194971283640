import { Divider, FormControl, Grid, InputLabel, List, ListItem,ListItemButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { DataGridPro as DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
import { forEach } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import BaseLayout from "../../layout/BaseLayout.component";
import { DocumentSearchRequest, CreateDefaultDocumentSearchRequest } from "./Document.models";
import { DownloadDocument, GetDocumentListColumns, SearchDocuments } from "./Document.service";
import RightMenu from "../../layout/RightMenu.component";
import { useQueryString } from "../../components/queryString/QueryString.provider";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import { GetFacilityTypeAheadSearch } from "../../services/FacilityTypeAhead.service";
import { GetAssessmentTypeAheadSearch } from "../../services/Assessment.service";
import NumericDropDownModel from "../../models/NumericDropDown.model";

function DocumentsList(){
    const {appUser} = useApiAuthorization();
    const getDocumentCallback = useCallback((fileName: string, s3Link: string) => {
        setIsLoading(true);
        var formData = new FormData();
        
        formData.append('BucketName', 'ha-assessment-content');
        formData.append('FileName', s3Link.replace('https://ha-assessment-content.s3.amazonaws.com/',''));

        DownloadDocument(formData, appUser!.token)
            .then(response => {
                return response.blob();
            })
            .then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', fileName ? fileName : '');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .finally(() => setIsLoading(false));
    }, [appUser]);
    const columns: GridColDef[] = useMemo(() => GetDocumentListColumns(getDocumentCallback), [getDocumentCallback]);
    const location = useLocation();
    const presetParams = useQueryString();
    const [queryOptions, setQueryOptions] = React.useState<DocumentSearchRequest>(CreateDefaultDocumentSearchRequest(presetParams, location));
    const [rowData, setRowData] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        if(appUser?.token){
            setIsLoading(true);
            SearchDocuments(queryOptions, appUser.token)
            .then((response) => response.json())
            .then((data) => {
                setRowData(data);
                if(data && data.length > 0){
                    setRowCount(data[0].totalCount);
                } else {
                    setRowCount(0);
                }
            }).finally(() => setIsLoading(false));
        }
    }, [queryOptions, appUser?.token]);

    const onPageChange = React.useCallback((newPage: number) => {
        setQueryOptions({...queryOptions, page: newPage});
    }, [queryOptions]);

    const onSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions({...queryOptions, sortColumn: sortParam.field, sortDirection: sortParam.sort});
        });
    }, [queryOptions]);

    const childMenuContent = (<RightMenu>
        <List component="nav">
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            <ListItemButton onClick={() => setQueryOptions(CreateDefaultDocumentSearchRequest(presetParams, location))}>
                Reset Filters
            </ListItemButton>
            <Divider />
            <ListItem>
                <Typography variant="h6">Filters</Typography>
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, assessmentIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetAssessmentTypeAheadSearch(appUser?.token!, searchTerm)}
                    value={queryOptions.assessmentIDs}
                    label="Assessments"
                />
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, facilityIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, undefined, null, null)}
                    value={queryOptions.facilityIDs}
                    label="Facilities"
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel variant="standard">Status</InputLabel>
                    <Select
                        value={queryOptions.isCurrent ? true : false}
                        onChange={(event: SelectChangeEvent<boolean>, child: React.ReactNode) => setQueryOptions({...queryOptions, isCurrent: event.target.value as boolean, page: 0})}
                        label="Status"
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem value={true as any}>Most Recent</MenuItem>
                        <MenuItem value={false as any}>ALL</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
        </List>
    </RightMenu>);
 
    return (
        <BaseLayout childMenu={childMenuContent}>
            
            <Grid sx={{display: "flex", height: "100%"}}>
                <DataGrid
                    columns={columns}
                    rows={rowData}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    paginationMode="server"
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: 'Documents',
                            gridConfigName: 'documentGridState'
                        }
                    }}
                    onRowClick={(params: GridRowParams) => {
                        navigate(`${params.row.documentID}`, {
                            state: {
                                fromPath: location.pathname,
                                componentState: queryOptions
                            }
                        });
                    }}
                />
            </Grid>
        </BaseLayout>
    );
}

export default DocumentsList;