import { Grid, Typography } from "@mui/material";
import { CreateDefaultStageAchievedDistributionModel, LatestPublishedScoresDetailsProps, StageAchievedDistributionModel } from "./LatestPublishedScoresDetails.models";
import { useEffect, useRef, useState } from "react";
import { BarChart } from "@mui/x-charts";
import { useApiAuthorization } from "../../../../components/authorization/ApiAuthorizationProvider.component";
import { GetLatestPublishedScoresDetailsDataVisualization } from "./LatestPublishedScoresDetails.service";
import { GlobalColors } from "../../../../constants/GlobalColors.const";

const StageAchievedDistribution = (props: LatestPublishedScoresDetailsProps) => {
    const [dataVizData, setDataVizData] = useState<StageAchievedDistributionModel>(CreateDefaultStageAchievedDistributionModel());
    const { onLoad, queryOptions } = props;
    const { appUser } = useApiAuthorization();     
    const handleOnLoad = useRef(onLoad);

    useEffect(() => {
        handleOnLoad.current(true);
        GetLatestPublishedScoresDetailsDataVisualization(queryOptions, "2", appUser?.token)
            .then(response => response.json())
            .then(data => {
                setDataVizData(data);
            })
            .finally(() => handleOnLoad.current(false));

    }, [queryOptions, handleOnLoad, appUser?.token]);

    return(
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle1" textAlign="center">
                    Stage Assessed Distribution
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <BarChart
                    sx={{
                        '& .MuiBarLabel-root': {
                            fill: '#fff'
                        }
                    }}
                    slotProps={{
                        legend: {
                            direction: "column",
                            position: {
                                vertical:'middle',
                                horizontal: 'right',
                            },
                        }
                    }}
                    height={500}
                    xAxis={[{
                        scaleType: "band", 
                        data: ["Stage 0", "Stage 1", "Stage 2", "Stage 3", "Stage 4", "Stage 5", "Stage 6", "Stage 7"]
                    }]}
                    series={
                        [
                            {
                                data:[
                                    dataVizData.stageDistributions.filter(x => x.stage === 0)?.[0]?.total ?? 0,
                                    dataVizData.stageDistributions.filter(x => x.stage === 1)?.[0]?.total ?? 0,
                                    dataVizData.stageDistributions.filter(x => x.stage === 2)?.[0]?.total ?? 0,
                                    dataVizData.stageDistributions.filter(x => x.stage === 3)?.[0]?.total ?? 0,
                                    dataVizData.stageDistributions.filter(x => x.stage === 4)?.[0]?.total ?? 0,
                                    dataVizData.stageDistributions.filter(x => x.stage === 5)?.[0]?.total ?? 0,
                                    dataVizData.stageDistributions.filter(x => x.stage === 6)?.[0]?.total ?? 0,
                                    dataVizData.stageDistributions.filter(x => x.stage === 7)?.[0]?.total ?? 0,
                                ],
                                valueFormatter: (value) => `${value} Facilities`,
                            }
                        ]
                    }
                    barLabel={props.axisLable}
                    colors={GlobalColors}
                />
            </Grid>
        </Grid>
    )
};

export default StageAchievedDistribution;