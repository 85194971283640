import BaseLayout from "../../layout/BaseLayout.component";

const NotFound = () => {
    return (
        <BaseLayout>
            <h3>Page not found.</h3>
        </BaseLayout>
    );
};

export default NotFound;