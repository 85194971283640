import { Grid, Typography } from "@mui/material"
import { CreateDefaultFocusAreaStageAchievementByFacilityModel, FocusAreaStageAchievementByFacilityModel, LatestPublishedScoresDetailsProps } from "./LatestPublishedScoresDetails.models";
import { useEffect, useRef, useState } from "react";
import { BarChart } from "@mui/x-charts";
import { useApiAuthorization } from "../../../../components/authorization/ApiAuthorizationProvider.component";
import { GetLatestPublishedScoresDetailsDataVisualization } from "./LatestPublishedScoresDetails.service";
import { GlobalColors } from "../../../../constants/GlobalColors.const";

const FocusAreaStageAchievementByFacility = (props: LatestPublishedScoresDetailsProps) => {
    const [dataVizData, setDataVizData] = useState<FocusAreaStageAchievementByFacilityModel>(CreateDefaultFocusAreaStageAchievementByFacilityModel());
    const { onLoad, queryOptions } = props;
    const { appUser } = useApiAuthorization();     
    const handleOnLoad = useRef(onLoad);

    useEffect(() => {
        handleOnLoad.current(true);
        GetLatestPublishedScoresDetailsDataVisualization(queryOptions, "4", appUser?.token)
            .then(response => response.json())
            .then(data => {
                setDataVizData(data);
            })
            .finally(() => handleOnLoad.current(false));

    }, [queryOptions, handleOnLoad, appUser?.token]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle1" textAlign="center">
                    Focus Area Stage Achievement
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <BarChart
                    sx={{
                        '& .MuiBarLabel-root': {
                            fill: '#fff'
                        }
                    }}
                    slotProps={{
                        legend: {
                            direction: "row",
                            position: {
                                vertical:'bottom',
                                horizontal: 'middle',
                            },
                        }
                    }}
                    height={500}
                    margin={{ top: 5, bottom: 150 }}
                    xAxis={[{
                        data: ["Stage 0 & 1","Stage 2 & 3","Stage 4 & 5","Stage 6 & 7"],
                        scaleType: 'band',
                        label: "Number of Organizations by Focus Area Stage"
                    }]
                    }
                    series={
                        dataVizData.focusAreaAchievements.map(record => {
                            return {
                                data: [record.stage01, record.stage23, record.stage45, record.stage67],
                                label: record.focusArea,
                                type: 'bar'
                            }
                        })
                    }
                    barLabel={props.axisLable}
                    colors={GlobalColors}
                />
            </Grid>
        </Grid>
    )
};

export default FocusAreaStageAchievementByFacility;