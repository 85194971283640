import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { ApiBaseRoute } from "../../api_helper";
import { ReleaseNoteDetailModel, ReleaseNoteSearchRequest } from "./ReleaseNote.models";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export const GetReleaseNoteListColumns = ():GridColDef[] => {
    return [
        { field: "active", headerName: "Active", flex: .2, filterable: false, align: "center", headerAlign: "center", renderCell: (params) => {
            return params.value ? <CheckIcon></CheckIcon> : <CloseIcon></CloseIcon>
        }},
        { field: "title", headerName: "Title", flex: 1, filterable: false},
        { field: "createdByFullName", headerName: "Created By", flex: .6, filterable: false},
        {
            field: "dateCreated",
            headerName: "Created Date",
            flex: .4,
            filterable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        },
        { field: "modifiedByFullName", headerName: "Modified By", flex: .6, filterable: false},
        {
            field: "dateModified",
            headerName: "Modified Date",
            flex: .4,
            filterable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        }
    ]
};

export const SearchReleaseNotes = (request: ReleaseNoteSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {
        ...request, 
    };
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "ReleaseNotes/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(requestCopy)
    });
};

export const HomeSearchReleaseNotes = (token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "ReleaseNotes/HomeSearch", {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const GetReleaseNoteDetail = (releaseNoteID: string, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "ReleaseNotes/" + releaseNoteID, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const SaveReleaseNoteDetail = (releaseNoteDetailModel: ReleaseNoteDetailModel, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "ReleaseNotes/Save", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(releaseNoteDetailModel)
    });
};

export const DeleteReleaseNote = (releaseNoteID: string, token: string|undefined):Promise<Response> => {
    return fetch(`${ApiBaseRoute}ReleaseNotes/Delete?releaseNoteID=${releaseNoteID}`, {
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};
