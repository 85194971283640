import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import TypeAhead from "../../components/formControls/TypeAheadBase.component";
import Loading from "../../components/loading/Loading.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { MergeAccounts } from "./Account.service";
import { GetFacilityTypeAheadSearch } from "../../services/FacilityTypeAhead.service";

interface AccountMergeDialogProps{
    open: boolean,
    onClose: () => void,
    uniqueID: number
}

const AccountMergeDialog = (props: AccountMergeDialogProps) => {
    const [saving, setSaving] = React.useState<boolean>(false);
    const [mergeToFacility, setMergeToFacility] = React.useState<NumericDropDownModel|null>(null);
    const {appUser} = useApiAuthorization();
    const navigate = useNavigate();

    const handleClose = React.useCallback(() => {
        setMergeToFacility(null);
        props.onClose();
    }, [props]);

    const handleConfirm = React.useCallback(() => {
        setSaving(true);
        MergeAccounts({fromUniqueID: props.uniqueID, toUniqueID: mergeToFacility}, appUser?.token).then(response => {
            if(response.status === 200){
                handleClose();
                navigate(`/accounts/${mergeToFacility?.value}`, {
                    replace: true
                });
            }
        }).finally(() => {
            setSaving(false);
        });
    }, [appUser?.token, mergeToFacility, navigate, props.uniqueID, handleClose]);
    
    return (
        <>
            {saving && <Loading/>}
            {!saving && 
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>Merge Accounts</DialogTitle>
                <DialogContent>
                    <DialogContentText>Select the account you want to merge this into:</DialogContentText>
                    <TypeAhead
                        value={mergeToFacility}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setMergeToFacility(newValue)}
                        searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, [8], null, null)}
                        label="Account to merge into"
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleConfirm} disabled={mergeToFacility === null}>Merge</Button>
                </DialogActions>
            </Dialog>
            }
        </>
    );
};

export default AccountMergeDialog;
