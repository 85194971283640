import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, Grid, InputLabel, List, ListItem, ListItemButton, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import TypeAhead from "../../components/formControls/TypeAheadBase.component";
import Loading from "../../components/loading/Loading.component";
import GoBack from "../../components/rightMenuControls/GoBack.component";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { CaseDetailModel, CreateDefaultCaseDetail } from "./Case.models";
import { DeleteCaseDetail, FileCase, GetCaseDetail } from "./CaseDetail.service";
import { GetFacilityTypeAheadSearch } from "../../services/FacilityTypeAhead.service";
import { GetUserTypeAheadSearch, userHasPermission } from "../../services/User.service";
import { useIsDirtyPrompt } from "../../components/formComponents/useIsDirtyPrompt.component";

function CaseDetail() {
    const {appUser} = useApiAuthorization();
    const {caseID} = useParams();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [caseDetail, setCaseDetail] = React.useState<CaseDetailModel>(CreateDefaultCaseDetail());
    const initialCaseDetail = React.useRef<CaseDetailModel|null>(null);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const isDirty = useIsDirtyPrompt(initialCaseDetail.current, caseDetail);

    React.useEffect(() => {
        let caseIDNumber = parseInt(caseID!);
        if(isNaN(caseIDNumber) === false){
            setLoading(true);
            GetCaseDetail(caseIDNumber, appUser!.token)
                .then((response) => response.json())
                .then((data) => {
                    setCaseDetail(data);
                    initialCaseDetail.current = data;
                }).finally(() => setLoading(false));
        }
    }, [appUser, caseID]);

    const updateCase = () => {
        setSaving(true);
        FileCase(appUser!.token, caseDetail)
        .then((response) => response.json())
        .then((data) => {
            setCaseDetail(data);
            initialCaseDetail.current = data;
        }).finally(() => setSaving(false));        
    };

    const deleteCase = () => {
        setDeleteDialogOpen(false);
        setSaving(true);
        initialCaseDetail.current = caseDetail;
        DeleteCaseDetail(caseDetail.caseID, appUser!.token)
        .then((response) => {
            setSaving(false);
            navigate("/cases");
        }).finally(() => setSaving(false));     
    };

    const childMenuContent = (<RightMenu>
        <List component="nav">
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            <GoBack/>
            {userHasPermission("Case_Detail_Delete", appUser) &&
            <ListItemButton onClick={() => setDeleteDialogOpen(true)}>
                Delete
            </ListItemButton>
            }
        </List>
    </RightMenu>);
    
    return (
        <BaseLayout childMenu={childMenuContent}>
            {(saving || loading) && <Loading />}
            
            {appUser?.permissions && appUser.permissions.indexOf("Case_Detail_Edit") > -1 &&
            <Box display="flex" justifyContent="flex-end">
                <Fab size="medium" color="primary" aria-label="save" variant="extended" onClick={updateCase} disabled={!isDirty}>
                    Save 
                </Fab>
            </Box>
            }
            <Typography variant="h6" gutterBottom>
                Case Details
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard">Status</InputLabel>
                        <Select
                            value={caseDetail.status}
                            onChange={(event:SelectChangeEvent) => setCaseDetail({...caseDetail, status: event.target.value})}
                            label="Status"
                            fullWidth
                            variant="standard"
                            disabled={!appUser?.permissions || appUser.permissions.indexOf("Case_Detail_Edit") < 0}
                        >
                            <MenuItem value="New">New</MenuItem>
                            <MenuItem value="Closed">Closed</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeAhead 
                        onChange={(event: React.SyntheticEvent, newValue: NumericDropDownModel|null) => setCaseDetail({...caseDetail, facility: newValue})}
                        searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, undefined, null, null)}
                        value={caseDetail.facility}
                        disabled
                        label="Facility"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="CaseID"
                        label="Case ID"
                        fullWidth
                        variant="standard"
                        value={caseDetail.caseID}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="Assessment ID"
                        label="Assessment ID"
                        fullWidth
                        variant="standard"
                        value={caseDetail.surveyID ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} sm={6}>
                        <TypeAhead 
                            onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel|null) => {
                                setCaseDetail({...caseDetail, assignedTo: newValue, assignedToID: newValue?.value});
                            }}
                            searchCallback={(searchTerm?: string) => GetUserTypeAheadSearch(appUser?.token!, searchTerm, true)}
                            value={caseDetail.assignedTo}
                            label="Assigned To"
                            disabled={!appUser?.permissions || appUser.permissions.indexOf("Case_Detail_Edit") < 0}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        name="Case Name"
                        label="Case Name"
                        fullWidth
                        variant="standard"
                        value={caseDetail.caseName}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setCaseDetail({...caseDetail, caseName: event.target.value})}
                        disabled={!appUser?.permissions || appUser.permissions.indexOf("Case_Detail_Edit") < 0}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        multiline
                        name="Case Description"
                        label="Description"
                        fullWidth
                        variant="standard"
                        value={caseDetail.description ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                            setCaseDetail({...caseDetail, description: event.target.value});
                        }}
                        disabled={!appUser?.permissions || appUser.permissions.indexOf("Case_Detail_Edit") < 0}
                    />
                </Grid>             
                <Grid item xs={12} sm={6}>
                    <TextField 
                        name="Error Code"
                        label="Error Code"
                        fullWidth
                        variant="standard"
                        value={caseDetail.errorCode ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="QA Comment"
                        label="QA Comment"
                        fullWidth
                        variant="standard"
                        value={caseDetail.qaComment ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCaseDetail({...caseDetail, qaComment: event.target.value});
                        }}
                        disabled={!appUser?.permissions || appUser.permissions.indexOf("Case_Detail_Edit") < 0}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="Date Created"
                        label="Date Created"
                        fullWidth
                        variant="standard"
                        value={caseDetail.dateCreated ? moment(caseDetail.dateCreated).format("MM/DD/YYYY"): ""}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="Date Modified"
                        label="Date Modified"
                        fullWidth
                        variant="standard"
                        value={caseDetail.dateModified ? moment(caseDetail.dateModified).format("MM/DD/YYYY"): ""}
                        disabled
                    />
                </Grid>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                >
                    <DialogTitle>Action Confirmation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please confirm you wish to delete this case. 
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                        <Button onClick={deleteCase} autoFocus>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </BaseLayout>
    );
};

export default CaseDetail;