import { ApiBaseRoute } from "../api_helper";

export const GetMaturityModelSearch = (searchTerm: string|undefined, excludedModels?: number[]):Promise<Response> => {
    return fetch(`${ApiBaseRoute}maturityModels/typeAheadSearch`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "accept": "applicaiton/json"
        },
        body: JSON.stringify({
            searchTerm: searchTerm,
            excludedModels: excludedModels ? excludedModels : []
        })
    });
};