import { ApiBaseRoute } from "../../api_helper";
import { EmailTemplateDetailModel } from "./EmailTemplate.models";

export const GetEmailTemplateDetail = (emailTemplateID: number, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "EmailTemplates/" + emailTemplateID, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const FileEmailTemplate = (token: string|undefined, emailTemplateDetail: EmailTemplateDetailModel):Promise<Response> => {
    emailTemplateDetail.content = '';  // Leaving in the content could cause a large, unnecessary object transfer depending on the template

    return fetch(ApiBaseRoute + "EmailTemplates/FileEmailTemplate",{
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(emailTemplateDetail)
    });
};