import {Divider, FormControl, Grid, InputLabel, List, ListItem,ListItemButton, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { DataGridPro as DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
import { forEach } from "lodash";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import BaseLayout from "../../layout/BaseLayout.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { ValidationSearchRequest, CreateValidationSearchRequest } from "./Validation.models";
import { GetValidationListColumns, SearchValidations } from "./Validation.service";
import RightMenu from "../../layout/RightMenu.component";
import ValidationModelDropDown from "../../components/formControls/ValidationModelDropDown.component";
import { useQueryString } from "../../components/queryString/QueryString.provider";
import { GetFacilityTypeAheadSearch } from "../../services/FacilityTypeAhead.service";
import { userHasPermission } from "../../services/User.service";

function ValidationList(){
    const {appUser} = useApiAuthorization();
    const columns: GridColDef[] = useMemo(() => GetValidationListColumns(), []);
    const presetParams = useQueryString();
    const location = useLocation();
    const [queryOptions, setQueryOptions] = React.useState<ValidationSearchRequest>(CreateValidationSearchRequest(location, presetParams));
    const [rowData, setRowData] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        if(appUser?.token){
            setIsLoading(true);
            SearchValidations(queryOptions, appUser.token)
            .then((response) => response.json())
            .then((data) => {
                setRowData(data);
                if(data && data.length > 0){
                    setRowCount(data[0].totalCount);
                } else {
                    setRowCount(0);
                }
            }).finally(() => setIsLoading(false));
        }
    }, [queryOptions, appUser?.token]); //only run useEffect if the queryOptions change

    const onPageChange = React.useCallback((newPage: number) => {
        setQueryOptions({...queryOptions, page: newPage});
    }, [queryOptions]);

    const onSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions({...queryOptions, sortColumn: sortParam.field, sortDirection: sortParam.sort});
        });
    }, [queryOptions]);

    const childMenuContent = (<RightMenu>
        <List component="nav">
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            {userHasPermission("Validation_Detail_Edit", appUser) &&
            <ListItemButton onClick={() => navigate('new')}>
                Create New Validation 
            </ListItemButton>
            }
            <ListItemButton onClick={() => setQueryOptions(CreateValidationSearchRequest(location, presetParams))}>
                Reset Filters
            </ListItemButton>                    
            <Divider/>
            <ListItem>
                <Typography variant="h6">Filters</Typography>
            </ListItem>

            <ListItem>
            <TypeAheadMulti
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, parentAccountUniqueIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, [8,14], null, null)}
                    value={queryOptions.parentAccountUniqueIDs}
                    label="Facilites By Parent"
                />
            </ListItem>

            <ListItem>
                <TypeAheadMulti 
                    onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, facilityIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, undefined, null, null)}
                    value={queryOptions.facilityIDs}
                    label="Facilities"
                />
            </ListItem>
            <ListItem>
                <ValidationModelDropDown
                    onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel|null) => setQueryOptions({...queryOptions, modelID: newValue, page: 0})}
                    value={queryOptions.modelID}
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel variant="standard">Status</InputLabel>
                    <Select
                        value={queryOptions.status ?? ''}
                        onChange={(event:SelectChangeEvent) =>  setQueryOptions({...queryOptions, status: event.target.value, page: 0})}
                        label="Status"
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem value="Any">Any</MenuItem>
                        <MenuItem value="Failed">Failed</MenuItem>
                        <MenuItem value="Passed">Passed</MenuItem>
                        <MenuItem value="Review Required">Review Required</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
            <ListItem>
                <TextField
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQueryOptions({...queryOptions, validatedBy: event.target.value, page: 0})}
                    value={queryOptions.validatedBy ?? ''}
                    label='Validated By'
                    fullWidth
                    variant="standard"
                />
            </ListItem>
        </List>
    </RightMenu>);


    return (
        <BaseLayout childMenu={childMenuContent}>
            
            <Grid sx={{display: "flex", height: "100%"}}>
                <DataGrid
                    columns={columns}
                    rows={rowData}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    paginationMode="server"
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: 'Validations',
                            gridConfigName: 'validationGridState',
                            defaultConfig: {
                                columns: {
                                    columnVisibilityModel: {
                                        year: false,
                                        dateCreated: false,
                                    },
                                }
                            }
                        }
                    }}
                    onRowClick={(params: GridRowParams) => {
                        navigate(`${params.id}`, {
                            state: {
                                fromPath: location.pathname,
                                componentState: queryOptions
                            }
                        });
                    }}
                />
            </Grid>
        </BaseLayout>
    );
}

export default ValidationList;