import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import React from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import TypeAhead from "../../components/formControls/TypeAheadBase.component";
import Loading from "../../components/loading/Loading.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { AssessmentMassUpdateModel, CreateDefaultAssessmentMassUpdateModel } from "./Assessment.models";
import { MassUpdateAssessments } from "./Assessment.service";
import { GetUserTypeAheadSearch } from "../../services/User.service";

type AssessmentMassUpdateProps = {
    show: boolean,
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void,
    selectedIDs: GridRowSelectionModel
};

const AssessmentMassUpdate = (props: AssessmentMassUpdateProps) => {
    const {appUser} = useApiAuthorization();
    const [massUpdateModel, setMassUpdateModel] = React.useState<AssessmentMassUpdateModel>(CreateDefaultAssessmentMassUpdateModel());
    const [saving, setSaving] = React.useState<boolean>(false);
    const isValid = !!massUpdateModel.assignedToUser;
    
    const massUpdate = () => {
        
        setSaving(true);
        MassUpdateAssessments({...massUpdateModel, surveyIDs: props.selectedIDs as number[]}, appUser?.token).then((request) =>{
            
        }).finally(() => {
            setSaving(false);
            handleClose();
        })
    };

    const handleClose = () => {
        setMassUpdateModel(CreateDefaultAssessmentMassUpdateModel());
        props.onClose();
    };

    return (
        <>
            {saving && <Loading />}
            {!saving &&
            <Dialog open={props.show} onClose={handleClose}>
                <DialogTitle>Mass Update</DialogTitle>
                <DialogContent>
                    <DialogContentText>Update the following properties for the rows selected</DialogContentText>
                    <TypeAhead 
                        label="Assigned To User"
                        value={massUpdateModel.assignedToUser}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setMassUpdateModel({...massUpdateModel, assignedToUser: newValue})}
                        searchCallback={(searchTerm?: string) => GetUserTypeAheadSearch(appUser?.token!, searchTerm, true)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={massUpdate} disabled={!isValid}>Save</Button>
                </DialogActions>
            </Dialog>
            }
        </>
    );
}

export default AssessmentMassUpdate;