import { ApiBaseRoute } from "../api_helper";
import { User } from "../models/User.model";

export function GetCurrentUser(token:string): Promise<Response> {
    return fetch(ApiBaseRoute+"users/currentuserinfo", {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export function GetUserTypeAheadSearch(token: string, searchTerm: string|undefined, internalOnly?:boolean): Promise<Response> {
    let uri = `${ApiBaseRoute}users/typeahead?searchTerm=${searchTerm ?? ''}&internalOnly=${internalOnly ?? false}`;
    return fetch(uri, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export function userHasPermission( permission:string, appUser?:User):boolean {
    return appUser?.permissions !== undefined && appUser?.permissions?.indexOf(permission) > -1;
};

export function FileUserSetting(settingID:number, moduleID:number, moduleTypeID: number, value:string, token:string, markDelete:boolean = false):Promise<Response> {
    return fetch(`${ApiBaseRoute}users/FileCurrentUserSetting`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            settingID: settingID,
            moduleID: moduleID,
            moduleTypeID: moduleTypeID,
            value: value,
            delete: markDelete
        })
    });
}