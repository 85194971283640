import { Box, Card, CardContent, Fab, FormControlLabel, FormLabel, Grid, List, ListItem, ListItemButton, Switch, TextField, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import Loading from "../../components/loading/Loading.component";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import { ReleaseNoteDetailModel, CreateDefaultReleaseNoteDetail } from "./ReleaseNote.models";
import { DeleteReleaseNote, GetReleaseNoteDetail, SaveReleaseNoteDetail } from "./ReleaseNote.service";
import DeleteDialog from "../../components/formComponents/DeleteDialog.component";
import { useIsDirtyPrompt } from "../../components/formComponents/useIsDirtyPrompt.component";
import GoBack from "../../components/rightMenuControls/GoBack.component";
import { userHasPermission } from "../../services/User.service";

const ReleaseNoteDetail = () => {
    const {appUser} = useApiAuthorization();
    const {releaseNoteID} = useParams();
    const [releaseNoteDetail, setReleaseNoteDetail] = React.useState<ReleaseNoteDetailModel>(CreateDefaultReleaseNoteDetail());
    const initialReleaseNoteDetail = React.useRef<ReleaseNoteDetailModel|null>(releaseNoteID === 'new' ? releaseNoteDetail : null);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const isDirty = useIsDirtyPrompt(initialReleaseNoteDetail.current, releaseNoteDetail);

    React.useEffect(() => {
        if(appUser && releaseNoteID !== "new"){
            setLoading(true);
            GetReleaseNoteDetail(releaseNoteID!, appUser.token)
                .then(response => response.json())
                .then(data => {
                    setReleaseNoteDetail(data);
                    initialReleaseNoteDetail.current = data;
                })
                .finally(() => setLoading(false));
        }
    }, [appUser, releaseNoteID]);

    const updateReleaseNote = () => {
        setSaving(true);

        if(releaseNoteID === 'new'){
            initialReleaseNoteDetail.current = releaseNoteDetail;
        }

        SaveReleaseNoteDetail(releaseNoteDetail, appUser?.token)
            .then((response) => {
                return response.json();
            }).then((data) => {
                if(releaseNoteID === 'new'){
                    navigate(`/releaseNotes/${data.releaseNoteID}`);
                }
                else{
                    setReleaseNoteDetail(data);
                    initialReleaseNoteDetail.current = data;
                }
            }).finally(() => {
                setSaving(false);
            });

    };

    const deleteReleaseNote = ()=>{
        setSaving(true);
        initialReleaseNoteDetail.current = releaseNoteDetail;
        DeleteReleaseNote(releaseNoteID!, appUser?.token).finally(() => {
            setSaving(false);
            navigate('/releasenotes');
        })
    };

    const childMenuContent = (<RightMenu>
        <List component='nav'>
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            <GoBack/>
        {userHasPermission("ReleaseNote_Detail_Delete", appUser) && (
            <ListItemButton onClick={() => setShowDeleteDialog(true)}>Delete</ListItemButton>
        )}
        </List>
    </RightMenu>);

    return (
        <BaseLayout childMenu={(releaseNoteID !== 'new' && userHasPermission("ReleaseNote_Detail_Delete", appUser)) ? childMenuContent : false} childMenuOpen={(releaseNoteID !== 'new')}>
            {(saving || loading) && <Loading />}

            {userHasPermission("ReleaseNote_Detail_Edit", appUser) &&
            <Box display="flex" justifyContent="flex-end">
                <Fab size="medium" color="primary" aria-label="save" variant="extended" onClick={updateReleaseNote} disabled={!isDirty}>
                    {releaseNoteID === 'new' ? 'Create' : 'Save'}
                </Fab>
            </Box>
            }
            <Typography variant="h6" gutterBottom>
                Release Note Information
            </Typography>
            <Grid container spacing={3} sx={{pb: 10}}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="releaseNoteID"
                        label="Release Note ID"
                        fullWidth
                        variant="standard"
                        value={releaseNoteDetail.releaseNoteID ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={!userHasPermission("ReleaseNote_Detail_Edit", appUser)}
                                checked={releaseNoteDetail.active}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setReleaseNoteDetail({...releaseNoteDetail, active: event.target.checked})}
                            />
                        }
                        label="Active"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="dateCreated"
                        label="Created Date"
                        fullWidth
                        variant="standard"
                        value={releaseNoteDetail.dateCreated ? moment(releaseNoteDetail.dateCreated).format("MM/DD/YYYY"): ""}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="createdBy"
                        label="Created By"
                        fullWidth
                        variant="standard"
                        value={releaseNoteDetail.createdByFullName ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="dateModified"
                        label="Modified Date"
                        fullWidth
                        variant="standard"
                        value={releaseNoteDetail.dateModified ? moment(releaseNoteDetail.dateModified).format("MM/DD/YYYY"): ""}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="modifiedBy"
                        label="Modified By"
                        fullWidth
                        variant="standard"
                        value={releaseNoteDetail.modifiedByFullName ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="title"
                        label="Title"
                        fullWidth
                        variant="standard"
                        value={releaseNoteDetail.title ?? ""}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            setReleaseNoteDetail({...releaseNoteDetail, title: event.target.value });
                        }}
                        required
                        disabled={!userHasPermission("ReleaseNote_Detail_Edit", appUser) }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="details"
                        label="Details"
                        fullWidth
                        multiline
                        required
                        variant="standard"
                        value={releaseNoteDetail.details ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            setReleaseNoteDetail({...releaseNoteDetail, details: event.target.value });
                        }}
                        disabled={!userHasPermission("ReleaseNote_Detail_Edit", appUser) }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Preview</FormLabel>
                    <Card>
                        <CardContent>
                            <Typography variant='h5'>
                                {releaseNoteDetail.title}
                            </Typography>
                            <Typography component='div' dangerouslySetInnerHTML={{__html:releaseNoteDetail.details}} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <DeleteDialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={deleteReleaseNote}
                title="Please confirm you want to delete release note"
                content={`${releaseNoteDetail.title}\nRelease Note ID: ${releaseNoteID}`}
            />
        </BaseLayout>
    );
};

export default ReleaseNoteDetail;