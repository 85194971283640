import BaseLayout from "../../layout/BaseLayout.component";
import { DataGridPro as DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridSortModel } from '@mui/x-data-grid-pro';
import { GetUserAccountListColumns, SearchUserAccounts } from "./UserAccountList.service";
import React from "react";
import { CreateUserAccountListRequest, UserAccountSearchRequest } from "./UserAccount.models";
import { forEach } from "lodash";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, FormControl, Grid, InputLabel, List, ListItem, ListItemButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import RightMenu from "../../layout/RightMenu.component";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import { GetRoles } from "../Roles/UserRoleList.service";
import { GetUserTypeAheadSearch, userHasPermission } from "../../services/User.service";
import { GetAssessmentTypeAheadSearch } from "../../services/Assessment.service";

function UserAccountList(){
    const {appUser} = useApiAuthorization();
    const columns: GridColDef[] = GetUserAccountListColumns();
    const navigate = useNavigate();
    const location = useLocation();
    const [queryOptions, setQueryOptions] = React.useState<UserAccountSearchRequest>(CreateUserAccountListRequest(location));
    const [rowData, setRowData] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    

    React.useEffect(() => {
        if(appUser?.token){
            setIsLoading(true);
            SearchUserAccounts(queryOptions, appUser.token)
            .then((response) => response.json())
            .then((data) => {
                setRowData(data);
                if(data && data.length > 0){
                    setRowCount(data[0].totalCount);
                } else {
                    setRowCount(0);
                }
            }).finally(() => setIsLoading(false));
        }
    }, [queryOptions, appUser?.token]); //only run useEffect if the queryOptions change

    const onPageChange = React.useCallback((newPage: number) => {
        setQueryOptions({...queryOptions, page: newPage});
    }, [queryOptions]);


    const onSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions({...queryOptions, sortColumn: sortParam.field, sortDirection: sortParam.sort});
        });
    }, [queryOptions]);

    const createUser = () => {
        navigate("0");
    };

    const childMenuContent = (<RightMenu>
        <List component="nav">
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            {userHasPermission("UserAccount_Detail_Edit", appUser) &&
            <ListItemButton onClick={createUser}>
                Create New User 
            </ListItemButton>
            }
            <ListItemButton onClick={() => setQueryOptions(CreateUserAccountListRequest(location))}>
                Reset Filters
            </ListItemButton>
            <Divider/>
            <ListItem>
                <Typography variant="h6">Filters</Typography>
            </ListItem>
            <ListItem>
                <TypeAheadMulti 
                    onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, userIDs: newValue})}
                    searchCallback={(searchTerm?: string) => GetUserTypeAheadSearch(appUser?.token!, searchTerm, false)}
                    value={queryOptions.userIDs}
                    label="Users"
                />
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, userRoleIDs: newValue})}
                    searchCallback={(searchTerm?: string) => GetRoles(appUser?.token!)}
                    value={queryOptions.userRoleIDs}
                    label="User Roles"
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel variant="standard">Is Internal</InputLabel>
                    <Select
                        value={queryOptions.isInternalText ?? ''}
                        onChange={(event:SelectChangeEvent) =>  setQueryOptions({...queryOptions, isInternalText: event.target.value, isInternal: event.target.value === "Any" ? undefined : event.target.value === "Yes" })}
                        label="Is Internal"
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem value="Any">Any</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, surveyIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetAssessmentTypeAheadSearch(appUser?.token!, searchTerm)}
                    value={queryOptions.surveyIDs}
                    label="Assessments"
                />
            </ListItem>
        </List>
    </RightMenu>);

    return (
        <BaseLayout childMenu={childMenuContent}>
            
            <Grid sx={{display: "flex", height: "100%"}}>
                <DataGrid
                    columns={columns}
                    rows={rowData}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    paginationMode="server"
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: 'UserAccounts',
                            gridConfigName: 'userGridState',
                            defaultConfig: {
                                columns:{
                                    columnVisibilityModel: {
                                        auth0ID: false
                                    },
                                }
                            }
                        }
                    }}
                    onRowClick={(params: GridRowParams) => {
                        navigate(`${params.id}`, {
                            state: {
                                fromPath: location.pathname,
                                componentState: queryOptions
                            }
                        });
                    }}
                />
            </Grid>
        </BaseLayout>
    );
}

export default UserAccountList;