import { Location } from "react-router-dom";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";
import { SearchResponseBase } from "../../models/SearchResponseBase.model";
import NumericDropDownModel from "../../models/NumericDropDown.model";

export interface DocumentSearchRequest extends SearchRequestBase {
    assessmentIDs: NumericDropDownModel[],
	facilityIDs: NumericDropDownModel[],
    isCurrent: boolean,
};

export interface DocumentSearchResponse extends SearchResponseBase {
    documentID: number,
    s3BucketName: string,
    originalFileName: string,
    createdByUserID: number,
    createdByFullName: string,
    s3Link: string,
    createdDate: Date,
	uniqueID: number,
    dateCreated: Date,
    questionText: string,
};

export const CreateDefaultDocumentSearchRequest = (presetParams: URLSearchParams|undefined, location: Location|undefined):DocumentSearchRequest => {
    if (location && location.state?.componentState) {
        return location.state.componentState;
    }

    return {
        assessmentIDs: [],
        facilityIDs: [],
        page: 0,
        pageSize: 100,
        sortColumn: "datecreated",
        sortDirection: "desc",
        isCurrent: true,
    }
};

export interface DocumentDetailModel {
    documentID: number,
    s3BucketName: string,
    originalFileName: string,
    createdByUserID: number|null,
    createdByFullName: string,
    s3Link: string,
    createdDate: Date|null,
	uniqueID: number|null,
    surveyType: string|null,
	orgName: string|null,
    surveys: NumericDropDownModel[],
};

export const CreateDefaultDocumentDetail = ():DocumentDetailModel => {
    return {
        documentID: 0,
        s3BucketName: '',
        originalFileName: '',
        createdByUserID: null,
        createdByFullName: '',
        s3Link: '',
        createdDate: null,
        uniqueID: null,
        surveyType: null,
	    orgName: null,
        surveys: [],
    }
};

export interface DocumentDetailRequestModel {
    documentID: number,
    assessmentID: number|null,
    facilityID: number|null,
}
