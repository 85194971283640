import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, Switch } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import React from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import TypeAhead from "../../components/formControls/TypeAheadBase.component";
import Loading from "../../components/loading/Loading.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { AccountMassUpdateModel, CreateDefaultAccountMassUpdateModel } from "./Account.models";
import { MassUpdateAccounts } from "./Account.service";
import { GetFacilityTypeAheadSearch } from "../../services/FacilityTypeAhead.service";

interface AccountMassUpdateProps {
    open: boolean,
    onClose: () => void,
    selectedIDs: GridRowSelectionModel
}

const AccountMassUpdate = (props: AccountMassUpdateProps) => {
    const {appUser} = useApiAuthorization();
    const [massUpdateModel, setMassUpdateModel] = React.useState<AccountMassUpdateModel>(CreateDefaultAccountMassUpdateModel());
    const [saving, setSaving] = React.useState<boolean>(false);
    const isValid = !!massUpdateModel.network || !!massUpdateModel.parentFacility;
    
    const handleClose = React.useCallback(() => {
        setMassUpdateModel(CreateDefaultAccountMassUpdateModel());
        props.onClose();
    }, [props]);
    
    const massUpdate = React.useCallback(() => {
        setSaving(true);
        MassUpdateAccounts({...massUpdateModel, uniqueIDs: props.selectedIDs as number[]}, appUser?.token).then((request) =>{
            if(request.status === 200){
                handleClose();
            }
        }).finally(() => {
            setSaving(false);
        })
    }, [massUpdateModel, props.selectedIDs, appUser?.token, handleClose]);

    

    return (
        <>
            {saving && <Loading />}
            {!saving && 
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>Mass Update</DialogTitle>
                <DialogContent>
                    <DialogContentText>Update the following properties for the rows selected. </DialogContentText>
                    <TypeAhead
                        label="Network"
                        value={massUpdateModel.network}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setMassUpdateModel({...massUpdateModel, network: newValue})}
                        searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, [14], null, null)}
                    />
                    <Grid item xs={12}>
                        <FormControlLabel 
                            label="Remove this Network from the rows selected?"
                            control={
                                <Switch
                                    checked={massUpdateModel.removeNetworkFromFacilities}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setMassUpdateModel({...massUpdateModel, removeNetworkFromFacilities: checked})}
                                    disabled={!massUpdateModel.network}
                                />
                            }
                        />
                    </Grid>
                    <Divider/>
                    <TypeAhead
                        label="Move to Parent Facility"
                        value={massUpdateModel.parentFacility}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setMassUpdateModel({...massUpdateModel, parentFacility: newValue})}
                        searchCallback={(searchTerm?:string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, undefined, undefined, [8],null,null)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={massUpdate} disabled={!isValid}>Save</Button>
                </DialogActions>
            </Dialog>
            }
        </>
    );
};

export default AccountMassUpdate;