import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { ApiBaseRoute } from "../../api_helper";
import { UserAccountSearchRequest } from "./UserAccount.models";

export const GetUserAccountListColumns = ():GridColDef[] => {
    return [
        { field: "id", headerName: "User ID", flex: .4, filterable: false },
        { field: "userName", headerName: "User Name", flex: 1, filterable: false },
        { field: "auth0ID", headerName: "Auth0 ID", flex: 1, filterable: false},
        { field: "fullName", headerName: "Full Name", flex: 1, filterable: false},
        { field: "isInternal", headerName: "Is Internal?", flex: .5, align: "center", filterable: false, headerAlign: "center", renderCell: (cellValues) => {
            return cellValues.value ? <CheckIcon></CheckIcon> : <CloseIcon></CloseIcon>
        }},
        {
            field: "dateCreated",
            headerName: "Created Date",
            flex: .6,
            align: "center",
            filterable: false,
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        },
        {
            field: "dateModified",
            headerName: "Modified Date",
            flex: .6,
            align: "center",
            filterable: false,
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        }
    ]
};

export const SearchUserAccounts = (request: UserAccountSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {...request};
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "Users/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            ...requestCopy,
            surveyIDs: requestCopy.surveyIDs.map(x => x.value),
            userIDs: requestCopy.userIDs.map(x => x.value),
            userRoleIDs: requestCopy.userRoleIDs.map(x => x.value)
        })
    });
};
