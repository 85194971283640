import { DataGridPro, GridActionsCellItem, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridValidRowModel } from "@mui/x-data-grid-pro";
import { AccountDetailModel, ExternalIDMapping } from "./Account.models";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";

export interface AccountDetailsExternalMappingProps{
    accountDetail: AccountDetailModel,
    setRows: (newRows:GridValidRowModel[]) => void

}

const AccountDetailsExternalMapping = (props: AccountDetailsExternalMappingProps) => {
    const [rows, setRows] = useState(props.accountDetail.externalMappingIDs ?? []);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    useEffect(() => {
      setRows(props.accountDetail.externalMappingIDs);
    }, [props.accountDetail.externalMappingIDs]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
          event.defaultMuiPrevented = true;
        }
      };
    
    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: "externalID" } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        props.setRows(rows.filter((row) => row.externalIDMappingID !== id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.externalIDMappingID === id);
        if (editedRow!.externalIDMappingID < 0) {
            setRows(rows.filter((row) => row.externalIDMappingID !== id));
        }
    };
    
    const processRowUpdate = (newRow: GridRowModel) => {
      props.setRows(rows.map((row) => (row.externalIDMappingID === newRow.externalIDMappingID ? newRow as ExternalIDMapping : row)));
      return newRow;
    };
  
    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };
    return (
        <DataGridPro
            rows={rows}
            editMode="row"
            rowSelection={false}
            autoHeight
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            getRowId={(row) => row.externalIDMappingID}
            initialState={{
                columns:{
                    columnVisibilityModel: {
                        externalMappingID: false
                    }
                }
            }}
            columns={[{
                field: "externalMappingID"   
            },{
              field: "externalSource",
              headerName: "Source",
              flex: 1
            },{
              field: "externalID",
              headerName: "ID",
              editable: true,
              flex: 1
            },{
              field: 'actions',
              type: 'actions',
              headerName: 'Actions',
              width: 100,
              cellClassName: 'actions',
              getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        
                if (isInEditMode) {
                  return [
                    <GridActionsCellItem
                      icon={<SaveIcon />}
                      label="Save"
                      sx={{
                        color: 'primary.main',
                      }}
                      onClick={handleSaveClick(id)}
                    />,
                    <GridActionsCellItem
                      icon={<CancelIcon />}
                      label="Cancel"
                      className="textPrimary"
                      onClick={handleCancelClick(id)}
                      color="inherit"
                    />,
                  ];
                }
        
                return [
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                  />,
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                  />,
                ];
              },
            },]}
        />
    );
}

export default AccountDetailsExternalMapping;