import Forbidden from "../../pages/Forbidden/Forbidden.component";
import Loading from "../loading/Loading.component";
import { useApiAuthorization } from "./ApiAuthorizationProvider.component";
import { useAuth0 } from "@auth0/auth0-react";


function PrivateRoute({children, accessToken}:{children:JSX.Element, accessToken?: string}) {
    const {appUser, isLoading, isAuthenticated} = useApiAuthorization();
    const { loginWithRedirect } = useAuth0();
    if(!appUser && (isLoading || isAuthenticated)){
        return <Loading/>;
    }

    if(!appUser && !isLoading && !isAuthenticated){
        loginWithRedirect();
    }

    if(accessToken && appUser && appUser.permissions?.indexOf(accessToken) === -1){
        return <Forbidden/>;
    }

    return children;
};

export default PrivateRoute;