import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React from "react";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { GetLanguageOptions } from "../../services/Language.service";
import { useApiAuthorization } from "../authorization/ApiAuthorizationProvider.component";

export interface LanguageDropDownMultiProps {
    onChange(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]):void,
    value: NumericDropDownModel[],
    disabled?: boolean,
    label?: string,
    required?: boolean,
    surveyTypeID: number|null,
    error?: boolean,
    helperText?: string,
    isSurveyTypeAgnostic: boolean,
}

const LanguageDropDownMulti = (props: LanguageDropDownMultiProps) => {
    const {appUser} = useApiAuthorization();
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<NumericDropDownModel[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const isSurveyTypeAgnostic = React.useState<boolean>(props.isSurveyTypeAgnostic);

    React.useEffect(() => {
        let active = true;

        if (active && (props.surveyTypeID || isSurveyTypeAgnostic) && open) {
            setLoading(true)
            GetLanguageOptions(props.surveyTypeID, appUser?.token!)
            .then((response) => response.json())
            .then((data) => {
                if(props.value){
                    //data = [props.value, ...data.filter((option:NumericDropDownModel[]) => option.value !== props.value?.value)]
                    data = props.value.concat(data).filter((option:NumericDropDownModel, index:number, self:NumericDropDownModel[]) => {
                        return self.map((item:NumericDropDownModel) => item.value).indexOf(option.value) === index;
                    });
                }
                setOptions(data);
            }).finally(() => setLoading(false));
        }

        return () => {
            active = false;
        };
    }, [appUser?.token, props.value, props.surveyTypeID, isSurveyTypeAgnostic, open]);
    
    return (
        <Autocomplete
            fullWidth
            multiple
            disabled={props.disabled}
            onChange={props.onChange}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={props.value}
            getOptionLabel={(option) => option.label}
            options={options}
            loading={loading}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.value}>{option.label}</li>
                );
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => (
                <TextField
                {...params}
                label={props.label ?? "Language"}
                variant="standard"
                required={props.required}
                error={props.error}
                helperText={props.helperText}
                InputProps={{
                    ...params.InputProps,                    
                    endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                    ),
                }}
                />
            )}
        />
    );
};

export default LanguageDropDownMulti;