import { useCallback, useState } from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import Loading from "../../components/loading/Loading.component";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, TextField } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DownloadAccountImportTemplate, UploadAccounts } from "./Imports.service";

interface AccountImportDialogProps {
    open: boolean,
    onClose: () => void,
}
const AccountImportDialog = (props: AccountImportDialogProps) => {
    const [saving, setSaving] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const {appUser} = useApiAuthorization();
    const [projectName, setProjectName] = useState<string>();
    const [fileUpload, setFileUpload] = useState<File|null>();

    const handleClose = useCallback(() => {
        setProjectName(undefined);
        props.onClose();
    }, [props]);

    const handleConfirm = useCallback(() => {
        setSaving(true);
        UploadAccounts(fileUpload!, appUser?.token, projectName!).then((value:Response) => {
            if(value.status === 200){
                handleClose();
            }
        }).finally(() => {
            setSaving(false);
        })
    }, [appUser?.token, handleClose, fileUpload, projectName]);
    
    const downloadTemplate = useCallback(() => {
        setLoading(true);
        DownloadAccountImportTemplate(appUser?.token).then(response => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response.blob();
        }).then(blob => {
            if(blob != null)
            {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "ImportAccountTemplate.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        }).finally(() => setLoading(false));
    },[appUser?.token]);

    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = event.currentTarget.files && event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
        setFileUpload(newValue);
    }, []);

    return (
        <>
            {(saving||loading) && <Loading/>}
            {!loading && !saving && 
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>Import Accounts</DialogTitle>
                <DialogContent>
                    <DialogContentText>Download the import account template <Link onClick={downloadTemplate}>file</Link> to ensure correct formatting for the upload to be processed.</DialogContentText>
                    <Grid item xs={12}>
                        <TextField 
                            value={projectName} 
                            label={"Project Name"} 
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setProjectName(event.target.value)}
                            required
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sx={{mt:2}}>
                        <Button 
                            component="label"
                            variant='contained'
                            startIcon={<CloudUploadIcon/>}
                            href='#file-upload'
                            fullWidth
                        >
                            {!!fileUpload && fileUpload.name}
                            {!fileUpload && 'Select a file'}
                            <input 
                                type='file' 
                                style={{display: "none"}}
                                onChange={handleFileChange}
                                accept='.xlsx'
                            />
                        </Button>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleConfirm} disabled={!projectName || !fileUpload}>Import</Button>
                </DialogActions>
            </Dialog>
            }
        </>
    );
};

export default AccountImportDialog;