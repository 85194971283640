import { Accordion, AccordionSummary, AccordionDetails, Box, Fab, FormControl, Grid, InputLabel, List, ListItem, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import Loading from "../../components/loading/Loading.component";
import GoBack from "../../components/rightMenuControls/GoBack.component";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { EmailTemplateDetailModel, CreateDefaultEmailTemplateDetail } from "./EmailTemplate.models";
import { GetEmailTemplateDetail, FileEmailTemplate } from "./EmailTemplateDetail.service";
import LanguageDropDown from "../../components/formControls/LanguageDropDown.component";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import { GetAssessmentTypeTypeAheadSearch } from "../../services/AssessmentType.service";
import { useIsDirtyPrompt } from "../../components/formComponents/useIsDirtyPrompt.component";
import { userHasPermission } from "../../services/User.service";

const EmailTemplateDetail = () => {
    const {appUser} = useApiAuthorization();
    const {emailTemplateID} = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [emailTemplateDetail, setEmailTemplateDetail] = useState<EmailTemplateDetailModel>(CreateDefaultEmailTemplateDetail());
    const initialEmailTemplateDetail = useRef<EmailTemplateDetailModel|null>(null);
    const [saving, setSaving] = useState<boolean>(false);
    const userCanEdit = userHasPermission("EmailTemplate_Detail_Edit", appUser);
    const isDirty = useIsDirtyPrompt(initialEmailTemplateDetail.current, emailTemplateDetail);

    useEffect(() => {
        let emailTemplateIDNumber = parseInt(emailTemplateID!);
        if(isNaN(emailTemplateIDNumber) === false){
            setLoading(true);
            GetEmailTemplateDetail(emailTemplateIDNumber, appUser!.token)
                .then((response) => response.json())
                .then((data) => {
                    setEmailTemplateDetail(data);
                    initialEmailTemplateDetail.current = data;
                }).finally(() => setLoading(false));
        }
    }, [appUser, emailTemplateID]);

    const updateEmailTemplate = () => {
        setSaving(true);
        FileEmailTemplate(appUser!.token, emailTemplateDetail)
        .then((response) => response.json())
        .then((data) => {
            setEmailTemplateDetail(data);
            initialEmailTemplateDetail.current = data;
        }).finally(() => setSaving(false));
    };

    const childMenuContent = (<RightMenu>
        <List component="nav">
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            <GoBack />
        </List>
    </RightMenu>);

    return (
        <BaseLayout childMenu={childMenuContent}>
            {(saving || loading) && <Loading />}
            
            {userCanEdit &&
                <Box display="flex" justifyContent="flex-end">
                    <Fab size="medium" color="primary" aria-label="save" variant="extended" onClick={updateEmailTemplate} disabled={!isDirty}>
                        Save 
                    </Fab>
                </Box>
            }
            <Typography variant="h6" gutterBottom>
                Email Template Details
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="Name"
                        label="Name"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmailTemplateDetail({...emailTemplateDetail, name: event.target.value})}
                        fullWidth
                        variant="standard"
                        value={emailTemplateDetail.name}
                        disabled={!userCanEdit}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="FileName"
                        label="File Name"
                        fullWidth
                        variant="standard"
                        value={emailTemplateDetail.fileName}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeAheadMulti
                        disabled={!userCanEdit}
                        value={emailTemplateDetail.surveyTypes}
                        onChange={(event: React.SyntheticEvent, newValue: NumericDropDownModel[]) => setEmailTemplateDetail({...emailTemplateDetail, surveyTypes: newValue})}
                        searchCallback={(searchTerm?: string) => GetAssessmentTypeTypeAheadSearch(appUser?.token!, searchTerm)}
                        label="Assessment Types"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard">Email Type</InputLabel>
                        <Select
                            value={emailTemplateDetail.type}
                            multiple={false}
                            onChange={(event: SelectChangeEvent<string>) => setEmailTemplateDetail({...emailTemplateDetail, type: event.target.value})}
                            label="Email Type"
                            fullWidth
                            variant="standard"
                            disabled={!userCanEdit}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value="Authorization">Authorization</MenuItem>
                            <MenuItem value="Completion">Completion</MenuItem>
                            <MenuItem value="Completion DHTP">Completion DHTP</MenuItem>
                            <MenuItem value="Delegate">Delegate</MenuItem>
                            <MenuItem value="In Review">In Review</MenuItem>
                            <MenuItem value="Reminder">Reminder</MenuItem>
                            <MenuItem value="Test">Test</MenuItem>
                            <MenuItem value="Welcome">Welcome</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LanguageDropDown
                        value={emailTemplateDetail.language}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setEmailTemplateDetail({...emailTemplateDetail, language: newValue})}
                        surveyTypeID={null}
                        isSurveyTypeAgnostic={true}
                        required
                        disabled={!userCanEdit}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panelemail-content"
                            id="panelemail-header"
                        >
                            <Typography>Email Template Preview</Typography>
                        </AccordionSummary>
                        <AccordionDetails onClick={(e) => {e.preventDefault()}}>
                            <Typography dangerouslySetInnerHTML={{ __html: emailTemplateDetail.content }} />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </BaseLayout>
    );
};

export default EmailTemplateDetail;