import { Location } from "react-router-dom";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";
import { SearchResponseBase } from "../../models/SearchResponseBase.model";
import { User } from "../../models/User.model";

export interface AccountSearchResponse extends SearchResponseBase {
    id: number,
    accountType: string,
    name: string,
    uniqueID: number,
    assignedToID?: number,
    assignedToEmail: string,
    street: string,
    state: string,
    country: string,
    lastCompletedSurveyDate?: Date,
    dateModified?: Date,
    testOrg?: boolean,
    externalIDList: string
};

export interface AccountSearchRequest extends SearchRequestBase {
    parentAccountUniqueIDs: NumericDropDownModel[],
    facilityIDs: NumericDropDownModel[],
    countryIDs: NumericDropDownModel[],
    stateIDs: NumericDropDownModel[],
    assignedToUserIDs: NumericDropDownModel[],
    facilityTypeIDs: NumericDropDownModel[]
};

export const CreateDefaultAccountSearchRequest = (defaultParams:URLSearchParams, location?: Location, appUser?: User):AccountSearchRequest => {
    let parentUniqueID = defaultParams.get("parentAccountUniqueID");
    let parentName = defaultParams.get("parentAccountName");
    if(location && location.state?.componentState){
        return location.state.componentState;
    }
    return {
        parentAccountUniqueIDs: parentUniqueID && parentName ? [{label: parentName!, value: parseInt(parentUniqueID!)}] : [],
        facilityIDs: [],
        countryIDs: [],
        stateIDs: [],
        assignedToUserIDs: !parentUniqueID && !!appUser ? [{label: appUser?.email, value: appUser.userID}] : [],
        facilityTypeIDs: [],
        page: 0,
        pageSize: 100,
        sortColumn: "name",
        sortDirection: "asc"
    }
    
};

export interface AccountDetailModel {
    uniqueID: number,
    name: string,
    facilityType: NumericDropDownModel|null,
    primaryService: string|null,
    numberOfBeds: string,
    address1: string,
    address2: string,
    city: string,
    postalCode: string,
    country: NumericDropDownModel|null,
    geographicalRegion: NumericDropDownModel|null,
    phone: string,
    assignedToUser: NumericDropDownModel|null,
    dateModified: Date|null,
    modifiedByUserID: number|null,
    modifiedByUserName: string,
    doNotContact: boolean|null,
    testOrg: boolean|null,
    topHealthSystem: boolean|null,
    website: string,
    emailConvention: string,
    lastCreatedSurveyDate: Date|null,
    lastCompletedSurveyDate: Date|null,
    isIndependent: boolean|null,
    parentAccount: NumericDropDownModel|null,
    networkAccounts: NumericDropDownModel[],
    typeOfHealthCareSetting: NumericDropDownModel|null,
    isUniversity: boolean|null,
    reportModels: string[],
    region: string,
    hasChildren: boolean,
    externalMappingIDs: ExternalIDMapping[]
};

export const ModelIsValid = (model:AccountDetailModel):Boolean => {
    let baseIsValid = !!model.name &&
    !!model.assignedToUser &&
    (model.facilityType?.value === 14 || !!model.phone) &&
    !!model.facilityType &&
    ((model.facilityType?.value === 14 || model.facilityType?.value === 8) || !!model.parentAccount) &&
    (model.facilityType?.value === 14 || !!model.address1) &&
    (model.facilityType?.value === 14 || !!model.city) &&
    (model.facilityType?.value === 14 || !!model.postalCode) &&
    (model.facilityType?.value === 14 || !!model.geographicalRegion) &&
    !!model.country;

    return baseIsValid;
}

export const CreateDefaultAccountDetail = (location?: Location, appUser?: User):AccountDetailModel => {
    if(location && location?.state && location?.state.componentState){
        // return location?.state.componentState as AccountDetailModel;
    }
    return {
        uniqueID: 0,
        name: '',
        facilityType: null,
        primaryService: null,
        numberOfBeds: '',
        address1: '',
        address2: '',
        city: '',
        postalCode: '',
        country: null,
        geographicalRegion: null,
        phone: '',
        assignedToUser: appUser ? {label: appUser!.email, value: appUser!.userID} : null,
        dateModified: null,
        modifiedByUserID: null,
        modifiedByUserName: '',
        doNotContact: null,
        testOrg: null,
        topHealthSystem: null,
        website: '',
        emailConvention: '',
        lastCreatedSurveyDate: null,
        lastCompletedSurveyDate: null,
        isIndependent: null,
        typeOfHealthCareSetting: null,
        parentAccount: null,
        networkAccounts: [],
        isUniversity: false,
        reportModels: [],
        region: '',
        hasChildren: false,
        externalMappingIDs: []
    }
};

export interface OrganizationMerger {
    toUniqueID: NumericDropDownModel|null,
    fromUniqueID: number
};

export interface AccountMassUpdateModel {
    uniqueIDs: number[],
    network: NumericDropDownModel|null,
    removeNetworkFromFacilities: boolean,
    parentFacility: NumericDropDownModel|null
};

export const CreateDefaultAccountMassUpdateModel = ():AccountMassUpdateModel => {
    return {
        uniqueIDs: [],
        network: null,
        removeNetworkFromFacilities: false,
        parentFacility: null
    };
};

export interface AccountCreateAssessmentModel {
    assessmentType: NumericDropDownModel|null
};

export const CreateDefaultAccountCreateAssessmentModel = ():AccountCreateAssessmentModel => {
    return {
        assessmentType: null
    };
};

export interface ExternalIDMapping {
    externalIDMappingID: number,
    externalName: string,
    externalID: string,
    externalSource: string
}