import { useAuth0 } from "@auth0/auth0-react";
import { ContactPage, Face, Login, Logout } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { theme } from "./BaseLayout.component";
import React from "react";
import { Link } from "react-router-dom";
import { useApiAuthorization } from "../components/authorization/ApiAuthorizationProvider.component";

const UserProfileAppBar = () => {
    const {
        isAuthenticated,
        loginWithRedirect,
        logout
    } = useAuth0();
    const {appUser} = useApiAuthorization();
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event:any) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }

    return (
        <>
            {isAuthenticated &&
                <Box sx={{ 
                    flexGrow: 0,
                    [theme.breakpoints.down('md')]: {
                        display: "none",
                    },
                    }}>
                    <Typography
                        textAlign="left"
                        paddingRight={2}
                    >
                        Welcome {appUser?.fullName}!
                    </Typography>
                </Box>
            }
            <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Account settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Face sx={{ color: '#fff' }} />
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    { isAuthenticated
                        ? <div>
                            <MenuItem
                                key='userprofile'
                                onClick={handleCloseUserMenu}
                                component={Link}
                                to='/userprofile'
                            >
                                <ContactPage />
                                <Typography
                                    textAlign="center"
                                    paddingLeft={2}
                                >
                                    User Profile
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                key='logout'
                                onClick={() => {
                                    logout({ 
                                        logoutParams: {
                                            returnTo: window.location.origin
                                        }
                                    });
                                }}
                            >
                                <Logout />
                                <Typography
                                    textAlign="center"
                                    paddingLeft={2}
                                >
                                    Logout
                                </Typography>
                            </MenuItem>
                        </div>
                        : <MenuItem onClick={() => loginWithRedirect()}>
                            <Login />
                            <Typography
                                textAlign="center"
                                paddingLeft={2}
                            >
                                Login
                            </Typography>
                        </MenuItem>
                    }
                </Menu>
            </Box>
        </>
    );
}

export default UserProfileAppBar;