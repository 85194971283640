import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import React from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import TypeAhead from "../../components/formControls/TypeAheadBase.component";
import Loading from "../../components/loading/Loading.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { CaseMassUpdateModel, CreateDefaultMassUpdateModel } from "./Case.models";
import { MassUpdateCases } from "./CaseList.service";
import { GetUserTypeAheadSearch } from "../../services/User.service";

type CaseMassUpdateProps = {
    show: boolean,
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void,
    selectedIDs: GridRowSelectionModel
};

const CaseMassUpdate = (props: CaseMassUpdateProps) => {
    const {appUser} = useApiAuthorization();
    const [massUpdateModel, setMassUpdateModel] = React.useState<CaseMassUpdateModel>(CreateDefaultMassUpdateModel());
    const [saving, setSaving] = React.useState<boolean>(false);
    const isValid = !!massUpdateModel.status || !!massUpdateModel.assignedToUser;
    
    const massUpdate = () => {
        
        setSaving(true);
        MassUpdateCases({...massUpdateModel, caseIDs: props.selectedIDs as number[]}, appUser?.token).then((request) =>{
            
        }).finally(() => {
            setSaving(false);
            handleClose();
        })
    };

    const handleClose = () => {
        setMassUpdateModel(CreateDefaultMassUpdateModel());
        props.onClose();
    };

    return (
        <>
            {saving && <Loading />}
            <Dialog open={props.show} onClose={handleClose}>
                
                <DialogTitle>Mass Update</DialogTitle>
                <DialogContent>
                    <DialogContentText>Update the following properties for the cases selected</DialogContentText>
                    <FormControl fullWidth>
                        <InputLabel variant="standard">Status</InputLabel>
                        <Select
                            value={massUpdateModel.status}
                            onChange={(event:SelectChangeEvent) => setMassUpdateModel({...massUpdateModel, status: event.target.value})}
                            label="Status"
                            fullWidth
                            variant="standard"
                        >
                            <MenuItem value="New">New</MenuItem>
                            <MenuItem value="Closed">Closed</MenuItem>
                        </Select>
                    </FormControl>
                    <TypeAhead 
                        label="Assigned To User"
                        value={massUpdateModel.assignedToUser}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setMassUpdateModel({...massUpdateModel, assignedToUser: newValue})}
                        searchCallback={(searchTerm?: string) => GetUserTypeAheadSearch(appUser?.token!, searchTerm, true)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={massUpdate} disabled={!isValid}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CaseMassUpdate;