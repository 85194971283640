import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { ApiBaseRoute } from "../../api_helper";
import { ValidationCheckModel, ValidationDetailModel, ValidationSearchRequest } from "./Validation.models";

export const GetValidationListColumns = ():GridColDef[] => {
    return [
        // { field: "id", headerName: "Validation ID", flex: .4, filterable: false },
        { field: "modelName", headerName: "Model Name", flex: .5, filterable: false },
        { field: "validationName", headerName: "Name", flex: 1, filterable: false},
        { field: "validationStage", headerName: "Stage", flex: .3, filterable: false},
        { field: "validationStatus", headerName: "Status", flex: .5, filterable: false},
        { field: "year", headerName: "Year", flex: .6, filterable: false, sortable: false},
        {
            field: "validationDate",
            headerName: "Validation Date",
            flex: .6,
            filterable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        },
        {
            field: "validationExpirationDate",
            headerName: "Expiration Date",
            flex: .6,
            filterable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        },
        { field: "validationBy", headerName: "Validated By", flex: .6, filterable: false},
        {
            field: "dateCreated",
            headerName: "Date Created",
            flex: .6,
            filterable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        },
    ]
};

export const SearchValidations = (request: ValidationSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {...request};
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "Validations/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({
            ...requestCopy,
            facilityIDs: requestCopy.facilityIDs.map(x => x.value),
            modelID: requestCopy.modelID ? requestCopy.modelID.value : undefined,
            status: requestCopy.status === "Any" ? undefined : requestCopy.status,
            parentAccountUniqueIDs: requestCopy.parentAccountUniqueIDs.map(x => x.value)
        })
    });
};

export const GetValidationModels = (token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "Validations/Models", {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const GetValidation = (token: string|undefined, validationID: string): Promise<Response> => {
    return fetch(ApiBaseRoute + `Validations/Detail?validationID=${validationID}`, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const CheckValidation = (token: string|undefined, model: ValidationCheckModel): Promise<Response> => {
    return fetch(ApiBaseRoute + "Validations/CheckValidation", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(model)
    });
};

export const SaveValidation = (token: string|undefined, model: ValidationDetailModel): Promise<Response> => {
    return fetch(ApiBaseRoute + "Validations/Save", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(model)
    });
};

export const DeleteValidation = (token: string|undefined, validationID: string) => {
    return fetch(ApiBaseRoute + `Validations/Delete?validationID=${validationID}`, {
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
    });
};