export interface MapModel {
    country: string,
    countryCode: string,
    countryID: number,
    geographicalRegion: string,
    geographicalRegionID: number,
    stateProvinceCode: string
}

export interface PublishedScoresDataset extends MapModel {
    modelName: string,
    stage0Facilities: number,
    stage1Facilities: number,
    stage2Facilities: number,
    stage3Facilities: number,
    stage4Facilities: number,
    stage5Facilities: number,
    stage6Facilities: number,
    stage7Facilities: number

}
export interface PublishedScoresDataVisualization  {
    geographicDistributions: PublishedScoresDataset[],
    modelDistributions: PublishedScoresModelDistributionModel [],
    stageDistributions: PublishedScoresStageDistributionModel []
};
export const CreateDefaultPublishedScoresDataVisualization = (): PublishedScoresDataVisualization => {

    return {
        geographicDistributions: [], 
        modelDistributions: CreateDefaultModelDistributionModel(),
        stageDistributions: CreateDefaultStageDistributionModel(),
    }
};
export interface PublishedScoresModelDistributionModel {
    AMAM: number,
    CCOMM: number,
    CISOM: number,
    DHI: number,
    DIAM: number,
    EMRAM: number,
    INFRAM: number,
    OEMRAM: number,
    [key: string]: number

}
export interface PublishedScoresStageDistributionModel extends PublishedScoresModelDistributionModel{
    stage: number,
    
};

export const CreateDefaultModelDistributionModel = ():PublishedScoresModelDistributionModel[] => {
    return [{
        AMAM: 0,
        CCOMM: 0,
        CISOM: 0,
        DHI: 0,
        DIAM: 0,
        EMRAM: 0,
        INFRAM: 0,
        OEMRAM: 0
    }];
};

export const CreateDefaultStageDistributionModel = ():PublishedScoresStageDistributionModel[] => {
    return [{
        stage: 0,
        AMAM: 0,
        CCOMM: 0,
        CISOM: 0,
        DHI: 0,
        DIAM: 0,
        EMRAM: 0,
        INFRAM: 0,
        OEMRAM: 0
    },{
        stage: 1,
        AMAM: 0,
        CCOMM: 0,
        CISOM: 0,
        DHI: 0,
        DIAM: 0,
        EMRAM: 0,
        INFRAM: 0,
        OEMRAM: 0
    },{
        stage: 2,
        AMAM: 0,
        CCOMM: 0,
        CISOM: 0,
        DHI: 0,
        DIAM: 0,
        EMRAM: 0,
        INFRAM: 0,
        OEMRAM: 0
    },{
        stage: 3,
        AMAM: 0,
        CCOMM: 0,
        CISOM: 0,
        DHI: 0,
        DIAM: 0,
        EMRAM: 0,
        INFRAM: 0,
        OEMRAM: 0
    },{
        stage: 4,
        AMAM: 0,
        CCOMM: 0,
        CISOM: 0,
        DHI: 0,
        DIAM: 0,
        EMRAM: 0,
        INFRAM: 0,
        OEMRAM: 0
    },{
        stage: 5,
        AMAM: 0,
        CCOMM: 0,
        CISOM: 0,
        DHI: 0,
        DIAM: 0,
        EMRAM: 0,
        INFRAM: 0,
        OEMRAM: 0
    },{
        stage: 6,
        AMAM: 0,
        CCOMM: 0,
        CISOM: 0,
        DHI: 0,
        DIAM: 0,
        EMRAM: 0,
        INFRAM: 0,
        OEMRAM: 0
    },{
        stage: 7,
        AMAM: 0,
        CCOMM: 0,
        CISOM: 0,
        DHI: 0,
        DIAM: 0,
        EMRAM: 0,
        INFRAM: 0,
        OEMRAM: 0
    }];
}