import { Typography } from "@mui/material";

export const Heading1 = (item: any) => {
    let styleOverride = item.data.text[0].style
        ? JSON.parse(item.data.text[0].style)
        : [];
    let outputText = "";

    if (item.data.text.length > 1) {
        item.data.text.map((textPiece: any, index: number) => {
            return outputText += textPiece.value;
        });
    } else {
        outputText = item.data.text[0].value;
    }

    return (
        <Typography
            variant="h1"
            sx={{
                fontSize: "1.3em",
                marginBottom: "8px",
                marginTop: "16px",
                color: "#1e22aa",
                fontWeight: "700",
                ...(styleOverride && {
                    ...styleOverride,
                }),
            }}
        >
            {outputText}
        </Typography>
    );
};

export const Heading2 = (item: any) => {
    let styleOverride = item.data.text[0].style
        ? JSON.parse(item.data.text[0].style)
        : [];
        let outputText = "";

    if (item.data.text.length > 1) {
        item.data.text.map((textPiece: any, index: number) => {
            return outputText += textPiece.value;
        });
    } else {
        outputText = item.data.text[0].value;
    }
    return (
        <Typography
            variant="h2"
            sx={{
                fontSize: "1.45em",
                marginBottom: "20px",
                marginTop: "20px",
                color: "#1e22aa",
                fontWeight: "700",
                ...(styleOverride && {
                    ...styleOverride,
                }),
            }}
        >
            {outputText}
        </Typography>
    );
};

export const Heading3 = (item: any) => {
    let styleOverride = item.data.text[0].style
        ? JSON.parse(item.data.text[0].style)
        : [];

        let outputText = "";

        if (item.data.text.length > 1) {
            item.data.text.map((textPiece: any, index: number) => {
                return outputText += textPiece.value;
            });
        } else {
            outputText = item.data.text[0].value;
        }
    return (
        <Typography
            variant="h3"
            sx={{
                fontSize: "1em",
                marginBottom: "10px",
                color: "#1e22aa",
                ...(styleOverride && {
                    ...styleOverride,
                }),
            }}
        >
            {outputText}
        </Typography>
    );
};

export const Heading4 = (item: any) => {
    let styleOverride = item.data.text[0].style
        ? JSON.parse(item.data.text[0].style)
        : [];
    return (
        <Typography
            variant="h4"
            sx={{
                fontSize: "1em",
                fontWeight: "700",
                marginBottom: "16px",
                marginTop: "64px",
                ...(styleOverride && {
                    ...styleOverride,
                }),
            }}
        >
            {item.data.text[0].value}
        </Typography>
    );
};

export const BasicText = (item: any) => {
    let styleOverride = item.data.text[0].style
        ? JSON.parse(item.data.text[0].style)
        : [];
    return (
        <Typography
            sx={{
                fontSize: "1em",
                marginBottom: "20px",
                ...(styleOverride && {
                    ...styleOverride,
                }),
            }}
        >
            {item.data.text[0].value}
        </Typography>
    );
};
