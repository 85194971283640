import NumericDropDownModel from "../../models/NumericDropDown.model";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";
import { SearchResponseBase } from "../../models/SearchResponseBase.model";
import { Location } from "react-router-dom";
import { User } from "../../models/User.model";
import moment from 'moment';

export interface AssessmentSearchRequest extends SearchRequestBase {
    wizard: boolean,
    surveyIDs: NumericDropDownModel[],
    parentSurvey: string,
    surveyTypeIDs: NumericDropDownModel[],
    statuses: string[],
    assignedToUserIDs: NumericDropDownModel[],
    delegateUserIDs: NumericDropDownModel[],
    facilityIDs: NumericDropDownModel[],
    createdDateRange: [moment.Moment|null, moment.Moment|null]
};

export interface AssessmentMassUpdateModel {
    surveyIDs: number[],
    assignedToUser: NumericDropDownModel|null
};

export function CreateDefaultAssessmentMassUpdateModel(){
    return {surveyIDs: [], assignedToUser: null};
}

export interface AssessmentSearchResponse extends SearchResponseBase {
    id: number,
    surveyID: number,
    surveyGuid: string,
    surveyName: string,
    surveyType: string,
    assignedToUserName: string,
    assignedToUserID: number|null,
    status: string,
    dateCreated: Date
};

export const CreateDefaultAssessmentSearchRequest = (location?: Location, appUser?: User):AssessmentSearchRequest => {
    if(location && location.state?.componentState){
        return {...location.state.componentState, createdDateRange: [location.state.componentState.createdDateRange[0] ? moment(location.state.componentState.createdDateRange[0]) : null, location.state.componentState.createdDateRange[1] ? moment(location.state.componentState.createdDateRange[1]) : null]};
    }
    
    return {
        wizard: false,
        surveyIDs: [],
        parentSurvey: "Any",
        surveyTypeIDs: [],
        statuses: [],
        assignedToUserIDs: appUser?.isInternalFlag ? [{label: appUser!.email, value: appUser!.userID}] : [],
        delegateUserIDs: [],
        facilityIDs: [],
        createdDateRange: [null, null],
        page:  0,
        pageSize: 100,
        sortColumn: "surveyID",
        sortDirection: "desc"
    };
};


export interface AssessmentDetailModel {
    surveyID: number,
    surveyGUID: string,
    surveyType: NumericDropDownModel|null,
    parentFacility: NumericDropDownModel|null,
    digitalHealthTechnologyPartner: NumericDropDownModel|null,
    language: NumericDropDownModel|null,
    surveyName: string,
    assignedToUser: NumericDropDownModel|null,
    status: string,
    dateCreated: Date|null,
    createdByUserName: string,
    modifiedByUserName: string,
    dateModified: Date|null,
    recipients: string[],
    surveyURL: string,
    surveyReason: string,
    facilities: NumericDropDownModel[],
    skipValidation: boolean,
    plainText?: boolean,
    doNotSendCompletionEmail: boolean,
    createBlankSurvey: boolean,
    lockFieldsForSES: boolean,
    surveyUsers: NumericDropDownModel|null
};

export const CreateDefaultAssessmentDetail = (appUser?: User, location?: Location):AssessmentDetailModel => {
    if(location && location.state?.componentState.surveyID === 0) {
        return { ...location.state.componentState,
            surveyGUID: '00000000-0000-0000-0000-000000000000',
            surveyName: '',
            status: '',
            dateCreated: null,
            dateModified: null,
            surveyURL: '',
            createdByUserName: '',
            modifiedByUserName: '',
            surveyReason: '',
        }
    }

    return {
        surveyID: 0,
        surveyGUID: '00000000-0000-0000-0000-000000000000',
        surveyType: null,
        parentFacility: null,
        digitalHealthTechnologyPartner: null,
        language: null,
        surveyName: '',
        assignedToUser: appUser ? {label: appUser!.email, value: appUser!.userID} : null,
        status: '',
        dateCreated: null,
        dateModified: null,
        recipients: [],
        surveyURL: '',
        surveyReason: '',
        facilities: [],
        createdByUserName: '',
        modifiedByUserName: '',
        skipValidation: false,
        plainText: false,
        doNotSendCompletionEmail: false,
        createBlankSurvey: false,
        lockFieldsForSES: false,
        surveyUsers: null
    }
};

export const AssessmentDetailModelIsValid = (model:AssessmentDetailModel):boolean => {
    return (!!model.surveyReason || 
        model.surveyType?.value === 28 ||
        model.surveyType?.value === 25 ||
        model.surveyType?.value === 19 ||
        model.surveyType?.value === 33
    ) &&
    !!model.surveyType &&
    model.facilities.length > 0 &&
    !!model.language &&
    !!model.assignedToUser &&
    model.recipients.length > 0;
};

export interface AssessmentDetailValidationModel{
    isValid?: boolean,
    message?: string,
    existingSurveys?: AssessmentSearchResponse[]
};

export interface AssessmentResendEmailModel{
    surveyID: number,
    resendWelcomeEmail: boolean,
    sendReminderEmail: boolean,
    resendCompletionEmail: boolean,
    usePlainText: boolean,
    recipients: string[],
};

export const CreateDefaultAssessmentResendEmailModel = (surveyID: number):AssessmentResendEmailModel => {
    return {
        surveyID: surveyID,
        resendWelcomeEmail: false,
        sendReminderEmail: false,
        resendCompletionEmail: false,
        usePlainText: false,
        recipients: []
    };
};