import NumericDropDownModel from "../../models/NumericDropDown.model";
import { SearchRequestBase } from "../../models/SearchRequestBase.model";
import { SearchResponseBase } from "../../models/SearchResponseBase.model";
import { Location } from "react-router-dom";
import { User } from "../../models/User.model";
import moment from 'moment';

export interface AARSearchRequest extends SearchRequestBase {
    surveyTypeIDs: NumericDropDownModel[],
    facilityIDs: NumericDropDownModel[],
    facilityTypeIDs: NumericDropDownModel[]
};

export interface AARSearchResponse extends SearchResponseBase {
    uniqueID: number,
    accountName: string,
    surveyType: string,
    accountID: number,
    modelID: number,
};

        
export const CreateDefaultAARSearchRequest = (defaultParams:URLSearchParams, location?: Location, appUser?: User):AARSearchRequest => {
    if(location && location.state?.componentState){
        return location.state.componentState;
    }
    
    return {
        surveyTypeIDs: [],
        facilityIDs: [],
        page:  0,
        pageSize: 100,
        sortColumn: "AccountName",
        sortDirection: "desc",
        facilityTypeIDs: [],
    };
};





